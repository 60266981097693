export const SHOW_TURNTABLE = 'SHOW_TURNTABLE_ICP'
export const SET_TURNTABLE_SIZE = 'SET_TURNTABLE_SIZE_ICP'
export const SET_RESULT_SELECTED = 'SET_RESULT_SELECTED_ICP'
export const SET_CHOICES = 'SET_CHOICES_ICP'
export const SET_HISTORY = 'SET_HISTORY_ICP'
export const SET_TITLE_OBJ = 'SET_TITLE_OBJ_ICP'
export const INSTANT_INIT = 'INSTANT_INIT_ICP'
export const SET_INPUTS = 'SET_INPUTS_ICP'
export const SET_USERNAME = 'SET_USERNAME_ICP'
export const SET_POST_PHOTO = 'SET_POST_PHOTO_ICP'
export const SET_RESULT_REVERSE = 'SET_RESULT_REVERSE_ICP'
export const CHANGE_KEY_TEXT = 'CHANGE_KEY_TEXT_ICP'
export const SET_NUM_WINNERS = 'SET_NUM_WINNERS_ICP'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN_ICP'
// export const SET_AUTO_SPIN = 'SET_AUTO_SPIN_ICP'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE_ICP'
export const REPLACE_STATE = 'REPLACE_STATE_ICP'
import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  SET_PAST_IP,
  SET_SPIN_COUNT,
  SET_MODE,
  SET_HISTORY,
  SET_INPUT_MODE,
  SET_STYLE_MODE,
  SET_CUSTOM_INPUT,
  SET_TITLE_OBJ,
} from './constants'
import { setStartRotate } from '../turntable/actions'

export const showTurntable = turntable => ({
  type: SHOW_TURNTABLE,
  payload: {
    turntable,
  },
})

export const setTurntableSize = size => ({
  type: SET_TURNTABLE_SIZE,
  payload: {
    size,
  },
})

export const setResultSelected = result => ({
  type: SET_RESULT_SELECTED,
  payload: {
    result,
  },
})

export const setHistory = history => ({
  type: SET_HISTORY,
  payload: {
    history,
  },
})

export const setChoices =
  (choices, resetAngle = true) =>
  async (dispatch, getState) => {
    if (resetAngle) {
      const state = getState()
      const startAngle = state.settings.spinBehavior.randomStart
        ? 2 * Math.PI * Math.random()
        : 0
      dispatch(setStartRotate(startAngle))
    }
    dispatch(setChoicesAction(choices))
  }

export const setChoicesAction = (choices, nullTurntable = true) => ({
  type: SET_CHOICES,
  payload: {
    choices,
    nullTurntable,
  },
})

export const setMode = mode => ({
  type: SET_MODE,
  payload: {
    mode,
  },
})

// export const toggleMute = () => ({
//   type: TOGGLE_MUTE,
// })

export const setPastIP = data => {
  return {
    type: SET_PAST_IP,
    payload: {
      data,
    },
  }
}

export const setSpinCount = data => {
  return {
    type: SET_SPIN_COUNT,
    payload: {
      data,
    },
  }
}

export const setInputModeStore = mode => {
  return {
    type: SET_INPUT_MODE,
    payload: {
      mode,
    },
  }
}

export const setStyleModeStore = mode => {
  return {
    type: SET_STYLE_MODE,
    payload: {
      mode,
    },
  }
}

export const setCustomInputStore = input => {
  return {
    type: SET_CUSTOM_INPUT,
    payload: {
      input,
    },
  }
}

//03-07-2021 add title
export const setTitleActive = active => dispatch => {
  dispatch(setTitleObj({ active }))
}

export const setTitleText = text => dispatch => {
  dispatch(setTitleObj({ text }))
}

export const setTitleObj = obj => {
  return {
    type: SET_TITLE_OBJ,
    payload: {
      obj,
    },
  }
}

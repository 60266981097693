export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL'
export const SIGN_IN_USER = 'SIGN_IN_USER'
export const SIGN_OUT_USER = 'SIGN_OUT_USER'
export const UPDATE_LAST_ACTIVE_DATE = 'UPDATE_LAST_ACTIVE_DATE'
export const UPDATE_AUTH_DISPLAY_NAME = 'UPDATE_AUTH_DISPLAY_NAME'
export const UPDATE_AUTH_PHOTO_URL = 'UPDATE_AUTH_PHOTO_URL'
export const UPDATE_AUTH_ROLE = 'UPDATE_AUTH_ROLE'
export const UPDATE_USER_STORE = 'UPDATE_USER_STORE'
export const SET_PREMIUM_HISTORY = 'SET_PREMIUM_HISTORY'
export const SET_USER_IS_PREMIUM = 'SET_USER_IS_PREMIUM'
export const UPDATE_USER_CUSTOMIZATION = 'UPDATE_USER_CUSTOMIZATION'
export const SET_SHARE_PREMIUM = 'SET_SHARE_PREMIUM'

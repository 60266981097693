import {
  SET_WEBSITE_MODE,
  SET_CURRENT_FILE_KEY,
  SET_DATA_STATUS,
  SET_CURRENT_TAB_INDEX,
  SET_CURRENT_TAB_DATA,
  ADD_NEW_TAB_DATA,
  DELETE_TAB_DATA,
  SET_TOOL_SETTINGS_ACTIVE_INDEX,
  SET_IS_TOOL_SPINNING,
} from './constants'
// import { setLoadSaveDataRIG } from '../rig/actions'
// import { setLoadSaveDataRTG } from '../rtg/actions'
import {
  updateAllSettings,
  resetInitialSettings,
} from '../toolSettings/actions'
import { updateCurrentConfig } from '../shareWheel/actions'
// import { entries, clear, setMany } from 'idb-keyval'

export const setWebsiteMode = mode => ({
  type: SET_WEBSITE_MODE,
  payload: {
    mode,
  },
})

export const setCurrentFileKey = key => {
  return {
    type: SET_CURRENT_FILE_KEY,
    payload: {
      key,
    },
  }
}

export const setDataStatus = (status, fromSettings = false) => ({
  type: SET_DATA_STATUS,
  payload: {
    status,
    fromSettings,
  },
})

export const setCurrentTabData = data => ({
  type: SET_CURRENT_TAB_DATA,
  payload: {
    data,
  },
})

export const deleteTabData = (page, index) => async (dispatch, getState) => {
  const state = getState()
  let newCurrentIndex = state.common.currentTabIndex
  // debugger
  if (newCurrentIndex === index) {
    if (newCurrentIndex === state.common.tabsData.length - 1) {
      await dispatch(setCurrentTabIndex(page, newCurrentIndex - 1, true))
      newCurrentIndex = newCurrentIndex - 1
    } else {
      await dispatch(setCurrentTabIndex(page, newCurrentIndex + 1, true))
      newCurrentIndex = newCurrentIndex
    }
  } else if (index < newCurrentIndex) {
    newCurrentIndex = newCurrentIndex - 1
  }
  dispatch({
    type: DELETE_TAB_DATA,
    payload: {
      index,
      newCurrentIndex,
    },
  })
}

export const storeTabData = page => async (dispatch, getState) => {
  const state = getState()
  let data = {
    name: '',
    ...state.common.tabsData[state.common.currentTabIndex],
    id: state.common.currentFileKey,
    dataStatus: state.common.dataStatus,
    currentConfigShare: state.share.currentConfig,
  }
  const toolTitle = state[page].title
  if (toolTitle.active) {
    data['title'] = toolTitle
  } else {
    data['title'] = { active: false, text: '', description: '', popup: '' }
  }

  // if (page !== 'rtg') {
  const {
    resultSelected,
    title,
    turnTable,
    turnTableSize,
    savedChoicesList,
    inputKeyText,
    keyTextRTG,
    savedPeopleList,
    ...rest
  } = state[page]
  data = {
    ...data,
    ...rest,
  }
  // }
  // if (page === 'main' || page === 'rig') {
  //   data['choices'] = state[page].choices
  //   data['history'] = state[page].history
  //   data['spinCount'] = state[page].spinCount
  //   data['mode'] = state[page].mode

  //   if (page === 'main') {
  //     data['weightOn'] = state[page].weightOn
  //   }
  // } else if (page === 'rtg') {
  //   data['people'] = state[page].people
  // } else if (page === 'ynw') {
  //   data['history'] = state[page].history
  //   data['inputMode'] = state[page].inputMode
  //   data['numInputSet'] = state[page].numInputSet
  //   data['results'] = state[page].results
  // } else if (page === 'rng') {
  //   data['history'] = state[page].history
  //   data['inputMode'] = state[page].inputMode
  //   data['numInputSet'] = state[page].numInputSet
  //   data['results'] = state[page].results
  // }

  // if (data['settings']) {
  const fSettings = state.settings
  const settingsData = { ...fSettings }
  if (settingsData.hasOwnProperty('_persist')) {
    delete settingsData._persist
  }

  data['settings'] = settingsData
  // const storedCustomizations = await entries()
  // if (storedCustomizations.length > 0) {
  //   data['customizations'] = storedCustomizations
  // } else {
  //   data['customizations'] = []
  // }

  // }

  dispatch(setCurrentTabData(data))
}

export const setCurrentTabIndex =
  (page, index, skipStoreData = false) =>
  async (dispatch, getState) => {
    if (!skipStoreData) await dispatch(storeTabData(page))
    const state = getState()

    const listData = state.common.tabsData[index]

    if (Object.keys(listData).length === 1 && listData.tabNumber) {
      //newtab
      await dispatch(resetInitialSettings())
      dispatch({
        type: `RESET_INITIAL_STATE_${page.toUpperCase()}`,
      })
      dispatch(setCurrentFileKey(''))
      dispatch(updateCurrentConfig(null))
    } else {
      await dispatch(updateAllSettings(listData['settings']))
      const {
        dataStatus,
        id = '',
        label,
        name,
        settings,
        tabNumber,
        uniqueId,
        currentConfigShare,
        ...newStateData
      } = listData
      let newFileKey = id
      // let newCurrentConfig = null
      // switch (page) {
      //   case 'main':
      //   case 'rig':
      //   case 'rtg':
      //     newFileKey = id
      //     break
      // }
      // newCurrentConfig = currentConfigShare
      dispatch({
        type: `REPLACE_STATE_${page.toUpperCase()}`,
        payload: {
          data: { ...newStateData, turnTable: null },
        },
      })
      dispatch(updateCurrentConfig(currentConfigShare))
      dispatch(setCurrentFileKey(newFileKey))
    }

    dispatch(setDataStatus(listData.dataStatus))
    dispatch(setToolSettingsActiveIndex(1))
    dispatch({
      type: SET_CURRENT_TAB_INDEX,
      payload: {
        index,
      },
    })
  }

export const addNewTabData = page => (dispatch, getState) => {
  const newIndex = getState().common.tabsData.length
  dispatch({
    type: ADD_NEW_TAB_DATA,
  })
  dispatch(setCurrentTabIndex(page, newIndex))
}

export const setToolSettingsActiveIndex = value => ({
  type: SET_TOOL_SETTINGS_ACTIVE_INDEX,
  payload: {
    value,
  },
})

export const setIsToolSpinning = status => ({
  type: SET_IS_TOOL_SPINNING,
  payload: {
    status,
  },
})

export const SHOW_TURNTABLE = 'SHOW_TURNTABLE_RNG'
export const SET_TURNTABLE_SIZE = 'SET_TURNTABLE_SIZE_RNG'
export const SET_RESULT_SELECTED = 'SET_RESULT_SELECTED_RNG'
export const SET_CHOICES = 'SET_CHOICES_RNG'
// export const TOGGLE_MUTE = "TOGGLE_MUTE_RNG"
export const SET_PAST_IP = 'SET_PAST_IP_RNG'
export const SET_SPIN_COUNT = 'SET_SPIN_COUNT_RNG'
export const SET_HISTORY = 'SET_HISTORY_RNG'
export const SET_MODE = 'SET_MODE_RNG'
export const SET_RESULTS_ARRAY = 'SET_RESULTS_ARRAY_RNG'
export const SET_BOARD_INDEX = 'SET_BOARD_INDEX_RNG'
export const SET_TYPE_OPTION = 'SET_TYPE_OPTION_RNG'
export const SET_AUTO_SPIN_MERGE = 'SET_AUTO_SPIN_MERGE_RNG'
export const SET_MIN_VALUE = 'SET_MIN_VALUE_RNG'
export const SET_MAX_VALUE = 'SET_MAX_VALUE_RNG'
export const SET_INTERVAL_VALUE = 'SET_INTERVAL_VALUE_RNG'
// export const SET_TITLE_ACTIVE = 'SET_TITLE_ACTIVE_RNG'
// export const SET_TITLE_TEXT = 'SET_TITLE_TEXT_RNG'
export const SET_TITLE_OBJ = 'SET_TITLE_OBJ_RNG'
export const SET_EXCLUDE_FIELD_VALUE = 'SET_EXCLUDE_FIELD_VALUE_RNG'
export const SET_INPUT_METHOD = 'SET_INPUT_METHOD_RNG'
export const SET_FORMULA_FIELD_VALUE = 'SET_FORMULA_FIELD_VALUE_RNG'
export const INSTANT_INIT = 'INSTANT_INIT_RNG'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE_RNG'
export const REPLACE_STATE = 'REPLACE_STATE_RNG'
export const SET_DIGIT_RANGES = 'SET_DIGIT_RANGES_RNG'
export const SET_DIGIT_NUMBER = 'SET_DIGIT_NUMBER_RNG'

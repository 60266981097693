import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  SET_MODE,
  SET_HISTORY,
  SET_INPUT_MODE,
  SET_DAYS_MODE,
  SET_INPUTS,
  SET_TITLE_OBJ,
} from './constants'
import { setStartRotate } from '../turntable/actions'

export const showTurntable = turntable => ({
  type: SHOW_TURNTABLE,
  payload: {
    turntable,
  },
})

export const setTurntableSize = size => ({
  type: SET_TURNTABLE_SIZE,
  payload: {
    size,
  },
})

export const setResultSelected = result => ({
  type: SET_RESULT_SELECTED,
  payload: {
    result,
  },
})

export const setHistory = history => ({
  type: SET_HISTORY,
  payload: {
    history,
  },
})

export const setChoices = (choices, resetAngle = true) => async (
  dispatch,
  getState,
) => {
  if (resetAngle) {
    const state = getState()
    const startAngle = state.settings.spinBehavior.randomStart
      ? 2 * Math.PI * Math.random()
      : 0
    dispatch(setStartRotate(startAngle))
  }
  dispatch(setChoicesAction(choices))
}

export const setChoicesAction = (choices, nullTurntable = true) => ({
  type: SET_CHOICES,
  payload: {
    choices,
    nullTurntable,
  },
})

export const setMode = mode => ({
  type: SET_MODE,
  payload: {
    mode,
  },
})

export const setInputModeStore = inputMode => {
  return {
    type: SET_INPUT_MODE,
    payload: {
      inputMode,
    },
  }
}

export const setDaysModeStore = daysMode => {
  return {
    type: SET_DAYS_MODE,
    payload: {
      daysMode,
    },
  }
}

export const setInputsStore = inputs => {
  return {
    type: SET_INPUTS,
    payload: {
      inputs,
    },
  }
}

export const setTitleActive = active => dispatch => {
  dispatch(setTitleObj({ active }))
}

export const setTitleText = text => dispatch => {
  dispatch(setTitleObj({ text }))
}

export const setTitleObj = obj => {
  return {
    type: SET_TITLE_OBJ,
    payload: {
      obj,
    },
  }
}
import { createReducer } from '../reducerUtil'
import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  // TOGGLE_MUTE,
  SET_PAST_IP,
  SET_SPIN_COUNT,
  SET_HISTORY,
  SET_MODE,
  SET_RESULTS_ARRAY,
  SET_BOARD_INDEX,
  SET_TYPE_OPTION,
  SET_AUTO_SPIN_MERGE,
  SET_MIN_VALUE,
  SET_MAX_VALUE,
  SET_INTERVAL_VALUE,
  SET_TITLE_OBJ,
  SET_EXCLUDE_FIELD_VALUE,
  SET_FORMULA_FIELD_VALUE,
  SET_INPUT_METHOD,
  INSTANT_INIT,
  RESET_INITIAL_STATE,
  REPLACE_STATE,
  SET_DIGIT_RANGES,
  SET_DIGIT_NUMBER,
} from './constants'

const initialState = {
  resultSelected: '',
  resultsArray: [null, null, null, null],
  boardIndex: 0,
  typeOption: 0, // 0 is random number mode, >0 is random digits mode
  autoSpinMerge: false,
  history: [],
  mode: 0,
  choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  title: {
    active: false,
    text: '',
    description: '',
    popup: '',
  },
  turnTable: null,
  turnTableSize: 300,
  // mute: isMobile ? true : false,
  // pastIP: '',
  spinCount: 0,
  minValue: 1,
  maxValue: 10,
  intervalValue: 1,
  excludeFieldValue: '',
  formulaFieldValue: '',
  inputMethod: 'By Range',
  digitNumber: 4,
  digitRanges: [
    [0, 9],
    [0, 9],
    [0, 9],
    [0, 9],
    [0, 9],
    [0, 9],
  ],
}

export const setAutoSpinMerge = (state, payload) => {
  return {
    ...state,
    autoSpinMerge: payload.status,
  }
}

export const setTypeOption = (state, payload) => {
  return {
    ...state,
    typeOption: payload.option,
  }
}

export const setBoardIndex = (state, payload) => {
  return {
    ...state,
    boardIndex: payload.index,
  }
}

export const setResultsArray = (state, payload) => {
  return {
    ...state,
    resultsArray: [...payload.results],
  }
}

export const setResultSelected = (state, payload) => {
  return {
    ...state,
    resultSelected: payload.result,
  }
}

export const setChoices = (state, payload) => {
  return {
    ...state,
    choices: payload.choices,
    turnTable: null,
  }
}

export const setHistory = (state, payload) => {
  return {
    ...state,
    history: payload.history,
  }
}

export const setMode = (state, payload) => {
  return {
    ...state,
    mode: payload.mode,
  }
}

export const showTurntable = (state, payload) => {
  return {
    ...state,
    turnTable: payload.turntable,
  }
}

export const setTurntableSize = (state, payload) => {
  return {
    ...state,
    turnTable: state.turnTableSize === payload.size ? state.turnTable : null,
    turnTableSize: payload.size,
  }
}

// export const toggleMute = (state, payload) => {
//   return {
//     ...state,
//     mute: !state.mute,
//   }
// }

export const setPastIP = (state, payload) => {
  return {
    ...state,
    pastIP: payload.data,
  }
}

export const setSpinCount = (state, payload) => {
  return {
    ...state,
    spinCount: payload.data,
  }
}

export const setMinValue = (state, payload) => {
  return {
    ...state,
    minValue: payload.min,
  }
}

export const setMaxValue = (state, payload) => {
  return {
    ...state,
    maxValue: payload.max,
  }
}

export const setIntervalValue = (state, payload) => {
  return {
    ...state,
    intervalValue: payload.interval,
  }
}

// export const setTitleActive = (state, payload) => {
//   return {
//     ...state,
//     title: {
//       ...state.title,
//       active: payload.active,
//     },
//   }
// }

// export const setTitleText = (state, payload) => {
//   return {
//     ...state,
//     title: {
//       ...state.title,
//       text: payload.text,
//     },
//   }
// }

export const setTitleObj = (state, payload) => {
  return {
    ...state,
    title: {
      ...state.title,
      ...payload.obj,
    },
  }
}

export const setExcludeFieldValue = (state, payload) => {
  return {
    ...state,
    excludeFieldValue: payload.value,
  }
}

export const setFormulaFieldValue = (state, payload) => {
  return {
    ...state,
    formulaFieldValue: payload.value,
  }
}

export const setInputMethod = (state, payload) => {
  return {
    ...state,
    inputMethod: payload.method,
  }
}

export const instantInit = (state, payload) => {
  if (!payload.previousState) {
    return {
      ...state,
    }
  } else {
    return {
      ...payload.previousState,
    }
  }
}

export const resetInitialState = (state, payload) => {
  const { turnTableSize, ...rest } = initialState
  return {
    ...state,
    ...rest,
  }
}

export const replaceState = (state, payload) => {
  return {
    ...state,
    ...payload.data,
  }
}

export const setDigitRanges = (state, payload) => {
  return {
    ...state,
    digitRanges: payload.ranges,
  }
}

export const setDigitNumber = (state, payload) => {
  return {
    ...state,
    digitNumber: payload.number,
  }
}

export default createReducer(initialState, {
  [SHOW_TURNTABLE]: showTurntable,
  [SET_RESULT_SELECTED]: setResultSelected,
  [SET_CHOICES]: setChoices,
  [SET_MODE]: setMode,
  [SET_HISTORY]: setHistory,
  [SET_TURNTABLE_SIZE]: setTurntableSize,
  // [TOGGLE_MUTE]: toggleMute,
  [SET_PAST_IP]: setPastIP,
  [SET_SPIN_COUNT]: setSpinCount,
  [SET_RESULTS_ARRAY]: setResultsArray,
  [SET_BOARD_INDEX]: setBoardIndex,
  [SET_TYPE_OPTION]: setTypeOption,
  [SET_AUTO_SPIN_MERGE]: setAutoSpinMerge,
  [SET_MIN_VALUE]: setMinValue,
  [SET_MAX_VALUE]: setMaxValue,
  [SET_INTERVAL_VALUE]: setIntervalValue,
  // [SET_TITLE_ACTIVE]: setTitleActive,
  // [SET_TITLE_TEXT]: setTitleText,
  [SET_TITLE_OBJ]: setTitleObj,
  [SET_EXCLUDE_FIELD_VALUE]: setExcludeFieldValue,
  [SET_FORMULA_FIELD_VALUE]: setFormulaFieldValue,
  [SET_INPUT_METHOD]: setInputMethod,
  [INSTANT_INIT]: instantInit,
  [RESET_INITIAL_STATE]: resetInitialState,
  [REPLACE_STATE]: replaceState,
  [SET_DIGIT_RANGES]: setDigitRanges,
  [SET_DIGIT_NUMBER]: setDigitNumber,
})

export const SHOW_TURNTABLE = 'SHOW_TURNTABLE_RLG'
export const SET_TURNTABLE_SIZE = 'SET_TURNTABLE_SIZE_RLG'
export const SET_RESULT_SELECTED = 'SET_RESULT_SELECTED_RLG'
export const SET_CHOICES = 'SET_CHOICES_RLG'
export const SET_PAST_IP = 'SET_PAST_IP_RLG'
export const SET_SPIN_COUNT = 'SET_SPIN_COUNT_RLG'
export const SET_HISTORY = 'SET_HISTORY_RLG'
export const SET_MODE = 'SET_MODE_RLG'
export const SET_INPUT_MODE = 'SET_INPUT_MODE_RLG'
export const SET_STYLE_MODE = 'SET_STYLE_MODE_RLG'
export const SET_CUSTOM_INPUT = 'SET_CUSTOM_INPUT_RLG'
// export const SET_TITLE_ACTIVE = 'SET_TITLE_ACTIVE_RLG'
// export const SET_TITLE_TEXT = 'SET_TITLE_TEXT_RLG'
export const SET_TITLE_OBJ = 'SET_TITLE_OBJ_RLG'
export const INSTANT_INIT = 'INSTANT_INIT_RLG'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE_RLG'
export const REPLACE_STATE = 'REPLACE_STATE_RLG'

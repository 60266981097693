import React from 'react'
import { Box, Text } from 'grommet'
import styled from 'styled-components'
import { Validate, Alert } from 'grommet-icons'
import TextButton from '../Button/TextButton'

const ToastBox = styled(Box)`
  position: fixed;
  top: ${props => (props.active ? '1.3rem' : '-5rem')};
  transition: top 230ms;
  width: 100vw;
  z-index: 10002;
  pointer-events: none;
  /* max-width: 30rem; */
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 10%), 0 3px 3px rgb(0 0 0 / 5%); */
`

const ToastInnerBox = styled(Box)`
  pointer-events: auto;
  transition: ${props =>
    props.active ? 'background 230ms step-start' : 'background 230ms step-end'};
`

const Toast = ({ data, close }) => {
  let text = ''
  let icon = null
  let color = 'primary'
  if (data) {
    text = data.text || ''
    if (data.type === 'Success') {
      icon = <Validate color="invertTextStrong" size="15px" />
      color = 'primary'
    } else if (data.type === 'Warning') {
      icon = <Alert color="invertTextStrong" size="15px" />
      color = 'reject'
    }
  }
  return (
    <ToastBox
      active={data !== null}
      align="center"
      pad={{ horizontal: '1rem' }}
    >
      <ToastInnerBox
        background={color}
        pad=".5rem 1.3rem"
        round="2rem"
        direction="row"
        gap=".5rem"
        align="center"
        elevation="medium"
        justify="center"
        active={data !== null}
        width={{ max: '45rem' }}
      >
        {icon}
        <Text
          margin={{ right: '1rem' }}
          size="1.5rem"
          color="invertTextStrong"
          weight={500}
        >
          {text}
        </Text>

        {!!data && (
          <TextButton
            label="x"
            onClick={close}
            color="invertTextStrong"
            weight={500}
          />
        )}
      </ToastInnerBox>
    </ToastBox>
  )
}

export default Toast

export const size = {
  small: {
    value: 1,
  },
  medium: {
    value: 1200,
  },
  dirChange: {
    value: 1225,
  },
  large: {
    // value: 3000,
  },
}

export const device = {
  small: `(min-width: ${size.small.value}px)`,
  mobileView: `(max-width: 450px)`,
  medium: `(max-width: ${size.medium.value}px)`,
  dirChange: `(max-width: ${size.dirChange.value}px)`,
  large: `(min-width: ${size.medium.value + 1}px)`,
}

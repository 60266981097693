import cuid from 'cuid'
import {
  setDoc,
  updateDoc,
  getDoc,
  writeBatch,
  serverTimestamp,
  doc,
  deleteField,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
// import { getAuth } from 'firebase/auth'
import cloneDeep from 'lodash.clonedeep'
// import equal from 'fast-deep-equal'
// import getFirebase from '../../utils/firebase/firebase'
// import {
//   asyncActionStart,
//   asyncActionFinish,
//   asyncActionError,
// } from '../async/actions'
import { saveLocalChoicesList, setSavedLists } from '../homepage/actions'
import {
  saveLocalChoicesList as saveLocalChoicesListRIG,
  setSavedLists as setSavedListsRIG,
} from '../rig/actions'
import {
  saveLocalPeopleList,
  setSavedLists as setSavedListsRTG,
} from '../rtg/actions'
import { updateUserCustomization } from '../auth/actions'
import {
  refactorTitle,
  refactorSoundSettings,
  refactorSpinBehavior,
  removeObjectField,
} from '../../utils'
import { firestore, auth } from '../../utils/firebase/firebase'
import { functions } from '../../utils/firebase/firebase'
import {
  updateAllSettings,
  turnOffPremiumSettings,
  resetInitialSettings,
} from '../toolSettings/actions'
import { isImageData } from '../../utils/imageUtil'
import {
  setCurrentFileKey,
  setCurrentTabData,
  setDataStatus,
  setToolSettingsActiveIndex,
} from '../common/actions'
// import { entries, setMany, clear } from 'idb-keyval'
import {
  // uploadUserAsset,
  copyStorageFiles,
  deleteUserAsset,
  getUnusedAssetsName,
} from '../firebaseStorage/actions'
import { updateCurrentConfig } from '../shareWheel/actions'
import { replaceCDN, isString } from '../../utils'
// import { removeObjectField } from '../../utils'

// import { displaySuccessModal } from '../modal/actions'

export const setUserProfileData = async user => {
  // const firebaseApp = getFirebase()
  // const firestore = firebase.firestore()
  // const d = collection(firestore, 'users').doc(user.uid)
  const d = doc(firestore, 'users', user.uid)
  return await setDoc(d, {
    displayName: user.displayName,
    email: user.email,
    photoURL: user.photoURL || '',
    role: 'subscriber',
    gender: '',
    occupation: '',
    country: '',
    uid: user.uid,
    providerId: user.providerData[0].providerId, //no longer for many use, this is the user first providerid, only for increment and decrement user
    createdAt: serverTimestamp(),
    lastActive: serverTimestamp(),
  })
}

// export const updateUserProfile = profile => async (dispatch, getState) => {
//   // const firebaseApp = getFirebase()
//   const user = auth.currentUser
//   const firestore = getFirestore()
//   // const firestore = firebase.firestore()
//   try {
//     dispatch(asyncActionStart('loadingButton'))
//     if (user.displayName !== profile.displayName) {
//       await updateProfile(user, {
//         displayName: profile.displayName,
//       })
//     }
//     // const d = collection(firestore, 'users').doc(user.uid)
//     const d = doc(firestore, 'users', user.uid)
//     await updateDoc(d, profile)
//     dispatch(asyncActionFinish('loadingButton'))
//   } catch (error) {
//     dispatch(asyncActionError('loadingButton'))
//     throw error
//   }
// }

export const updateLastActive = async user => {
  // const firestore = firebase.firestore()
  // const firebaseApp = getFirebase()
  // const d = collection(firestore, 'users').doc(user.uid)
  const d = doc(firestore, 'users', user.uid)
  return await updateDoc(d, {
    lastActive: serverTimestamp(),
  })
  // return await firestore
  //   .collection('users')
  //   .doc(user.uid)
  //   .update({
  //     lastActive: firebase.firestore.FieldValue.serverTimestamp(),
  //   })
}

// export const updateUserProfilePhoto = async downloadURL => {
//   // const firebaseApp = getFirebase()
//   const user = auth.currentUser
//   const firestore = getFirestore()
//   // const firestore = firebase.firestore()
//   // const userDocRef = collection(firestore, 'users').doc(user.uid)
//   const userDocRef = doc(firestore, 'users', user.uid)
//   try {
//     const userDoc = await getDoc(userDocRef)
//     if (userDoc.exists()) {
//       if (userDoc.data().photoURL !== downloadURL) {
//         // const d = collection(firestore, 'users').doc(user.uid)
//         await updateDoc(userDocRef, {
//           photoURL: downloadURL,
//         })
//         await updateProfile(user, {
//           photoURL: downloadURL,
//         })
//       }
//     }
//   } catch (error) {
//     throw error
//   }
// }

export const getUserProfile = async () => {
  // const firebaseApp = getFirebase()
  const user = auth.currentUser
  // const firestore = firebase.firestore()
  const userDocRef = doc(firestore, 'users', user.uid)
  return await getDoc(userDocRef)
}

export const getLoadListsNames = page => {
  // const firebaseApp = getFirebase()
  const user = auth.currentUser
  // const firestore = firebase.firestore()
  const userDocRef = doc(firestore, 'users', user.uid)
  return doc(userDocRef, 'save-lists-names', page)
}

export const getLoadList =
  (page, selectedList) => async (dispatch, getState) => {
    // dispatch(asyncActionStart('loadingButton'))
    const state = getState()
    let loadFromCloud = false
    try {
      let savedList = null
      if (page === 'main' || page === 'rig') {
        if (state[page].savedChoicesList.hasOwnProperty(selectedList.id)) {
          savedList = cloneDeep(state[page].savedChoicesList[selectedList.id]) // clone deep needed else local save list will also change value based on reference
        }
      } else if (page === 'rtg') {
        if (state.rtg.savedPeopleList.hasOwnProperty(selectedList.id)) {
          savedList = cloneDeep(state.rtg.savedPeopleList[selectedList.id])
        }
      }
      let listData = null
      if (
        savedList !== null &&
        savedList?.uniqueId !== null &&
        savedList?.uniqueId === selectedList.uniqueId
      ) {
        //if there is same uniqueid, load from local storage

        listData = {
          ...selectedList,
          ...savedList,
        }
        if (!savedList.title) {
          listData['title'] = {
            active: false,
            text: '',
            description: '',
            popup: '',
          }
        } else {
          listData.title = refactorTitle(listData.title)
        }
        // if (page !== 'rtg') {
        //   listData['choices'] = savedList.choices
        // } else {
        //   listData['people'] = savedList.people
        // }
        // if (savedList['settings']) {
        //   listData['settings'] = savedList['settings']
        // }
      } else {
        // const firebaseApp = getFirebase()
        const user = auth.currentUser
        // const firestore = firebase.firestore()
        const userDocRef = doc(firestore, 'users', user.uid)
        const d = doc(userDocRef, `save-lists-${page}`, selectedList.id)
        const list = await getDoc(d)
        if (!list.exists()) {
          // dispatch(asyncActionFinish('loadingButton'))
          return true
        }
        loadFromCloud = true
        listData = list.data()
        if (!listData.title) {
          listData['title'] = {
            active: false,
            text: '',
            description: '',
            popup: '',
          }
        } else {
          listData.title = refactorTitle(listData.title)
        }
      }
      if (page === 'main') {
        //refactor input.name image to input.data
        for (let input of listData.choices) {
          if (isImageData(input.name)) {
            input['data'] = input.name
            input.name = ''
          }
        }
      } else if (page === 'rtg') {
        if (listData.groups && isString(listData.groups)) {
          //load from local maybe not string
          listData.groups = JSON.parse(listData.groups)
        }
        if (listData.csvGroups && isString(listData.csvGroups)) {
          //load from local maybe not string
          listData.csvGroups = JSON.parse(listData.csvGroups)
        }
        if (!listData.presetGroups) {
          listData.presetGroups = []
        } else if (listData.presetGroups && isString(listData.presetGroups)) {
          //load from local maybe not string
          listData.presetGroups = JSON.parse(listData.presetGroups)
        }
      } else if (page === 'rng') {
        if (listData.digitRanges && isString(listData.digitRanges)) {
          listData.digitRanges = JSON.parse(listData.digitRanges)
        }
      }

      const { id, name, settings, ...newStateData } = listData
      dispatch({
        type: `REPLACE_STATE_${page.toUpperCase()}`,
        payload: {
          data: { ...newStateData, turnTable: null },
        },
      })
      switch (page) {
        case 'main':
          // dispatch(
          //   setLoadSaveData(
          //     listData.id,
          //     listData.choices,
          //     listData.title,
          //     true,
          //   ),
          // )
          if (loadFromCloud) dispatch(saveLocalChoicesList(cloneDeep(listData))) //save to local storage if retrieve from cloud
          break
        case 'rtg':
          // dispatch(
          //   setLoadSaveDataRTG(
          //     listData.id,
          //     listData.people,
          //     listData.title,
          //     true,
          //   ),
          // )
          if (loadFromCloud) dispatch(saveLocalPeopleList(cloneDeep(listData)))
          break
        case 'rig':
          // dispatch(
          //   setLoadSaveDataRIG(
          //     listData.id,
          //     listData.choices,
          //     listData.title,
          //     true,
          //   ),
          // )
          if (loadFromCloud)
            dispatch(saveLocalChoicesListRIG(cloneDeep(listData)))
          break
      }
      dispatch(setCurrentFileKey(id))
      dispatch(setDataStatus('Unchanged'))
      if (newStateData.sharePath) {
        dispatch(
          updateCurrentConfig({
            fileSharePath: newStateData.sharePath,
          }),
        )
      } else {
        dispatch(updateCurrentConfig(null))
      }
      dispatch(setCurrentTabData(listData))
      if (settings) {
        const refactoredMusic = refactorSoundSettings(settings.music)
        const refactoredBehavior = refactorSpinBehavior(settings.spinBehavior)
        const updatedSettings = {
          ...settings,
          music: refactoredMusic,
          spinBehavior: refactoredBehavior,
        }
        if (!updatedSettings.rtgSpinBehavior) {
          //add new rtg behavior
          updatedSettings['rtgSpinBehavior'] = {
            groupingDuration: 5,
            representativeDuration: 3,
          }
        }
        dispatch(setToolSettingsActiveIndex(1))
        await dispatch(updateAllSettings(updatedSettings))
        if (
          !state.auth.currentUser?.isPremium &&
          (settings.banners?.on ||
            settings.bgImage?.on ||
            settings.spinButton?.colorOn ||
            settings.spinButton?.imageOn ||
            settings.music?.customOn)
        ) {
          // for premium to non-premium users
          dispatch(turnOffPremiumSettings())
        }
      }

      // dispatch(asyncActionFinish('loadingButton'))
      return true
    } catch (error) {
      // dispatch(asyncActionError('loadingButton'))
      throw error
    }
  }

export const setSaveList =
  (page, listId, name, settingsChecked) => async (dispatch, getState) => {
    try {
      // dispatch(asyncActionStart('loadingButton'))
      // const firebaseApp = getFirebase()
      const user = auth.currentUser
      // const firestore = firebase.firestore()
      const batch = writeBatch(firestore)
      const state = getState()

      const userDocRef = doc(firestore, 'users', user.uid)

      const namesPath = doc(userDocRef, `save-lists-names`, page)

      const uniqueId = cuid()

      // const listNamesExist = listNames !== null

      const nameObject = {
        [listId]: {
          name: name,
          id: listId,
          uniqueId: uniqueId,
        },
      }
      if (settingsChecked) {
        nameObject[listId]['settingsChecked'] = true
      }
      // if (!isReplacingSameFile) {
      //   //global share link exits, then add sharePath
      //   nameObject[listId]['sharePath'] = deleteField()
      // }
      // if (listNamesExist) {
      //   batch.update(namesPath, nameObject)

      //   const isReplacingShareLink = !!listNames[listId]?.sharePath
      //   if (isReplacingShareLink) {
      //     //replacing an old share link
      //     const oldSharePath = listNames?.[listId]?.sharePath
      //     const linkDocRef = doc(
      //       firestore,
      //       'share-wheels',
      //       page,
      //       'share-links',
      //       oldShareId,
      //     )
      //     batch.update(linkDocRef, {
      //       hasOwner: false,
      //       lastUpdated: serverTimestamp(),
      //     })
      //   }
      // } else {
      // if the collection does not exist
      batch.set(namesPath, nameObject, { merge: true })
      // }

      let data
      // if (!sharePath) {
      const fSettings = state.settings
      const dataPath = doc(userDocRef, `save-lists-${page}`, listId)

      const keyName = page === 'rtg' ? 'people' : 'choices'
      data = {
        createdDate: serverTimestamp(),
        name: name,
        id: listId,
        uniqueId: uniqueId,
        [keyName]: page === 'rtg' ? state[page].people : state[page].choices,
      }
      if (page === 'main') {
        data['weightOn'] = state[page].weightOn
        data['mode'] = state[page].mode
        // if (weightOn) {
        //   data['weightOn'] = weightOn
        // } else {
        //   if (isReplacingSameFile) {
        //     data['weightOn'] = deleteField()
        //   }
        // }
      } else if (page === 'rtg') {
        data['groupNames'] = state[page].groupNames
        data['numGroups'] = state[page].numGroups
        data['evenSplit'] = state[page].evenSplit
        data['showIcon'] = state[page].showIcon
        data['pickQuantity'] = state[page].pickQuantity
        data['groups'] = JSON.stringify(state[page].groups)
        data['csvGroups'] = JSON.stringify(state[page].csvGroups)
        data['representativeIndexes'] = state[page].representativeIndexes
        data['pickRepresentative'] = state[page].pickRepresentative
        data['presetGroups'] = JSON.stringify(state[page].presetGroups)
      } else if (page === 'rig') {
        data['mode'] = state[page].mode
      } else if (page === 'rng') {
        const {
          typeOption,
          autoSpinMerge,
          minValue,
          maxValue,
          intervalValue,
          excludeFieldValue,
          formulaFieldValue,
          inputMethod,
          digitRanges,
          digitNumber,
          resultsArray,
        } = state[page]
        data = {
          ...data,
          typeOption,
          autoSpinMerge,
          minValue,
          maxValue,
          intervalValue,
          excludeFieldValue,
          formulaFieldValue,
          inputMethod,
          digitRanges: JSON.stringify(digitRanges),
          digitNumber,
          resultsArray,
        }
      }

      const toolTitle = state[page].title
      if (toolTitle.active) {
        data['title'] = toolTitle
      } else {
        data['title'] = {
          active: false,
          text: '',
          description: '',
          popup: '',
        }
      }

      if (settingsChecked) {
        const results = await copyAssets(fSettings, listId, user.uid, page)
        let settingsData = { ...fSettings }
        if (settingsData.hasOwnProperty('_persist')) {
          delete settingsData._persist
        }
        if (Object.keys(results).length > 0) {
          // has new src urls
          settingsData = await replaceNewSettings(results, settingsData)
          dispatch(updateAllSettings(settingsData))
          dispatch(setToolSettingsActiveIndex(1))
        }
        data['settings'] = settingsData
        // } else {
        //   if (isReplacingSameFile) {
        //     data['settings'] = deleteField()
        //   }
      }
      const dataLength = JSON.stringify({ ...data }).length
      if (dataLength > 990000) {
        // dispatch(asyncActionError('loadingButton'))
        return {
          error: 'Too many images/inputs, reduce some and try again.',
        }
      }
      batch.set(dataPath, data)
      // }

      await batch.commit()
      // if (shareId) {
      //   const addOwnerShareWheel = httpsCallable(
      //     functions,
      //     'addOwnerShareWheel',
      //   )
      //   const result = await addOwnerShareWheel({
      //     page,
      //     path: shareId,
      //     uid: user.uid,
      //   })
      //   const data = result.data
      //   if (data.error) {
      //     console.log(data.error)
      //     // dispatch(asyncActionError('share'))
      //     throw {
      //       error: 'Error when saving the link, please try again.',
      //     }
      //   }
      //   return {
      //     error: '',
      //   }
      // }
      //if have shareId will not go next steps
      dispatch(setCurrentFileKey(listId))
      dispatch(setDataStatus('Unchanged'))
      switch (page) {
        case 'main':
          dispatch(saveLocalChoicesList(cloneDeep(data))) // clone deep needed else local save list will also change value based on reference, only save private save list not global share link
          break
        case 'rtg':
          dispatch(saveLocalPeopleList(cloneDeep(data)))
          break
        case 'rig':
          dispatch(saveLocalChoicesListRIG(cloneDeep(data)))
          break
      }
      dispatch(updateCurrentConfig(null))
      dispatch(setCurrentTabData(data))
      // dispatch(asyncActionFinish('loadingButton'))
      return {
        error: '',
      }
    } catch (error) {
      // console.log(error.code)

      if (error.code === 'permission-denied') {
        checkRecoverUser()
      }
      // dispatch(asyncActionError('loadingButton'))
      throw error
    }
  }

export const instantSave = page => async (dispatch, getState) => {
  try {
    // throw new Error('Too many images/inputs, reduce some and try again.')
    dispatch(setDataStatus('Saving'))
    const user = auth.currentUser
    const batch = writeBatch(firestore)
    const state = getState()
    const listId = state.common.currentFileKey
    const settingsChecked =
      state.common.tabsData[state.common.currentTabIndex].hasOwnProperty(
        'settings',
      )
    const { name } = state.common.tabsData[state.common.currentTabIndex]

    const userDocRef = doc(firestore, 'users', user.uid)
    const namesPath = doc(userDocRef, `save-lists-names`, page)
    const dataPath = doc(userDocRef, `save-lists-${page}`, listId)

    const newUniqueId = cuid()

    const nameObject = {
      [listId]: {
        name: name,
        id: listId,
        uniqueId: newUniqueId,
      },
    }

    if (settingsChecked) {
      nameObject[listId]['settingsChecked'] = true
    }

    batch.set(namesPath, nameObject, { merge: true })

    let data
    const keyName = page === 'rtg' ? 'people' : 'choices'
    data = {
      updatedDate: serverTimestamp(),
      name: name,
      id: listId,
      uniqueId: newUniqueId,
      [keyName]: page === 'rtg' ? state[page].people : state[page].choices,
    }
    if (page === 'main') {
      data['weightOn'] = state[page].weightOn
      data['mode'] = state[page].mode
      // if (weightOn) {
      //   data['weightOn'] = weightOn
      //   // } else {
      //   //   data['weightOn'] = deleteField()
      // }
    } else if (page === 'rtg') {
      data['groupNames'] = state[page].groupNames
      data['numGroups'] = state[page].numGroups
      data['evenSplit'] = state[page].evenSplit
      data['showIcon'] = state[page].showIcon
      data['pickQuantity'] = state[page].pickQuantity
      data['groups'] = JSON.stringify(state[page].groups)
      data['csvGroups'] = JSON.stringify(state[page].csvGroups)
      data['representativeIndexes'] = state[page].representativeIndexes
      data['pickRepresentative'] = state[page].pickRepresentative
      data['presetGroups'] = JSON.stringify(state[page].presetGroups)
    } else if (page === 'rig') {
      data['mode'] = state[page].mode
    } else if (page === 'rng') {
      const {
        typeOption,
        autoSpinMerge,
        minValue,
        maxValue,
        intervalValue,
        excludeFieldValue,
        formulaFieldValue,
        inputMethod,
        digitRanges,
        digitNumber,
        resultsArray,
      } = state[page]
      data = {
        ...data,
        typeOption,
        autoSpinMerge,
        minValue,
        maxValue,
        intervalValue,
        excludeFieldValue,
        formulaFieldValue,
        inputMethod,
        digitRanges: JSON.stringify(digitRanges),
        digitNumber,
        resultsArray,
      }
    }

    const toolTitle = state[page].title
    if (toolTitle.active) {
      data['title'] = toolTitle
    } else {
      data['title'] = { active: false, text: '', description: '', popup: '' }
    }

    if (settingsChecked) {
      const fSettings = state.settings
      const results = await copyAssets(fSettings, listId, user.uid, page)
      let settingsData = { ...fSettings }
      if (settingsData.hasOwnProperty('_persist')) {
        delete settingsData._persist
      }
      if (Object.keys(results).length > 0) {
        // has new src urls
        settingsData = await replaceNewSettings(results, settingsData)
        dispatch(updateAllSettings(settingsData))
        dispatch(setToolSettingsActiveIndex(1))
      }
      data['settings'] = settingsData
    }

    // const fileShareConfig = state.share.fileShareConfig
    // if (fileShareConfig) {
    //   data = {
    //     ...data,
    //     ...fileShareConfig,
    //   }
    // }

    const dataLength = JSON.stringify({ ...data }).length
    if (dataLength > 990000) {
      // dispatch(asyncActionError('loadingButton'))
      throw new Error('Too many images/inputs, reduce some and try again.')
    }

    batch.set(dataPath, data, { merge: true })
    await batch.commit()
    dispatch(setDataStatus('Unchanged'))
    dispatch(setCurrentTabData(data))
    switch (page) {
      case 'main':
        dispatch(saveLocalChoicesList(cloneDeep(data))) // clone deep needed else local save list will also change value based on reference, only save private save list not global share link
        break
      case 'rtg':
        dispatch(saveLocalPeopleList(cloneDeep(data)))
        break
      case 'rig':
        dispatch(saveLocalChoicesListRIG(cloneDeep(data)))
        break
    }

    return true
  } catch (error) {
    // console.log(error)
    if (error.code === 'permission-denied') {
      checkRecoverUser()
    }
    throw error
  }
}

const copyAssets = async (settingsData, listId, userId, page) => {
  const { bgImage, banners, music } = settingsData
  const allPromises = []
  const copyFiles = []
  const nullAssets = []
  if (bgImage.src) {
    //copy from another storage folder
    if (
      bgImage.src.includes('Options') || //not from options
      !bgImage.src.includes(`${page}2F${listId}%2F`) || //not same file
      !bgImage.src.includes(userId) //from other people
    ) {
      copyFiles.push({
        srcUrl: bgImage.src,
        destFolder: `${page}/${listId}`,
        filename: 'bgImage',
      })
    }
  } else {
    nullAssets.push('bgImage')
  }

  if (music.spinning.includes('http')) {
    if (
      music.spinning.includes('Options') ||
      !music.spinning.includes(`${page}%2F${listId}%2F`) ||
      !music.spinning.includes(userId) //from other people
    ) {
      copyFiles.push({
        srcUrl: music.spinning,
        destFolder: `${page}/${listId}`,
        filename: 'spinning',
      })
    }
  } else {
    nullAssets.push('spinning')
  }

  if (music.celebrating.includes('http')) {
    if (
      music.celebrating.includes('Options') ||
      !music.celebrating.includes(`${page}%2F${listId}%2F`) ||
      !music.celebrating.includes(userId) //from other people
    ) {
      copyFiles.push({
        srcUrl: music.celebrating,
        destFolder: `${page}/${listId}`,
        filename: 'ending',
      })
    }
  } else {
    nullAssets.push('ending')
  }

  if (banners.topDesktop?.src) {
    if (
      banners.topDesktop.src.includes('Options') ||
      !banners.topDesktop.src.includes(`${page}%2F${listId}%2F`) ||
      !banners.topDesktop.src.includes(userId) //from other people
    ) {
      copyFiles.push({
        srcUrl: banners.topDesktop.src,
        destFolder: `${page}/${listId}`,
        filename: 'topDesktop',
      })
    }
  } else {
    nullAssets.push('topDesktop')
  }

  if (banners.bottomDesktop?.src) {
    if (
      banners.bottomDesktop.src.includes('Options') ||
      !banners.bottomDesktop.src.includes(`${page}%2F${listId}%2F`) ||
      !banners.bottomDesktop.src.includes(userId) //from other people
    ) {
      copyFiles.push({
        srcUrl: banners.bottomDesktop.src,
        destFolder: `${page}/${listId}`,
        filename: 'bottomDesktop',
      })
    }
  } else {
    nullAssets.push('bottomDesktop')
  }

  if (banners.topMobile?.src) {
    if (
      banners.topMobile.src.includes('Options') ||
      !banners.topMobile.src.includes(`${page}%2F${listId}%2F`) ||
      !banners.topMobile.src.includes(userId) //from other people
    ) {
      copyFiles.push({
        srcUrl: banners.topMobile.src,
        destFolder: `${page}/${listId}`,
        filename: 'topMobile',
      })
    }
  } else {
    nullAssets.push('topMobile')
  }

  if (banners.bottomMobile?.src) {
    if (
      banners.bottomMobile.src.includes('Options') ||
      !banners.bottomMobile.src.includes(`${page}%2F${listId}%2F`) ||
      !banners.bottomMobile.src.includes(userId) //from other people
    ) {
      copyFiles.push({
        srcUrl: banners.bottomMobile.src,
        destFolder: `${page}/${listId}`,
        filename: 'bottomMobile',
      })
    }
  } else {
    nullAssets.push('bottomMobile')
  }

  try {
    if (copyFiles.length > 0) {
      allPromises.push(copyStorageFiles(copyFiles))
    }

    if (nullAssets.length > 0) {
      const unusedAssets = await getUnusedAssetsName(
        `${page}/${listId}`,
        nullAssets,
      )
      if (unusedAssets.length > 0) {
        for (let name of unusedAssets) {
          allPromises.push(deleteUserAsset(`${page}/${listId}`, name))
        }
      }
    }

    if (allPromises.length === 0) return {}
    const resultsArray = await Promise.all(allPromises)
    const results = {}
    for (let result of resultsArray) {
      if (result.data) {
        //for copy files data
        for (let item of result.data) {
          const key = Object.keys(item)[0]
          results[key] = item[key]
        }
      } else {
        const key = Object.keys(result)[0]
        results[key] = result[key]
      }
    }
    return results
  } catch (error) {
    console.log('Error occurred', error)
  }
}

const replaceNewSettings = async (results, settingsData) => {
  const newSettingsData = cloneDeep(settingsData)
  // const cdnUrls = []
  if (results.bgImage) {
    newSettingsData.bgImage.src = results.bgImage
    // cdnUrls.push(replaceCDN(results.bgImage))
  } else if (results.bgImage === null) {
    //copyfiles failed
    newSettingsData.bgImage.src = null
  }

  if (results.spinning) {
    newSettingsData.music.spinning = results.spinning
    // cdnUrls.push(replaceCDN(results.spinning))
  } else if (results.spinning === null) {
    newSettingsData.music.spinning = 'tick-sound'
  }

  if (results.ending) {
    newSettingsData.music.celebrating = results.ending
    // cdnUrls.push(replaceCDN(results.ending))
  } else if (results.ending === null) {
    newSettingsData.music.celebrating = 'celebration-sound'
  }

  if (results.topDesktop) {
    newSettingsData.banners.topDesktop.src = results.topDesktop
    // cdnUrls.push(replaceCDN(results.topDesktop))
  } else if (results.topDesktop === null) {
    delete newSettingsData.banners.topDesktop
  }

  if (results.bottomDesktop) {
    newSettingsData.banners.bottomDesktop.src = results.bottomDesktop
    // cdnUrls.push(replaceCDN(results.bottomDesktop))
  } else if (results.bottomDesktop === null) {
    delete newSettingsData.banners.bottomDesktop
  }

  if (results.topMobile) {
    newSettingsData.banners.topMobile.src = results.topMobile
    // cdnUrls.push(replaceCDN(results.topMobile))
  } else if (results.topMobile === null) {
    delete newSettingsData.banners.topMobile
  }

  if (results.bottomMobile) {
    newSettingsData.banners.bottomMobile.src = results.bottomMobile
    // cdnUrls.push(replaceCDN(results.bottomMobile))
  } else if (results.bottomMobile === null) {
    delete newSettingsData.banners.bottomMobile
  }
  //if want to use purge, enable below
  // if (cdnUrls.length > 0) {
  //   const purgeCdnUrls = httpsCallable(functions, 'purgeCdnUrlsV2')
  //   await purgeCdnUrls({ urls: cdnUrls })
  // }
  return newSettingsData
}

export const deleteList = (page, listObject) => async (dispatch, getState) => {
  try {
    const listId = listObject.id
    const state = getState()
    // const listId = selectedList.id
    const user = auth.currentUser
    const currentFileKey = state.common.currentFileKey
    // const firestore = firebase.firestore()
    const batch = writeBatch(firestore)
    const userDocRef = doc(firestore, 'users', user.uid)
    const dataPath = doc(userDocRef, `save-lists-${page}`, listId)
    const namesPath = doc(userDocRef, `save-lists-names`, page)
    batch.delete(dataPath)
    batch.update(namesPath, {
      [listId]: deleteField(),
    })
    if (listObject.sharePath || listObject.shareId) {
      // for version 1 or 2
      //for share link
      const sharePath = listObject.sharePath || listObject.shareId
      const linkDocRef = doc(
        firestore,
        'share-wheels',
        page,
        'share-links',
        sharePath,
      )
      batch.delete(linkDocRef)
    }
    await batch.commit()
    // if (state.auth.currentUser?.isPremium !== undefined) {
    // only applicable to premium user, non subscribe can't get ispremium
    if (user.uid && listId) {
      //careful use
      const deleteFolder = httpsCallable(functions, 'deleteFolderV2')
      await deleteFolder({
        folderPath: `${user.uid}/${page}/${listId}`,
        userId: user.uid,
      })
    }
    // }

    let savedLists = null
    if (page !== 'rtg') {
      savedLists = state[page].savedChoicesList // clone deep needed else local save list will also change value based on reference
    } else {
      savedLists = state.rtg.savedPeopleList
    }

    const newSavedLists = removeObjectField(savedLists, listId)

    if (currentFileKey === listId) {
      await dispatch(resetInitialSettings())
      dispatch({
        type: `RESET_INITIAL_STATE_${page.toUpperCase()}`,
      })
      dispatch(setCurrentFileKey(''))
      dispatch(setCurrentTabData({}))
      dispatch(updateCurrentConfig(null))
    }
    switch (page) {
      case 'main':
        dispatch(setSavedLists(newSavedLists))
        break
      case 'rtg':
        dispatch(setSavedListsRTG(newSavedLists))
        break
      case 'rig':
        dispatch(setSavedListsRIG(newSavedLists))
        break
    }

    return true
  } catch (error) {
    if (error.code === 'permission-denied') {
      checkRecoverUser()
    }
    // dispatch(asyncActionError('loadin gButton'))
    throw error
  }
}

export const updateCustomization = data => async (dispatch, getState) => {
  const user = auth.currentUser
  // const userStore = getState().auth.currentUser
  const newCustomization = {
    customization: {
      ...data,
    },
  }
  const d = doc(firestore, 'users', user.uid)
  dispatch(updateUserCustomization(newCustomization.customization))
  await setDoc(d, newCustomization, { merge: true })
}

// export const deleteCustomizationField = field => async (dispatch, getState) => {
//   const user = auth.currentUser
//   const customizationField = `customization.${field}`
//   const newCustomization = {
//     [customizationField]: deleteField(),
//   }
//   const userStoreCustomization = {
//     ...getState().auth.currentUser.customization,
//   }
//   const newUserStoreCustomization = removeObjectField(
//     userStoreCustomization,
//     customizationField,
//   )
//   const d = doc(firestore, 'users', user.uid)
//   dispatch(updateUserStore({ customization: newUserStoreCustomization }))
//   await updateDoc(d, newCustomization)
// }

// export const updateBannersFirestore = async data => {
//   const user = auth.currentUser
//   const newBanners = {
//     // customization: {
//     banners: {
//       ...data,
//     },
//     // },
//   }

//   const d = doc(firestore, 'users', user.uid)
//   // dispatch(updateBanners(newBanners))
//   await setDoc(d, newBanners, { merge: true })
// }

// export const updateShareWheel = data => async (dispatch, getState) => {
//   const {
//     sharePage,
//     inputChecked,
//     titleChecked,
//     settingsChecked,
//     shareCanEdit,
//     listId,
//     shareId,
//     fileName,
//   } = data
//   const state = getState()

//   const user = auth.currentUser
//   const fSettings = state.settings

//   let toolData = null
//   let settingsData = null
//   switch (sharePage) {
//     case 'main':
//       if (inputChecked) {
//         const fChoices = state.main.choices
//         const weightOn = state.main.weightOn
//         const tempChoices = cloneDeep(fChoices)
//         tempChoices.forEach(choice => {
//           choice.count = 0
//           choice.filtered = false
//           if (!weightOn && choice.weight) {
//             delete choice.weight
//           }
//         })
//         toolData = {
//           choices: tempChoices,
//           mode: 0,
//         }
//         if (weightOn) {
//           toolData['weightOn'] = weightOn
//         }
//       }
//       break
//     case 'rig':
//       if (inputChecked) {
//         const fChoices = state.rig.choices
//         const tempChoices = cloneDeep(fChoices)
//         tempChoices.forEach(choice => {
//           choice.count = 0
//           choice.filtered = false
//         })
//         toolData = {
//           choices: tempChoices,
//           mode: 0,
//         }
//       }
//       break
//   }

//   if (inputChecked && titleChecked) {
//     if (toolData === null) {
//       // in case if the wheel have no inputs
//       toolData = {}
//     }
//     toolData['title'] = { ...state[sharePage].title }
//   }

//   if (settingsChecked) {
//     settingsData = { ...fSettings }
//     if (settingsData.hasOwnProperty('_persist')) {
//       delete settingsData._persist
//     }
//   }

//   const dataFunctions = {
//     tool: toolData,
//     settings: settingsData,
//     websiteMode: shareCanEdit ? 'ShareCanEdit' : 'ShareViewOnly',
//   }

//   // if (shareCanEdit) {
//   //   dataFunctions['websiteMode'] = 'ShareCanEdit'
//   // } else {
//   //   dataFunctions['websiteMode'] = "ShareViewOnly"
//   // }

//   const dataLength = JSON.stringify({ ...dataFunctions }).length
//   if (dataLength > 990000) {
//     //firestore can only store up to 1Mb/doc. check data length less than 1Mb
//     // dispatch(asyncActionError('share'))
//     throw {
//       error: 'Too many images/inputs, reduce some and try again.',
//     }
//   }
//   const batch = writeBatch(firestore)
//   const linkDocRef = doc(
//     firestore,
//     'share-wheels',
//     sharePage,
//     'share-links',
//     shareId,
//   )
//   const userFileDocRef = doc(
//     firestore,
//     'users',
//     user.uid,
//     'save-lists-names',
//     sharePage,
//   )

//   const nameObject = {
//     [listId]: {
//       id: listId,
//       name: fileName,
//       uniqueId: cuid(),
//       shareId: shareId,
//     },
//   }

//   dataFunctions['lastUpdated'] = serverTimestamp()

//   batch.update(linkDocRef, dataFunctions)
//   batch.update(userFileDocRef, nameObject)

//   await batch.commit()
//   return true
// }

// export const deleteShareWheel = async (data, deleteAccessOnly) => {
//   const { sharePage, listId, shareId } = data
//   try {
//     const user = auth.currentUser
//     // const firestore = firebase.firestore()
//     const batch = writeBatch(firestore)
//     const linkDocRef = doc(
//       firestore,
//       'share-wheels',
//       sharePage,
//       'share-links',
//       shareId,
//     )
//     const userFileDocRef = doc(
//       firestore,
//       'users',
//       user.uid,
//       'save-lists-names',
//       sharePage,
//     )
//     if (deleteAccessOnly) {
//       batch.update(linkDocRef, {
//         hasOwner: false,
//         lastUpdated: serverTimestamp(),
//       })
//     } else {
//       batch.delete(linkDocRef)
//     }

//     batch.update(userFileDocRef, {
//       [listId]: deleteField(),
//     })
//     await batch.commit()

//     return true
//   } catch (error) {
//     // dispatch(asyncActionError('loadingButton'))
//     throw error
//   }
// }

export const checkRecoverUser = async () => {
  const user = auth.currentUser
  const userData = {
    displayName: user.displayName,
    email: user.email,
    photoURL: user.photoURL || '',
    role: 'subscriber',
    gender: '',
    occupation: '',
    country: '',
    uid: user.uid,
    providerId: user.providerData[0].providerId, //no longer for many use, this is the user first providerid, only for increment and decrement user
    createdAt: user.metadata.createdAt,
    lastActive: user.metadata.lastLoginAt,
  }
  const createMissingUser = httpsCallable(functions, 'createMissingUserV2')
  await createMissingUser(userData)
}

export const migrateShareOneToShareTwo = async (page, listObject) => {
  const user = auth.currentUser

  const data = {
    fileKey: listObject.id,
    name: listObject.name,
    uniqueId: listObject.uniqueId,
    path: listObject.shareId,
    page: page,
    userId: user.uid,
  }

  const migrateShareVersion = httpsCallable(functions, 'migrateShareVersion')
  await migrateShareVersion(data)
}

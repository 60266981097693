import { createReducer } from '../reducerUtil'
import {
  UPDATE_WHEEL_COLORS,
  UPDATE_SPIN_BEHAVIOR,
  UPDATE_BACKGROUND_COLOR,
  UPDATE_MUSIC,
  UPDATE_CONFETTI,
  UPDATE_ALL_SETTINGS,
  UPDATE_MUSIC_VOLUME,
  INSTANT_INIT_SETTINGS,
  UPDATE_SPIN_BUTTON,
  UPDATE_BACKGROUND_IMAGE,
  UPDATE_BANNERS,
  RESET_INITIAL_SETTINGS,
  TURN_OFF_PREMIUM_SETTINGS,
  UPDATE_RTG_SPIN_BEHAVIOR,
} from './constants'

const initialState = {
  wheelColors: {
    colors: ['#2B580C', '#F7B71D', '#AFA939', '#FDEF96'],
    index: 0,
    arrayKey: 'Default',
  },
  bgColor: {
    color: '#B1BDA8',
    theme: 'Default',
  },
  spinBehavior: {
    speed: 5,
    duration: 10,
    manualStop: false,
    randomStart: true,
    mysterySpin: false,
    spinQuantity: false,
    initialSpinning: true,
    buttonAnimation: true,
  },
  rtgSpinBehavior: {
    groupingDuration: 5,
    representativeDuration: 3,
  },
  spinButton: {
    colorOn: false,
    color: '#3D3D3D',
    imageOn: false,
    image: null,
  },
  music: {
    volume: 0.4,
    soundOn: true,
    starting: 'button-sound',
    spinning: 'tick-sound',
    celebrating: 'celebration-sound',
    customOn: false,
  },
  confetti: {
    confettiOn: true,
  },
  bgImage: {
    on: false,
    src: null,
  },
  banners: {
    on: false,
  },
}

export const updateWheelColors = (state, payload) => {
  return {
    ...state,
    wheelColors: {
      colors: payload.colors,
      index: payload.index,
      arrayKey: payload.arrayKey,
    },
  }
}

export const updateBgColor = (state, payload) => {
  return {
    ...state,
    bgColor: {
      color: payload.color,
      theme: payload.theme,
    },
  }
}

export const updateSpinBehavior = (state, payload) => {
  return {
    ...state,
    spinBehavior: {
      speed: payload.speed,
      duration: payload.duration,
      manualStop: payload.manualStop,
      randomStart: payload.randomStart,
      mysterySpin: payload.mysterySpin,
      spinQuantity: payload.spinQuantity,
      initialSpinning: payload.initialSpinning,
      buttonAnimation: payload.buttonAnimation,
    },
  }
}

export const updateMusic = (state, payload) => {
  return {
    ...state,
    music: {
      volume: state.music.volume, //maintain the original volume
      soundOn: payload.soundOn,
      starting: payload.starting,
      spinning: payload.spinning,
      celebrating: payload.celebrating,
      customOn: payload.customOn,
    },
  }
}

export const updateConfetti = (state, payload) => {
  return {
    ...state,
    confetti: {
      confettiOn: payload.confettiOn,
    },
  }
}

export const updateAllSettings = (state, payload) => {
  return {
    ...state,
    ...payload.settings,
  }
}

export const updateMusicVolume = (state, payload) => {
  return {
    ...state,
    music: {
      ...state.music,
      volume: payload.volume,
      soundOn: payload.soundOn,
    },
  }
}

export const instantInitSettings = (state, payload) => {
  if (!payload.previousState) {
    // const newState = { ...state }
    // if (state.wheelColors.arrayKey === 'Custom')
    //   // change to default colors if it is custom
    //   newState.wheelColors = initialState.wheelColors
    // if ((state.bgColor.theme = 'Custom'))
    //   // change to default colors if it is custom
    //   newState.bgColor = initialState.bgColor
    return {
      ...state,
    }
  } else {
    return {
      ...payload.previousState,
    }
  }
}

export const updateSpinButton = (state, payload) => {
  return {
    ...state,
    spinButton: {
      colorOn: payload.colorOn,
      color: payload.color,
      imageOn: payload.imageOn,
      image: payload.image,
    },
  }
}

export const updateBackgroundImage = (state, payload) => {
  return {
    ...state,
    bgImage: {
      on: payload.on,
      src: payload.src,
    },
  }
}

export const updateBanners = (state, payload) => {
  return {
    ...state,
    banners: {
      ...state.banners,
      on: payload.on,
      ...payload.srcObj,
    },
  }
}

export const resetInitialSettings = (state, payload) => {
  return {
    ...initialState,
  }
}

export const turnOffPremiumSettings = (state, payload) => {
  return {
    ...state,
    banners: {
      ...state.banners,
      on: false,
    },
    bgImage: {
      ...state.bgImage,
      on: false,
    },
    spinButton: {
      ...state.spinButton,
      colorOn: false,
      imageOn: false,
    },
    music: {
      ...state.music,
      customOn: false,
    },
    // wheelColors: {
    //   ...state.wheelColors,
    // },
    // bgColor: {
    //   ...state.bgColor,
    // },
  }
}

export const updateRTGSpinBehavior = (state, payload) => {
  return {
    ...state,
    rtgSpinBehavior: {
      groupingDuration: payload.grouping,
      representativeDuration: payload.representative,
    },
  }
}

export default createReducer(initialState, {
  [UPDATE_SPIN_BEHAVIOR]: updateSpinBehavior,
  [UPDATE_WHEEL_COLORS]: updateWheelColors,
  [UPDATE_BACKGROUND_COLOR]: updateBgColor,
  [UPDATE_MUSIC]: updateMusic,
  [UPDATE_CONFETTI]: updateConfetti,
  [UPDATE_ALL_SETTINGS]: updateAllSettings,
  [UPDATE_MUSIC_VOLUME]: updateMusicVolume,
  [INSTANT_INIT_SETTINGS]: instantInitSettings,
  [UPDATE_SPIN_BUTTON]: updateSpinButton,
  [UPDATE_BACKGROUND_IMAGE]: updateBackgroundImage,
  [UPDATE_BANNERS]: updateBanners,
  [RESET_INITIAL_SETTINGS]: resetInitialSettings,
  [TURN_OFF_PREMIUM_SETTINGS]: turnOffPremiumSettings,
  [UPDATE_RTG_SPIN_BEHAVIOR]: updateRTGSpinBehavior,
})

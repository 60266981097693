import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_INPUT_MODE,
  SET_NUM_INPUT_SET,
  // TOGGLE_MUTE,
  SET_PAST_IP,
  SET_SPIN_COUNT,
  CLEAR_RESULT_SELECTED,
  CLEAR_RESULTS,
  SET_HISTORY,
  SET_TITLE_OBJ,
} from './constants'

import { setStartRotate } from '../turntable/actions'

export const showTurntable = turntable => ({
  type: SHOW_TURNTABLE,
  payload: {
    turntable,
  },
})

export const setTurntableSize = size => ({
  type: SET_TURNTABLE_SIZE,
  payload: {
    size,
  },
})

export const setResultSelected = result => ({
  type: SET_RESULT_SELECTED,
  payload: {
    result,
  },
})

export const clearResultSelected = () => ({
  type: CLEAR_RESULT_SELECTED,
})

export const clearResults = () => ({
  type: CLEAR_RESULTS,
})

export const setInputMode = inputMode => async (dispatch, getState) => {
  const state = getState()
  const startAngle = state.settings.spinBehavior.randomStart
    ? 2 * Math.PI * Math.random()
    : 0
  dispatch(setStartRotate(startAngle))
  dispatch(setInputModeAction(inputMode))
}

export const setInputModeAction = inputMode => ({
  type: SET_INPUT_MODE,
  payload: {
    inputMode,
  },
})

export const setNumInputSet = numInputSet => async (dispatch, getState) => {
  const state = getState()
  const startAngle = state.settings.spinBehavior.randomStart
    ? 2 * Math.PI * Math.random()
    : 0
  dispatch(setStartRotate(startAngle))
  dispatch(setNumInputAction(numInputSet))
}

export const setNumInputAction = numInputSet => ({
  type: SET_NUM_INPUT_SET,
  payload: {
    numInputSet,
  },
})

// export const toggleMute = () => ({
//   type: TOGGLE_MUTE,
// })

export const setPastIP = data => {
  return {
    type: SET_PAST_IP,
    payload: {
      data,
    },
  }
}

export const setSpinCount = data => {
  return {
    type: SET_SPIN_COUNT,
    payload: {
      data,
    },
  }
}

export const setHistory = history => ({
  type: SET_HISTORY,
  payload: {
    history,
  },
})

//03-07-2021 add title
export const setTitleActive = active => dispatch => {
  dispatch(setTitleObj({ active }))
}

export const setTitleText = text => dispatch => {
  dispatch(setTitleObj({ text }))
}

export const setTitleObj = obj => {
  return {
    type: SET_TITLE_OBJ,
    payload: {
      obj,
    },
  }
}

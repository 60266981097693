import {
  SET_WEBSITE_MODE,
  SET_CURRENT_FILE_KEY,
  SET_DATA_STATUS,
  SET_CURRENT_TAB_INDEX,
  SET_CURRENT_TAB_DATA,
  ADD_NEW_TAB_DATA,
  DELETE_TAB_DATA,
  SET_TOOL_SETTINGS_ACTIVE_INDEX,
  SET_IS_TOOL_SPINNING,
  // UPDATE_CURRENT_TAB_DATA,
} from './constants'
import { createReducer } from '../reducerUtil'

const initialState = {
  websiteMode: 'Normal', //Normal, ShareViewOnly, ShareCanEdit, ShareEditingMode
  currentFileKey: '',
  fileSharePath: '',
  dataStatus: 'Unchanged', //Unchanged | Modified | Saving
  currentTabIndex: 0,
  tabsData: [{ tabNumber: 1 }],
  totalTabs: 1,
  toolSettingsActiveIndex: 1, //1 equal close section, 0 equal to open section
  isToolSpinning: false, //whether tool is spinning, currently rtg using only
}

export const setWebsiteMode = (state, payload) => {
  return {
    ...state,
    websiteMode: payload.mode,
  }
}

export const setCurrentFileKey = (state, payload) => {
  return {
    ...state,
    currentFileKey: payload.key,
  }
}

export const setDataStatus = (state, payload) => {
  let usePayloadStatus =
    !!state.currentFileKey && state.websiteMode === 'Normal'
  if (payload.fromSettings) {
    if (!state.tabsData[state.currentTabIndex].hasOwnProperty('settings'))
      usePayloadStatus = false
  }
  return {
    ...state,
    dataStatus: usePayloadStatus ? payload.status : state.dataStatus,
  }
}

export const setCurrentTabIndex = (state, payload) => {
  return {
    ...state,
    currentTabIndex: payload.index,
  }
}

export const setCurrentTabData = (state, payload) => {
  const newArray = state.tabsData.slice(0)
  newArray[state.currentTabIndex] = {
    tabNumber: newArray[state.currentTabIndex].tabNumber,
    ...payload.data,
  }
  return {
    ...state,
    tabsData: newArray,
  }
}

export const addNewTabData = (state, payload) => {
  return {
    ...state,
    tabsData: [...state.tabsData, { tabNumber: state.totalTabs + 1 }],
    totalTabs: state.totalTabs + 1,
  }
}

export const deleteTabData = (state, payload) => {
  const newArray = state.tabsData.slice(0)
  newArray.splice(payload.index, 1)

  return {
    ...state,
    tabsData: newArray,
    currentTabIndex: payload.newCurrentIndex,
  }
}

export const setToolSettingsActiveIndex = (state, payload) => {
  return {
    ...state,
    toolSettingsActiveIndex: payload.value,
  }
}

export const setIsToolSpinning = (state, payload) => {
  return {
    ...state,
    isToolSpinning: payload.status,
  }
}

export default createReducer(initialState, {
  [SET_WEBSITE_MODE]: setWebsiteMode,
  [SET_CURRENT_FILE_KEY]: setCurrentFileKey,
  [SET_DATA_STATUS]: setDataStatus,
  [SET_CURRENT_TAB_INDEX]: setCurrentTabIndex,
  [SET_CURRENT_TAB_DATA]: setCurrentTabData,
  [ADD_NEW_TAB_DATA]: addNewTabData,
  [DELETE_TAB_DATA]: deleteTabData,
  [SET_TOOL_SETTINGS_ACTIVE_INDEX]: setToolSettingsActiveIndex,
  [SET_IS_TOOL_SPINNING]: setIsToolSpinning,
})

export const SET_PEOPLE = 'SET_PEOPLE'
export const CHANGE_KEY_TEXT_RTG = 'CHANGE_KEY_TEXT_RTG'
export const SET_GROUPS = 'SET_GROUPS'
export const SET_CSV_GROUPS = 'SET_CSV_GROUPS'
export const SET_NUM_GROUPS = 'SET_NUM_GROUPS'
export const OPEN_BOARD_MODAL = 'OPEN_BOARD_MODAL'
export const SAVE_LOCAL_PEOPLE_LIST = 'SAVE_LOCAL_PEOPLE_LIST_RTG'
export const SET_SAVED_LISTS = 'SET_SAVED_LISTS_RTG'
// export const SET_LIST_NAME = 'SET_LIST_NAME_RTG'
// export const SET_TITLE_ACTIVE = 'SET_TITLE_ACTIVE_RTG'
// export const SET_TITLE_TEXT = 'SET_TITLE_TEXT_RTG'
export const SET_TITLE_OBJ = 'SET_TITLE_OBJ_RTG'
export const SET_EVEN_SPLIT = 'SET_EVEN_SPLIT_RTG'
export const SET_GROUP_NAMES = 'SET_GROUP_NAMES_RTG'
export const SET_SHOW_ICON = 'SET_SHOW_ICON'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE_RTG'
export const REPLACE_STATE = 'REPLACE_STATE_RTG'
export const SET_PICK_QUANTITY = 'SET_PICK_QUANTITY_RTG'
export const SET_REPRESENTATIVE_INDEXES = 'SET_REPRESENTATIVE_INDEXES_RTG'
export const SET_PICK_REPRESENTATIVE = 'SET_PICK_REPRESENTATIVE_RTG'
export const SET_PRESET_GROUPS = 'SET_PRESET_GROUPS_RTG'

import {
  SET_PEOPLE,
  CHANGE_KEY_TEXT_RTG,
  SET_GROUPS,
  SET_CSV_GROUPS,
  SET_NUM_GROUPS,
  OPEN_BOARD_MODAL,
  // SET_LIST_NAME,
  SAVE_LOCAL_PEOPLE_LIST,
  // SET_TITLE_ACTIVE,
  // SET_TITLE_TEXT,
  SET_TITLE_OBJ,
  SET_EVEN_SPLIT,
  SET_GROUP_NAMES,
  SET_SAVED_LISTS,
  SET_SHOW_ICON,
  SET_PICK_QUANTITY,
  SET_REPRESENTATIVE_INDEXES,
  SET_PICK_REPRESENTATIVE,
  SET_PRESET_GROUPS,
} from './constants'
import { setCurrentFileKey, setDataStatus } from '../common/actions'
import seedrandom from 'seedrandom'

export const setPeople = people => dispatch => {
  dispatch({
    type: SET_PEOPLE,
    payload: {
      people,
    },
  })
  dispatch(setDataStatus('Modified'))
}

// export const setPeopleAction = people => ({
//   type: SET_PEOPLE,
//   payload: {
//     people,
//   },
// })

export const changeKeyTextRTG = () => ({
  type: CHANGE_KEY_TEXT_RTG,
})

const setGroups = groups => ({
  type: SET_GROUPS,
  payload: {
    groups,
  },
})

const setCSVGroups = groups => ({
  type: SET_CSV_GROUPS,
  payload: {
    groups,
  },
})

export const setNumGroups = (num, changeStatusModified) => dispatch => {
  dispatch({
    type: SET_NUM_GROUPS,
    payload: {
      num,
    },
  })
  if (changeStatusModified) dispatch(setDataStatus('Modified'))
}

export const groupPeople =
  (people, groupNum, celebrate, pickQuantityValue, pickRepresentativeChecked) =>
  async (dispatch, getState) => {
    dispatch(clearAllGroups())
    dispatch(setRepresentativeIndexes([]))
    await new Promise(resolve => setTimeout(resolve, 200))
    const {
      evenSplit,
      groupNames,
      presetGroups: unfilteredPresetGroups,
    } = getState().rtg
    const { groupingDuration } = getState().settings.rtgSpinBehavior

    let shuffledPeople = []
    if (evenSplit === 'Default') {
      shuffledPeople = shuffle(people)
    } else {
      const valueOptions =
        evenSplit === 'Gender'
          ? ['unisex', 'male', 'female']
          : ['A', 'B', 'C', 'D', 'E', 'F', 'G']
      const key = evenSplit.toLowerCase()
      for (const option of valueOptions) {
        const selectedPeople = people.filter(person => {
          let value = person[key]
          if (evenSplit === 'Label' && person[key] === undefined) value = 'A' //value A is not assigned in old data
          return value === option
        })
        const shuffled = shuffle(selectedPeople)
        shuffledPeople.push(...shuffled)
      }
    }
    // if (evenSplit === 'Gender') {
    //   const unisexPeople = people.filter(person => {
    //     return person.gender === 'unisex'
    //   })
    //   const malePeople = people.filter(person => {
    //     return person.gender === 'male'
    //   })
    //   const femalePeople = people.filter(person => {
    //     return person.gender === 'female'
    //   })

    //   const shuffledUnisex = shuffle(unisexPeople)
    //   const shuffledMale = shuffle(malePeople)
    //   const shuffledFemale = shuffle(femalePeople)

    //   shuffledPeople = [...shuffledUnisex, ...shuffledMale, ...shuffledFemale]
    // } else if (evenSplit === 'Label') {
    //   const labelOptions = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

    //   for (let option of labelOptions) {
    //     const selectedPeople = people.filter(person => {
    //       return person.label === option
    //     })
    //     const shuffledPeople = shuffle(selectedPeople)
    //     shuffledPeople.push(...shuffledPeople)
    //   }
    // } else {
    //   shuffledPeople = shuffle(people)
    // }

    if (evenSplit === 'Default' && unfilteredPresetGroups.length > 0) {
      const presetGroups = Array.from(
        Array(unfilteredPresetGroups.length),
        () => {
          return []
        },
      )

      for (let i = 0; i < unfilteredPresetGroups.length; i++) {
        for (let j = 0; j < unfilteredPresetGroups[i].length; j++) {
          if (!unfilteredPresetGroups[i][j].filtered) {
            //undefined or false
            presetGroups[i].push(unfilteredPresetGroups[i][j])
          }
        }
      }
      let highestLength = 0
      for (let i = 0; i < presetGroups.length; i++) {
        if (presetGroups[i].length > highestLength) {
          highestLength = presetGroups[i].length
        }
      }

      const onlyPreset = []
      for (let tempGroup of presetGroups) {
        onlyPreset.push(...tempGroup)
      }
      const excludePeople = shuffledPeople.filter(
        el => !onlyPreset.includes(el),
      )
      for (let i = 0; i < highestLength; i++) {
        for (let j = 0; j < presetGroups.length; j++) {
          if (presetGroups[j][i] !== undefined) {
            const index = i * groupNum + j
            excludePeople.splice(index, 0, presetGroups[j][i]) // If the index is greater than the length of the array, the index is set to the length of the array. If the index is less than 1, the index is set to 1.
          }
        }
      }
      shuffledPeople = [...excludePeople]
    }

    const groups = []

    // let j = 0
    for (let i = 0; i < pickQuantityValue; i++) {
      if (i < groupNum) {
        groups.push([shuffledPeople[i]])
      } else {
        const groupIndex = i % groupNum
        groups[groupIndex].push(shuffledPeople[i])
      }
    }

    // let unselected = null
    // if (pickQuantityValue !== shuffledPeople.length) {
    //   unselected = shuffledPeople.slice(pickQuantityValue - 1)
    // }
    const innerShuffledGroups = []
    for (let i = 0; i < groups.length; i++) {
      innerShuffledGroups.push(shuffle(groups[i]))
    }
    const shuffledGroups = shuffle(innerShuffledGroups)

    let representativeIndexes = []
    if (pickRepresentativeChecked) {
      representativeIndexes = chooseRepresentativeIndexes(shuffledGroups)
    }
    const csvGroups = csvGrouping(
      shuffledGroups,
      groupNames,
      evenSplit,
      representativeIndexes,
    )
    //wait 1 second first
    await new Promise(resolve => setTimeout(resolve, 1000))
    dispatch(setGroups(shuffledGroups))
    dispatch(setCSVGroups(csvGroups))
    await new Promise(resolve => setTimeout(resolve, groupingDuration * 1000)) //spin 5 second same as group member animation
    dispatch(setRepresentativeIndexes(representativeIndexes))
    // await new Promise(resolve =>
    //   setTimeout(resolve, Math.ceil(pickQuantityValue / groupNum) * 20),
    // )
    dispatch(setDataStatus('Modified'))
    celebrate()
  }

const shuffle = inputs => {
  const mathRandom = seedrandom()
  let arra1 = inputs.slice(0)
  let ctr = arra1.length
  let temp
  let index

  // While there are elements in the array
  while (ctr > 0) {
    // Pick a random index
    index = Math.floor(mathRandom() * ctr)
    // Decrease ctr by 1
    ctr--
    // And swap the last element with it
    temp = arra1[ctr]
    arra1[ctr] = arra1[index]
    arra1[index] = temp
  }
  return arra1.slice(0)
}

const csvGrouping = (groups, groupNames, evenSplit, representativeIndexes) => {
  const csvGroups = []
  let num = 0
  const header = ['Number', 'Team Name', 'Member']
  if (evenSplit !== 'Default') {
    header.push(evenSplit)
  }
  if (representativeIndexes.length > 0) {
    header.push('Representative')
  }
  csvGroups.push(header)
  for (let i = 0; i < groups.length; i++) {
    for (let j = 0; j < groups[i].length; j++) {
      num = num + 1
      const groupName = !!groupNames[i] ? groupNames[i] : `Team ${i + 1}`
      const tempData = [num.toString(), groupName, groups[i][j]['name']]
      if (evenSplit !== 'Default') {
        const key = evenSplit.toLowerCase()
        let extraData = groups[i][j][key]
        if (extraData === 'unisex') {
          extraData = `don't know`
        } else if (extraData === undefined && evenSplit === 'Label') {
          extraData = 'A'
        }
        tempData.push(extraData)
      }
      if (representativeIndexes.length > 0) {
        if (representativeIndexes[i] === j) {
          tempData.push('Selected')
        }
      }
      csvGroups.push(tempData)
    }
  }
  return csvGroups.slice(0)
}

export const manualPickRepresentatives = () => async (dispatch, getState) => {
  const { groups, groupNames, evenSplit } = getState().rtg
  const { representativeDuration } = getState().settings.rtgSpinBehavior
  dispatch(setRepresentativeIndexes([]))
  dispatch(setPickRepresentative(true))
  await new Promise(resolve => setTimeout(resolve, 200))
  const representativeIndexes = chooseRepresentativeIndexes(groups)
  const csvGroups = csvGrouping(
    groups,
    groupNames,
    evenSplit,
    representativeIndexes,
  )
  await new Promise(resolve =>
    setTimeout(resolve, representativeDuration * 1000),
  )
  dispatch(setCSVGroups(csvGroups))
  dispatch(setRepresentativeIndexes(representativeIndexes))
}

const chooseRepresentativeIndexes = groups => {
  const representativeIndexes = []
  for (let i = 0; i < groups.length; i++) {
    const mathRandom = seedrandom()
    const representativeIndex = Math.floor(mathRandom() * groups[i].length)
    representativeIndexes.push(representativeIndex)
  }
  return representativeIndexes
}

// const randomTime = () => {
//   // return Math.random() * (max - min) + min;
//   return Math.random() * 2500 + 2500

//   // const temp = Math.random() * 5000
//   // return temp > 2000 ? temp : 2000
// }

export const clearAllGroups = () => async (dispatch, getState) => {
  const numGroups = getState().rtg.numGroups
  const groups = []
  for (let i = 0; i < numGroups; i++) {
    groups.push([])
  }
  dispatch(setGroups(groups))
  dispatch(setCSVGroups([]))
  dispatch(setRepresentativeIndexes([]))
  dispatch(setDataStatus('Modified'))
}

export const changeNumGroups =
  (num, changeStatusModified = false) =>
  async (dispatch, getState) => {
    const state = getState()
    if (state.rtg.groups.length !== 0 && state.rtg.groups[0].length === 0) {
      const groups = []
      for (let i = 0; i < num; i++) {
        groups.push([])
      }
      dispatch(setGroups(groups))
    }
    dispatch(setNumGroups(num, changeStatusModified))
  }

export const openBoardModal = open => ({
  type: OPEN_BOARD_MODAL,
  payload: {
    open,
  },
})

// export const syncFirstPeopleList = () => {
//   return {
//     type: SYNC_FIRST_PEOPLE_LIST,
//   }
// }

// export const savePeopleListNames = (
//   aName,
//   bName,
//   cName,
//   dName,
//   eName,
//   fName,
//   gName,
// ) => {
//   return {
//     type: SAVE_PEOPLE_LIST_NAMES,
//     payload: {
//       aName,
//       bName,
//       cName,
//       dName,
//       eName,
//       fName,
//       gName,
//     },
//   }
// }

// export const setListName = name => {
//   return {
//     type: SET_LIST_NAME,
//     payload: {
//       name,
//     },
//   }
// }

export const saveLocalPeopleList = data => {
  return {
    type: SAVE_LOCAL_PEOPLE_LIST,
    payload: {
      data,
    },
  }
}

export const setLoadSaveDataRTG =
  (id, people = null, title = null, keyText = false) =>
  (dispatch, getState) => {
    dispatch(setCurrentFileKey(id))
    // dispatch(setListName(name))
    if (people) dispatch(setPeople(people))
    if (title) dispatch(setTitleObj(title))
    dispatch(setDataStatus('Unchanged'))
    if (keyText) dispatch(changeKeyTextRTG())
  }

//input item actions callback
export const itemInputChanged =
  (newValue, index) => async (dispatch, getState) => {
    const people = getState().rtg.people
    let newPeople = people.slice(0)
    newPeople[index].name = newValue
    dispatch(setPeople(newPeople.slice(0)))
  }

export const itemGenderChanged =
  (gender, index) => async (dispatch, getState) => {
    const people = getState().rtg.people
    let newPeople = people.slice(0)
    newPeople[index].gender = gender
    dispatch(setPeople(newPeople.slice(0)))
  }

export const itemCloseClicked = id => async (dispatch, getState) => {
  const people = getState().rtg.people
  let array = people.slice(0)
  array.splice(id, 1)
  dispatch(setPeople(array.slice(0)))
  dispatch(changeKeyTextRTG())
}

//03-07-2021 add title
export const setTitleActive = active => dispatch => {
  dispatch(setTitleObj({ active }))
}

export const setTitleText = text => dispatch => {
  dispatch(setTitleObj({ text }))
}

export const setTitleObj = obj => dispatch => {
  dispatch({
    type: SET_TITLE_OBJ,
    payload: {
      obj,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setEvenSplit = evenSplit => dispatch => {
  dispatch({
    type: SET_EVEN_SPLIT,
    payload: {
      evenSplit,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setGroupNames = names => dispatch => {
  dispatch({
    type: SET_GROUP_NAMES,
    payload: {
      names,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setSavedLists = lists => {
  return {
    type: SET_SAVED_LISTS,
    payload: {
      lists,
    },
  }
}

export const itemLabelChanged =
  (label, index) => async (dispatch, getState) => {
    const people = getState().rtg.people
    let newPeople = people.slice(0)
    newPeople[index]['label'] = label
    dispatch(setPeople(newPeople.slice(0)))
  }

export const setShowIcon = show => dispatch => {
  dispatch({
    type: SET_SHOW_ICON,
    payload: {
      show,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setPickQuantity = value => dispatch => {
  dispatch({
    type: SET_PICK_QUANTITY,
    payload: {
      value,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const itemToggleChanged =
  (status, index) => async (dispatch, getState) => {
    const people = getState().rtg.people
    let newPeople = people.slice(0)
    if (newPeople[index]['filtered'] === undefined) {
      newPeople[index]['filtered'] = true
    } else {
      newPeople[index]['filtered'] = !status //inverse the value
    }
    dispatch(setPeople(newPeople))
  }

export const setRepresentativeIndexes = indexes => dispatch => {
  dispatch({
    type: SET_REPRESENTATIVE_INDEXES,
    payload: {
      indexes,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setPickRepresentative = active => ({
  type: SET_PICK_REPRESENTATIVE,
  payload: {
    active,
  },
})

export const setPresetGroups = groups => ({
  type: SET_PRESET_GROUPS,
  payload: {
    groups,
  },
})

export const SHOW_TURNTABLE = 'SHOW_TURNTABLE_RIG'
export const SET_TURNTABLE_SIZE = 'SET_TURNTABLE_SIZE_RIG'
export const SET_RESULT_SELECTED = 'SET_RESULT_SELECTED_RIG'
export const SET_CHOICES = 'SET_CHOICES_RIG'
export const SET_PAST_IP = 'SET_PAST_IP_RIG'
export const SET_SPIN_COUNT = 'SET_SPIN_COUNT_RIG'
export const SET_HISTORY = 'SET_HISTORY_RIG'
export const SET_MODE = 'SET_MODE_RIG'
export const CHANGE_KEY_TEXT = 'CHANGE_KEY_TEXT_RIG'
export const SAVE_LOCAL_CHOICES_LIST = 'SAVE_LOCAL_CHOICES_LIST_RIG'
export const SET_SAVED_LISTS = 'SET_SAVED_LISTS_RIG'
// export const SET_LIST_NAME = 'SET_LIST_NAME_RIG'
// export const SET_TITLE_ACTIVE = 'SET_TITLE_ACTIVE_RIG'
// export const SET_TITLE_TEXT = 'SET_TITLE_TEXT_RIG'
export const SET_TITLE_OBJ = 'SET_TITLE_OBJ_RIG'
export const INSTANT_INIT = 'INSTANT_INIT_RIG'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE_RIG'
export const REPLACE_STATE = 'REPLACE_STATE_RIG'

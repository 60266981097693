import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import rootReducer from './rootReducer'
import thunk from 'redux-thunk'
import { verifyAuth } from './auth/actions'
// import { persistReducer } from "redux-persist"
// import storage from "redux-persist/lib/storage"

export const configureStore = preloadedState => {
  const composedEnhancer = composeWithDevTools(applyMiddleware(thunk))

  // const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })

  // const composedEnhancer = composeEnhancers(applyMiddleware(thunk))

  // const persistedReducer = persistReducer(persistConfig, rootReducer)

  // const store = createStore(persistedReducer, preloadedState, composedEnhancer)

  const store = createStore(rootReducer, preloadedState, composedEnhancer)

  if (
    typeof window !== 'undefined' &&
    !window.location.pathname.includes('/emb/') //exclude emb page
  ) {
    store.dispatch(verifyAuth())
  }
  if (process.env.GATSBY_BUILD_CONTEXT !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').default
        store.replaceReducer(newRootReducer)
      })
    }
  }

  return store
}

// import React from "react"
import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  SET_MODE,
  CHANGE_KEY_TEXT,
  // SET_PAST_IP,
  SET_SPIN_COUNT,
  // SET_LIST_NAME,
  SAVE_LOCAL_CHOICES_LIST,
  SET_HISTORY,
  // SET_TITLE_TEXT,
  // SET_TITLE_ACTIVE,
  SET_TITLE_OBJ,
  SET_WEIGHT_ON,
  SET_SAVED_LISTS,
  INSTANT_INIT,
  RESET_INITIAL_STATE,
  REPLACE_STATE,
} from './constants'
import { createReducer } from '../reducerUtil'
// import Turntable from "../../component/Turntable"
import cuid from 'cuid'

const initialState = {
  resultSelected: null, //main is object
  resultIndex: -1,
  choices: [],
  title: {
    active: false,
    text: '',
    description: '',
    popup: '',
  },
  history: [],
  weightOn: false,
  turnTable: null,
  turnTableSize: 300,
  inputKeyText: cuid(),
  mode: 0,
  // pastIP: '',
  spinCount: 0,
  // listName: '',
  savedChoicesList: {
    // A: { name: '', choices: [] },
    // B: { name: '', choices: [] },
    // C: { name: '', choices: [] },
    // D: { name: '', choices: [] },
    // E: { name: '', choices: [] },
    // F: { name: '', choices: [] },
    // G: { name: '', choices: [] },
    // H: { name: '', choices: [] },
    // I: { name: '', choices: [] },
    // J: { name: '', choices: [] },
    // K: { name: '', choices: [] },
    // L: { name: '', choices: [] },
    // M: { name: '', choices: [] },
    // N: { name: '', choices: [] },
    // O: { name: '', choices: [] },
  },
}

export const showTurntable = (state, payload) => {
  return {
    ...state,
    turnTable: payload.turntable,
  }
}

export const setTurntableSize = (state, payload) => {
  return {
    ...state,
    turnTable: state.turnTableSize === payload.size ? state.turnTable : null,
    turnTableSize: payload.size,
    // turnTable: null,
  }
}

export const setResultSelected = (state, payload) => {
  return {
    ...state,
    resultSelected: payload.result,
    resultIndex: payload.resultIndex,
  }
}
export const setChoices = (state, payload) => {
  return {
    ...state,
    choices: payload.choices,
    // turnTable: payload.nullTurntable ? null : state.turnTable,
    turnTable: null,
  }
}

export const setHistory = (state, payload) => {
  return {
    ...state,
    history: payload.history,
  }
}

export const setMode = (state, payload) => {
  return {
    ...state,
    mode: payload.mode,
  }
}

export const changeKeyText = (state, payload) => {
  return {
    ...state,
    inputKeyText: cuid(),
  }
}

// export const setPastIP = (state, payload) => {
//   return {
//     ...state,
//     pastIP: payload.data,
//   }
// }

export const setSpinCount = (state, payload) => {
  return {
    ...state,
    spinCount: payload.data,
  }
}

// export const setListName = (state, payload) => {
//   return {
//     ...state,
//     listName: payload.name,
//   }
// }

export const saveLocalChoicesList = (state, payload) => {
  return {
    ...state,
    savedChoicesList: {
      ...state.savedChoicesList,
      [payload.data.id]: payload.data,
    },
  }
}

export const setSavedLists = (state, payload) => {
  return {
    ...state,
    savedChoicesList: payload.lists,
  }
}

// export const setTitleActive = (state, payload) => {
//   return {
//     ...state,
//     title: {
//       ...state.title,
//       active: payload.active,
//     },
//   }
// }

// export const setTitleText = (state, payload) => {
//   return {
//     ...state,
//     title: {
//       ...state.title,
//       text: payload.text,
//     },
//   }
// }

export const setTitleObj = (state, payload) => {
  return {
    ...state,
    title: {
      ...state.title,
      ...payload.obj,
    },
  }
}

export const setWeightOn = (state, payload) => {
  return {
    ...state,
    weightOn: payload.status,
    turnTable: null,
  }
}

export const instantInit = (state, payload) => {
  if (!payload.previousState) {
    return {
      ...state,
    }
  } else {
    return {
      ...payload.previousState,
    }
  }
}

export const resetInitialState = (state, payload) => {
  const { savedChoicesList, turnTableSize, ...rest } = initialState
  return {
    ...state,
    ...rest,
  }
}

export const replaceState = (state, payload) => {
  return {
    ...state,
    ...payload.data,
    inputKeyText: cuid(),
  }
}

export default createReducer(initialState, {
  [SHOW_TURNTABLE]: showTurntable,
  [SET_RESULT_SELECTED]: setResultSelected,
  [SET_CHOICES]: setChoices,
  [SET_MODE]: setMode,
  [CHANGE_KEY_TEXT]: changeKeyText,
  [SET_TURNTABLE_SIZE]: setTurntableSize,
  // [SET_PAST_IP]: setPastIP,
  [SET_SPIN_COUNT]: setSpinCount,
  // [SET_LIST_NAME]: setListName,
  [SAVE_LOCAL_CHOICES_LIST]: saveLocalChoicesList,
  [SET_HISTORY]: setHistory,
  // [SET_TITLE_ACTIVE]: setTitleActive,
  // [SET_TITLE_TEXT]: setTitleText,
  [SET_TITLE_OBJ]: setTitleObj,
  [SET_WEIGHT_ON]: setWeightOn,
  [SET_SAVED_LISTS]: setSavedLists,
  [INSTANT_INIT]: instantInit,
  [RESET_INITIAL_STATE]: resetInitialState,
  [REPLACE_STATE]: replaceState,
})

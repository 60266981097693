import {
  UPDATE_BACKGROUND_COLOR,
  UPDATE_SPIN_BEHAVIOR,
  UPDATE_WHEEL_COLORS,
  UPDATE_MUSIC,
  UPDATE_CONFETTI,
  UPDATE_ALL_SETTINGS,
  UPDATE_MUSIC_VOLUME,
  UPDATE_SPIN_BUTTON,
  UPDATE_BACKGROUND_IMAGE,
  UPDATE_BANNERS,
  RESET_INITIAL_SETTINGS,
  TURN_OFF_PREMIUM_SETTINGS,
  INSTANT_INIT_SETTINGS,
  UPDATE_RTG_SPIN_BEHAVIOR,
} from './constants'
import { showTurntable } from '../homepage/actions'
import { showTurntable as showTurntableRNG } from '../rng/actions'
import { showTurntable as showTurntableYNW } from '../ynw/actions'
import { showTurntable as showTurntableRLG } from '../rlg/actions'
import { showTurntable as showTurntableRIG } from '../rig/actions'
import { showTurntable as showTurntableRCG } from '../rcg/actions'
import { showTurntable as showTurntableRDG } from '../rdg/actions'
import { showTurntable as showTurntableICP } from '../icp/actions'
import { setDataStatus } from '../common/actions'

export const updateBgColor = (color, theme) => dispatch => {
  dispatch({
    type: UPDATE_BACKGROUND_COLOR,
    payload: {
      color,
      theme,
    },
  })
  dispatch(setDataStatus('Modified', true))
}

export const updateWheelColors =
  (colors, index, arrayKey) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_WHEEL_COLORS,
        payload: {
          colors,
          index,
          arrayKey,
        },
      })
      // this is to redraw all the wheels with new colors, rmb to add new wheel here
      dispatch(showTurntable(null))
      dispatch(showTurntableYNW(null))
      dispatch(showTurntableRNG(null))
      dispatch(showTurntableRLG(null))
      dispatch(showTurntableRIG(null))
      dispatch(showTurntableRCG(null))
      dispatch(showTurntableRDG(null))
      dispatch(showTurntableICP(null))
      dispatch(setDataStatus('Modified', true))
    } catch (error) {
      console.log(error)
    }
  }

// const updateWheelColorsAction = (colors, index, arrayKey) => ({
//   type: UPDATE_WHEEL_COLORS,
//   payload: {
//     colors,
//     index,
//     arrayKey,
//   },
// })

export const updateSpinBehavior =
  (
    speed,
    duration,
    manualStop,
    randomStart,
    mysterySpin,
    spinQuantity,
    initialSpinning,
    buttonAnimation,
  ) =>
  async (dispatch, getState) => {
    try {
      const state = getState()
      const pastMysterySpin = state.settings.spinBehavior.mysterySpin
      const pastInitialSpinning = state.settings.spinBehavior.initialSpinning
      dispatch({
        type: UPDATE_SPIN_BEHAVIOR,
        payload: {
          speed,
          duration,
          manualStop,
          randomStart,
          mysterySpin,
          spinQuantity,
          initialSpinning,
          buttonAnimation,
        },
      })
      // this is to redraw all the wheels with new colors, rmb to add new wheel here
      if (
        pastMysterySpin !== mysterySpin ||
        pastInitialSpinning !== initialSpinning
      ) {
        dispatch(showTurntable(null))
        dispatch(showTurntableYNW(null))
        dispatch(showTurntableRNG(null))
        dispatch(showTurntableRLG(null))
        dispatch(showTurntableRIG(null))
        dispatch(showTurntableRCG(null))
        dispatch(showTurntableRDG(null))
        dispatch(showTurntableICP(null))
      }
      dispatch(setDataStatus('Modified', true))
    } catch (error) {
      console.log(error)
    }
  }

// export const updateSpinBehaviorAction = (
//   speed,
//   duration,
//   manualStop,
//   randomStart,
//   mysterySpin,
// ) => ({
//   type: UPDATE_SPIN_BEHAVIOR,
//   payload: {
//     speed,
//     duration,
//     manualStop,
//     randomStart,
//     mysterySpin,
//   },
// })

export const updateMusic =
  (soundOn, starting, spinning, celebrating, customOn) => dispatch => {
    dispatch({
      type: UPDATE_MUSIC,
      payload: {
        soundOn,
        starting,
        spinning,
        celebrating,
        customOn,
      },
    })
    dispatch(setDataStatus('Modified', true))
  }

export const updateConfetti = confettiOn => dispatch => {
  dispatch({
    type: UPDATE_CONFETTI,
    payload: {
      confettiOn,
    },
  })
  dispatch(setDataStatus('Modified', true))
}

export const updateAllSettings = settings => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_ALL_SETTINGS,
      payload: {
        settings,
      },
    })
    // this is to redraw all the wheels with new colors, rmb to add new wheel here
    dispatch(showTurntable(null))
    dispatch(showTurntableYNW(null))
    dispatch(showTurntableRNG(null))
    dispatch(showTurntableRLG(null))
    dispatch(showTurntableRIG(null))
    dispatch(showTurntableRCG(null))
    dispatch(showTurntableRDG(null))
    dispatch(showTurntableICP(null))
    dispatch(setDataStatus('Unchanged', true)) //load from sharewheel and load list
  } catch (error) {
    console.log(error)
  }
}

// const updateAllSettingsAction = settings => ({
//   type: UPDATE_ALL_SETTINGS,
//   payload: {
//     settings,
//   },
// })

export const updateMusicVolume = (volume, soundOn) => dispatch => {
  dispatch({
    type: UPDATE_MUSIC_VOLUME,
    payload: {
      volume,
      soundOn,
    },
  })
  dispatch(setDataStatus('Modified', true))
}

export const updateSpinButton =
  (colorOn, color, imageOn, image) => dispatch => {
    dispatch({
      type: UPDATE_SPIN_BUTTON,
      payload: {
        colorOn,
        color,
        imageOn,
        image,
      },
    })
    dispatch(setDataStatus('Modified', true))
  }

export const updateBackgroundImage = (on, src) => dispatch => {
  dispatch({
    type: UPDATE_BACKGROUND_IMAGE,
    payload: { on, src },
  })
  dispatch(setDataStatus('Modified', true))
}

export const updateBanners = (on, srcObj) => dispatch => {
  dispatch({
    type: UPDATE_BANNERS,
    payload: { on, srcObj },
  })
  dispatch(setDataStatus('Modified', true))
}

export const resetInitialSettings = () => {
  return {
    type: RESET_INITIAL_SETTINGS,
  }
}

export const instantInitSettings = previousState => {
  return {
    type: INSTANT_INIT_SETTINGS,
    payload: {
      previousState,
    },
  }
}

export const turnOffPremiumSettings = () => {
  return {
    type: TURN_OFF_PREMIUM_SETTINGS,
  }
}

export const updateRTGSpinBehavior = (grouping, representative) => dispatch => {
  dispatch({
    type: UPDATE_RTG_SPIN_BEHAVIOR,
    payload: {
      grouping,
      representative,
    },
  })
  dispatch(setDataStatus('Modified', true))
}

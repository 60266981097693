//this is for wrapRootElement

import React, { useState, useEffect } from 'react'
import { Grommet } from 'grommet'
import theme from '../theme/theme'
import GeneralModal from '../contextHook/generalModal'
import ToastProvider from '../contextHook/toast'
import DarkModeProvider from '../contextHook/darkMode'

import '../styles/index.css'
import '../styles/cssVariables.css'
// import { useDispatch } from 'react-redux'
// import { verifyAuth } from '../redux/auth/actions'

let initGrommet = false
const Layout = ({ children }) => {
  let darkMode = false
  const tempTheme = { ...theme } //shallow copy no problem, just want to change the shallow address
  if (typeof window !== 'undefined') {
    darkMode = localStorage.getItem('darkMode') === 'true'
    if (darkMode) {
      tempTheme.global.colors.brand = '#afa939'
    }
  }
  // const { value: darkMode } = useDarkMode()    // cant use useDarkMode here, because the darkmodeprovider is in inner child

  const [customTheme, setCustomTheme] = useState(tempTheme)

  useEffect(() => {
    if (initGrommet) {
      const tempTheme = { ...theme } //shallow copy no problem, just want to change the shallow address
      if (!darkMode) {
        tempTheme.global.colors.brand = '#2b580c'
      } else {
        tempTheme.global.colors.brand = '#afa939'
      }
      setCustomTheme(tempTheme)
    } else {
      initGrommet = true
    }
  }, [darkMode])

  return (
    <Grommet
      theme={customTheme}
      // themeMode={darkMode ? 'dark' : 'light'}
    >
      <DarkModeProvider>
        <ToastProvider>
          <GeneralModal>
            {/* <Helmet>
              <link rel="shortcut icon" href="/favicon.ico" />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
              />
              <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
            </Helmet> */}
            {children}
          </GeneralModal>
        </ToastProvider>
      </DarkModeProvider>
    </Grommet>
  )
}

export default Layout

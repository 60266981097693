import wrapRootProvider from './src/redux/ReduxWrapper'
import { wrapPageElement as wrapPage } from './src/layout/OuterLayout'
// import 'firebase/functions'
import './src/styles/customFonts.css'
import getFirebase from './src/utils/firebase/firebase' //also trying to initialize firebase
//

export const onClientEntry = () => {
  if (window.location.pathname.includes('/emb/')) return //exclude emb page
  getFirebase()
}

export const wrapRootElement = wrapRootProvider

export const wrapPageElement = wrapPage

export const onServiceWorkerUpdateReady = () => window.location.reload(true)

export const SHOW_TURNTABLE = 'SHOW_TURNTABLE_RDG'
export const SET_TURNTABLE_SIZE = 'SET_TURNTABLE_SIZE_RDG'
export const SET_RESULT_SELECTED = 'SET_RESULT_SELECTED_RDG'
export const SET_CHOICES = 'SET_CHOICES_RDG'
export const SET_HISTORY = 'SET_HISTORY_RDG'
export const SET_MODE = 'SET_MODE_RDG'
export const SET_INPUT_MODE = 'SET_INPUT_MODE_RDG'
export const SET_TITLE_OBJ = 'SET_TITLE_OBJ_RDG'
export const INSTANT_INIT = 'INSTANT_INIT_RDG'
export const SET_DAYS_MODE = 'SET_DAYS_MODE_RDG'
export const SET_INPUTS = 'SET_INPUTS_RDG'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE_RDG'
export const REPLACE_STATE = 'REPLACE_STATE_RDG'

// import firebase from 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth'
// import 'firebase/storage'
// v9 compat packages are API compatible with v8 code
import { initializeApp } from 'firebase/app'
// import { initializeFunctions } from 'firebase/functions'
// import { getFirestore } from 'firebase/firestore'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
// import { getStorage } from 'firebase/storage'
// import 'firebase/firestore'
// import 'firebase/auth'
// import 'firebase/storage'
// import firebase from 'firebase/compat/app'
// import 'firebase/compat/auth'
// import 'firebase/compat/firestore'
// import 'firebase/compact/storage'

// Firebase web config
const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  // measurementId: "G-P54FZ6VY7E"
}

let instance = null
export let firestore = null
export let storage = null
export let functions = null
export let auth = null

const getFirebase = () => {
  if (typeof window !== 'undefined') {
    if (instance) return instance
    instance = initializeApp(config)
    firestore = getFirestore(instance)
    auth = getAuth(instance)
    functions = getFunctions(instance)
    storage = getStorage(instance)
    if (process.env.GATSBY_USE_EMULATORS === `true`) {
      //turn on this for firebase emulator
      // Set up emulators
      connectFirestoreEmulator(firestore, 'localhost', 8080)
      connectAuthEmulator(auth, 'http://localhost:9099')
      connectFunctionsEmulator(functions, 'localhost', 5001)
      connectStorageEmulator(storage, 'localhost', 9199)
    }

    return instance
  }
  return null
}

export default getFirebase

export const DISPLAY_LIST_MODAL = 'DISPLAY_LIST_MODAL'
export const DISPLAY_LOAD_SAVE_MODAL = 'DISPLAY_LOAD_SAVE_MODAL'
// export const DISPLAY_SUCCESS_MODAL = 'DISPLAY_SUCCESS_MODAL'
export const DISPLAY_CUSTOM_TEAM_MODAL = 'DISPLAY_CUSTOM_TEAM_MODAL'
export const DISPLAY_VIDEO_MODAL = 'DISPLAY_VIDEO_MODAL'
export const DISPLAY_MODIFY_TITLE_MODAL = 'DISPLAY_MODIFY_TITLE_MODAL'
export const DISPLAY_NEW_WHEEL_DROP = 'DISPLAY_NEW_WHEEL_DROP'
export const DISPLAY_PRESET_GROUP_MODAL = 'DISPLAY_PRESET_GROUP_MODAL'
export const SET_RESULT_MODAL_APPEAR = 'SET_RESULT_MODAL_APPEAR'
export const SET_GENERAL_MODAL = 'SET_GENERAL_MODAL'

import {
  SET_PEOPLE,
  CHANGE_KEY_TEXT_RTG,
  SET_GROUPS,
  SET_CSV_GROUPS,
  SET_NUM_GROUPS,
  OPEN_BOARD_MODAL,
  // SET_LIST_NAME,
  SAVE_LOCAL_PEOPLE_LIST,
  // SET_TITLE_ACTIVE,
  // SET_TITLE_TEXT,
  SET_TITLE_OBJ,
  SET_EVEN_SPLIT,
  SET_GROUP_NAMES,
  SET_SAVED_LISTS,
  SET_SHOW_ICON,
  RESET_INITIAL_STATE,
  REPLACE_STATE,
  SET_PICK_QUANTITY,
  SET_REPRESENTATIVE_INDEXES,
  SET_PICK_REPRESENTATIVE,
  SET_PRESET_GROUPS,
} from './constants'
import { createReducer } from '../reducerUtil'
import cuid from 'cuid'

const initialState = {
  people: [],
  title: {
    active: false,
    text: '',
    description: '',
    popup: '',
  },
  groups: [[], []],
  groupNames: ['', ''],
  csvGroups: [],
  numGroups: 2,
  keyTextRTG: cuid(),
  boardModal: 0,
  // listName: '',
  savedPeopleList: {},
  evenSplit: 'Default',
  showIcon: true,
  pickQuantity: 0,
  representativeIndexes: [],
  pickRepresentative: false,
  presetGroups: [],
}

export const setPeople = (state, payload) => {
  return {
    ...state,
    people: payload.people,
  }
}

export const changeKeyTextRTG = (state, payload) => {
  return {
    ...state,
    keyTextRTG: cuid(),
  }
}

export const setGroups = (state, payload) => {
  return {
    ...state,
    groups: payload.groups,
  }
}

export const setCSVGroups = (state, payload) => {
  return {
    ...state,
    csvGroups: payload.groups,
  }
}

export const setNumGroups = (state, payload) => {
  return {
    ...state,
    numGroups: payload.num,
  }
}
export const openBoardModal = (state, payload) => {
  return {
    ...state,
    boardModal: payload.open,
  }
}

// export const setListName = (state, payload) => {
//   return {
//     ...state,
//     listName: payload.name,
//   }
// }

export const saveLocalPeopleList = (state, payload) => {
  return {
    ...state,
    savedPeopleList: {
      ...state.savedPeopleList,
      [payload.data.id]: payload.data,
    },
  }
}

export const setSavedLists = (state, payload) => {
  return {
    ...state,
    savedPeopleList: payload.lists,
  }
}

// export const setTitleActive = (state, payload) => {
//   return {
//     ...state,
//     title: {
//       ...state.title,
//       active: payload.active,
//     },
//   }
// }

// export const setTitleText = (state, payload) => {
//   return {
//     ...state,
//     title: {
//       ...state.title,
//       text: payload.text,
//     },
//   }
// }

export const setTitleObj = (state, payload) => {
  return {
    ...state,
    title: {
      ...state.title,
      ...payload.obj,
    },
  }
}

export const setEvenSplit = (state, payload) => {
  return {
    ...state,
    evenSplit: payload.evenSplit,
  }
}

export const setGroupNames = (state, payload) => {
  return {
    ...state,
    groupNames: payload.names,
  }
}

export const setShowIcon = (state, payload) => {
  return {
    ...state,
    showIcon: payload.show,
  }
}

export const setPickQuantity = (state, payload) => {
  return {
    ...state,
    pickQuantity: payload.value,
  }
}

export const resetInitialState = (state, payload) => {
  const { savedPeopleList, ...rest } = initialState
  return {
    ...state,
    ...rest,
  }
}

export const replaceState = (state, payload) => {
  return {
    ...state,
    ...payload.data,
    keyTextRTG: cuid(),
  }
}

export const setRepresentativeIndexes = (state, payload) => {
  return {
    ...state,
    representativeIndexes: payload.indexes,
  }
}

export const setPickRepresentative = (state, payload) => {
  return {
    ...state,
    pickRepresentative: payload.active,
  }
}

export const setPresetGroups = (state, payload) => {
  return {
    ...state,
    presetGroups: payload.groups,
  }
}

export default createReducer(initialState, {
  [SET_PEOPLE]: setPeople,
  [CHANGE_KEY_TEXT_RTG]: changeKeyTextRTG,
  [SET_GROUPS]: setGroups,
  [SET_CSV_GROUPS]: setCSVGroups,
  [SET_NUM_GROUPS]: setNumGroups,
  [OPEN_BOARD_MODAL]: openBoardModal,
  // [SYNC_FIRST_PEOPLE_LIST]: syncFirstPeopleList,
  // [SAVE_PEOPLE_LIST_NAMES]: savePeopleListNames,
  // [SET_LIST_NAME]: setListName,
  [SAVE_LOCAL_PEOPLE_LIST]: saveLocalPeopleList,
  // [SET_TITLE_ACTIVE]: setTitleActive,
  // [SET_TITLE_TEXT]: setTitleText,
  [SET_TITLE_OBJ]: setTitleObj,
  [SET_EVEN_SPLIT]: setEvenSplit,
  [SET_GROUP_NAMES]: setGroupNames,
  [SET_SAVED_LISTS]: setSavedLists,
  [SET_SHOW_ICON]: setShowIcon,
  [RESET_INITIAL_STATE]: resetInitialState,
  [REPLACE_STATE]: replaceState,
  [SET_PICK_QUANTITY]: setPickQuantity,
  [SET_REPRESENTATIVE_INDEXES]: setRepresentativeIndexes,
  [SET_PICK_REPRESENTATIVE]: setPickRepresentative,
  [SET_PRESET_GROUPS]: setPresetGroups,
})

import { createReducer } from '../reducerUtil'
import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  SET_HISTORY,
  SET_CONTINENTS_ACTIVE,
  SET_SHOW_MODE,
  // SET_TITLE_TEXT,
  // SET_TITLE_ACTIVE,
  SET_TITLE_OBJ,
  INSTANT_INIT,
  SET_MODE,
  RESET_INITIAL_STATE,
  REPLACE_STATE,
} from './constants'
import {
  allCountries,
  ContinentActiveList,
} from '../../component/ReactRCG/data'

const initialState = {
  resultSelected: '',
  resultIndex: -1,
  history: [],
  choices: allCountries,
  mode: 0,
  title: {
    active: false,
    text: '',
    description: '',
    popup: '',
  },
  turnTable: null,
  turnTableSize: 300,
  continentsActive: ContinentActiveList,
  showMode: 'Flag & Name',
}

export const setResultSelected = (state, payload) => {
  return {
    ...state,
    resultSelected: payload.result,
    resultIndex: payload.resultIndex,
  }
}

export const setChoices = (state, payload) => {
  return {
    ...state,
    choices: payload.choices,
    turnTable: payload.nullTurntable ? null : state.turnTable,
  }
}

export const setHistory = (state, payload) => {
  return {
    ...state,
    history: payload.history,
  }
}

export const setShowMode = (state, payload) => {
  return {
    ...state,
    showMode: payload.showMode,
    turnTable: null,
  }
}

export const showTurntable = (state, payload) => {
  return {
    ...state,
    turnTable: payload.turntable,
  }
}

export const setTurntableSize = (state, payload) => {
  return {
    ...state,
    turnTable: state.turnTableSize === payload.size ? state.turnTable : null,
    turnTableSize: payload.size,
  }
}

export const setMode = (state, payload) => {
  return {
    ...state,
    mode: payload.mode,
  }
}

export const setContinentsActive = (state, payload) => {
  return {
    ...state,
    continentsActive: payload.continentsActive,
  }
}

// export const setTitleActive = (state, payload) => {
//   return {
//     ...state,
//     title: {
//       ...state.title,
//       active: payload.active,
//     },
//   }
// }

// export const setTitleText = (state, payload) => {
//   return {
//     ...state,
//     title: {
//       ...state.title,
//       text: payload.text,
//     },
//   }
// }

export const setTitleObj = (state, payload) => {
  return {
    ...state,
    title: {
      ...state.title,
      ...payload.obj,
    },
  }
}

export const instantInit = (state, payload) => {
  if (!payload.previousState) {
    return {
      ...state,
    }
  } else {
    return {
      ...payload.previousState,
    }
  }
}

export const resetInitialState = (state, payload) => {
  const { turnTableSize, ...rest } = initialState
  return {
    ...state,
    ...rest,
  }
}

export const replaceState = (state, payload) => {
  return {
    ...state,
    ...payload.data,
  }
}

export default createReducer(initialState, {
  [SHOW_TURNTABLE]: showTurntable,
  [SET_RESULT_SELECTED]: setResultSelected,
  [SET_CHOICES]: setChoices,
  [SET_MODE]: setMode,
  [SET_HISTORY]: setHistory,
  [SET_TURNTABLE_SIZE]: setTurntableSize,
  [SET_CONTINENTS_ACTIVE]: setContinentsActive,
  [SET_SHOW_MODE]: setShowMode,
  // [SET_TITLE_ACTIVE]: setTitleActive,
  // [SET_TITLE_TEXT]: setTitleText,
  [SET_TITLE_OBJ]: setTitleObj,
  [INSTANT_INIT]: instantInit,
  [RESET_INITIAL_STATE]: resetInitialState,
  [REPLACE_STATE]: replaceState,
})

import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
} from 'react'

// const preferDarkQuery = '(prefers-color-scheme: dark)'

// const DarkModeContext = React.createContext(false);
// const DarkModeActionContext = React.createContext({
// 	setMode: (p) => {},
// 	toggle: () => {},
// });

const DarkModeContext = createContext()

export const useDarkMode = () => useContext(DarkModeContext)

const DarkModeProvider = ({
  children,
  storageKey = 'darkMode',
  classNameDark = 'dark-mode',
  classNameLight = 'light-mode',
}) => {
  const [darkMode, setDarkMode] = useState(false)

  // const listener = ({ matches }) => setMode(matches)

  const setMode = val => {
    setDarkMode(val)
    localStorage.setItem(storageKey, val ? 'true' : 'false')
    document.body.classList.add(val ? classNameDark : classNameLight)
    document.body.classList.remove(val ? classNameLight : classNameDark)
  }

  const toggle = useCallback(() => {
    setMode(!darkMode)
  }, [darkMode])

  useEffect(() => {
    const isDarkMode = document.body.classList.contains(classNameDark)
    setDarkMode(isDarkMode)

    // const mql = matchMedia(preferDarkQuery)
    // if (mql.media === preferDarkQuery) {
    //   mql.addEventListener('change', listener)
    //   return () => mql.removeEventListener('change', listener)
    // }
  }, [])

  return (
    <DarkModeContext.Provider value={{ value: darkMode, setMode, toggle }}>
      {children}
    </DarkModeContext.Provider>
  )
}

export default DarkModeProvider

// export const useDarkModeValue = () => {
// 	return useContext(DarkModeContext);
// };

// export const useDarkModeAction = () => {
// 	return useContext(DarkModeActionContext);
// };

// export const useDarkMode = () => {
// 	return [
// 		useContext(DarkModeContext),
// 		useContext(DarkModeActionContext),
// 	];
// };

import {
  OPEN_AUTH_MODAL,
  SIGN_IN_USER,
  SIGN_OUT_USER,
  UPDATE_LAST_ACTIVE_DATE,
  UPDATE_AUTH_DISPLAY_NAME,
  UPDATE_AUTH_PHOTO_URL,
  UPDATE_AUTH_ROLE,
  UPDATE_USER_STORE,
  SET_PREMIUM_HISTORY,
  SET_USER_IS_PREMIUM,
  UPDATE_USER_CUSTOMIZATION,
  SET_SHARE_PREMIUM,
} from './constants'
import { createReducer } from '../reducerUtil'

const initialState = {
  authModal: { show: false, customAction: null },
  lastActiveDate: 0,
  premiumHistory: false,
  authenticated: null, //must use null instead of false.. because useeffect will trigger once before the value change to false.
  currentUser: null,
  isSharePremium: false,
}

export const openAuthModal = (state, payload) => {
  return {
    ...state,
    authModal: payload,
  }
}

export const updateLastActiveDate = (state, payload) => {
  return {
    ...state,
    lastActiveDate: new Date().getTime(),
  }
}

export const signInUser = (state, payload) => {
  return {
    ...state,
    authenticated: true,
    currentUser: {
      email: payload.user.email,
      photoURL: payload.user.photoURL || '',
      uid: payload.user.uid,
      displayName: payload.user.displayName,
      providerId: payload.user.providerData[0].providerId, //do not rely on firestore providerId, it is single and may change at the auth but cant change at the firestore. So use auth providerId
    },
  }
}

export const signOutUser = (state, payload) => {
  return {
    ...state,
    authenticated: false,
    currentUser: null,
    premiumHistory: false,
  }
}

export const updateAuthDisplayName = (state, payload) => {
  return {
    ...state,
    authenticated: true,
    currentUser: {
      ...state.currentUser,
      displayName: payload.name,
    },
  }
}

export const updateAuthPhotoURL = (state, payload) => {
  return {
    ...state,
    authenticated: true,
    currentUser: {
      ...state.currentUser,
      photoURL: payload.url,
    },
  }
}

export const updateAuthRole = (state, payload) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      role: payload.role,
    },
  }
}

export const updateUserStore = (state, payload) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      ...payload.user,
    },
  }
}

export const setPremiumHistory = (state, payload) => {
  return {
    ...state,
    premiumHistory: payload.status,
  }
}

export const setUserIsPremium = (state, payload) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      isPremium: payload.isPremium,
    },
  }
}

export const updateUserCustomization = (state, payload) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      customization: {
        ...state.currentUser.customization,
        ...payload.customization,
      },
    },
  }
}

export const setSharePremium = (state, payload) => {
  return {
    ...state,
    isSharePremium: payload.premium,
  }
}

export default createReducer(initialState, {
  [OPEN_AUTH_MODAL]: openAuthModal,
  [SIGN_IN_USER]: signInUser,
  [SIGN_OUT_USER]: signOutUser,
  [UPDATE_LAST_ACTIVE_DATE]: updateLastActiveDate,
  [UPDATE_AUTH_DISPLAY_NAME]: updateAuthDisplayName,
  [UPDATE_AUTH_PHOTO_URL]: updateAuthPhotoURL,
  [UPDATE_AUTH_ROLE]: updateAuthRole,
  [UPDATE_USER_STORE]: updateUserStore,
  [SET_PREMIUM_HISTORY]: setPremiumHistory,
  [SET_USER_IS_PREMIUM]: setUserIsPremium,
  [UPDATE_USER_CUSTOMIZATION]: updateUserCustomization,
  [SET_SHARE_PREMIUM]: setSharePremium,
})

import { createReducer } from '../reducerUtil'
import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_INPUT_MODE,
  SET_NUM_INPUT_SET,
  // TOGGLE_MUTE,
  SET_PAST_IP,
  SET_SPIN_COUNT,
  CLEAR_RESULT_SELECTED,
  CLEAR_RESULTS,
  SET_HISTORY,
  SET_TITLE_OBJ,
  INSTANT_INIT,
  RESET_INITIAL_STATE,
  REPLACE_STATE,
} from './constants'

const initialState = {
  resultSelected: '',
  history: [],
  inputMode: 0,
  numInputSet: 4,
  results: {
    YES: 0,
    NO: 0,
    MAYBE: 0,
  },
  title: {
    active: false,
    text: '',
    description: '',
    popup: '',
  },
  turnTable: null,
  turnTableSize: 300,
  // mute: isMobile ? true : false,
  // pastIP: '',
  spinCount: 0,
}

export const setResultSelected = (state, payload) => {
  return {
    ...state,
    resultSelected: payload.result,
    results: {
      ...state.results,
      [payload.result]: state.results[payload.result] + 1,
    },
  }
}

export const clearResultSelected = (state, payload) => {
  return {
    ...state,
    resultSelected: '',
  }
}

export const clearResults = (state, payload) => {
  return {
    ...state,
    results: {
      YES: 0,
      NO: 0,
      MAYBE: 0,
    },
  }
}

export const setInputMode = (state, payload) => {
  return {
    ...state,
    inputMode: payload.inputMode,
    turnTable: null,
  }
}

export const setNumInputSet = (state, payload) => {
  return {
    ...state,
    numInputSet: payload.numInputSet,
    turnTable: null,
  }
}

export const showTurntable = (state, payload) => {
  return {
    ...state,
    turnTable: payload.turntable,
  }
}

export const setTurntableSize = (state, payload) => {
  return {
    ...state,
    turnTable: state.turnTableSize === payload.size ? state.turnTable : null,
    turnTableSize: payload.size,
  }
}

// export const toggleMute = (state, payload) => {
//   return {
//     ...state,
//     mute: !state.mute,
//   }
// }

export const setPastIP = (state, payload) => {
  return {
    ...state,
    pastIP: payload.data,
  }
}

export const setSpinCount = (state, payload) => {
  return {
    ...state,
    spinCount: payload.data,
  }
}

export const setHistory = (state, payload) => {
  return {
    ...state,
    history: payload.history,
  }
}

// export const setTitleActive = (state, payload) => {
//   return {
//     ...state,
//     title: {
//       ...state.title,
//       active: payload.active,
//     },
//   }
// }

// export const setTitleText = (state, payload) => {
//   return {
//     ...state,
//     title: {
//       ...state.title,
//       text: payload.text,
//     },
//   }
// }

export const setTitleObj = (state, payload) => {
  return {
    ...state,
    title: {
      ...state.title,
      ...payload.obj,
    },
  }
}

export const instantInit = (state, payload) => {
  if (!payload.previousState) {
    return {
      ...state,
    }
  } else {
    return {
      ...payload.previousState,
    }
  }
}

export const resetInitialState = (state, payload) => {
  const { turnTableSize, ...rest } = initialState
  return {
    ...state,
    ...rest,
  }
}

export const replaceState = (state, payload) => {
  return {
    ...state,
    ...payload.data,
  }
}

export default createReducer(initialState, {
  [SHOW_TURNTABLE]: showTurntable,
  [SET_RESULT_SELECTED]: setResultSelected,
  [SET_INPUT_MODE]: setInputMode,
  [SET_NUM_INPUT_SET]: setNumInputSet,
  [SET_TURNTABLE_SIZE]: setTurntableSize,
  // [TOGGLE_MUTE]: toggleMute,
  [SET_PAST_IP]: setPastIP,
  [SET_SPIN_COUNT]: setSpinCount,
  [CLEAR_RESULT_SELECTED]: clearResultSelected,
  [CLEAR_RESULTS]: clearResults,
  [SET_HISTORY]: setHistory,
  // [SET_TITLE_ACTIVE]: setTitleActive,
  // [SET_TITLE_TEXT]: setTitleText,
  [SET_TITLE_OBJ]: setTitleObj,
  [INSTANT_INIT]: instantInit,
  [RESET_INITIAL_STATE]: resetInitialState,
  [REPLACE_STATE]: replaceState,
})

import { createReducer } from '../reducerUtil'
import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  SET_PAST_IP,
  SET_SPIN_COUNT,
  SET_HISTORY,
  SET_MODE,
  SET_CUSTOM_INPUT,
  SET_INPUT_MODE,
  SET_STYLE_MODE,
  SET_TITLE_OBJ,
  INSTANT_INIT,
  RESET_INITIAL_STATE,
  REPLACE_STATE,
} from './constants'
import { upperLetter } from '../../component/ReactRLG/letterOptions'

const initialState = {
  resultSelected: '',
  history: [],
  mode: 0,
  choices: upperLetter,
  title: {
    active: false,
    text: '',
    description: '',
    popup: '',
  },
  turnTable: null,
  turnTableSize: 300,
  // pastIP: '',
  spinCount: 0,
  inputMode: 0,
  styleMode: 'UPPERCASE',
  customInput: '',
}

export const setResultSelected = (state, payload) => {
  return {
    ...state,
    resultSelected: payload.result,
  }
}

export const setChoices = (state, payload) => {
  return {
    ...state,
    choices: payload.choices,
    turnTable: payload.nullTurntable ? null : state.turnTable,
  }
}

export const setHistory = (state, payload) => {
  return {
    ...state,
    history: payload.history,
  }
}

export const setMode = (state, payload) => {
  return {
    ...state,
    mode: payload.mode,
  }
}

export const showTurntable = (state, payload) => {
  return {
    ...state,
    turnTable: payload.turntable,
  }
}

export const setTurntableSize = (state, payload) => {
  return {
    ...state,
    turnTable: state.turnTableSize === payload.size ? state.turnTable : null,
    turnTableSize: payload.size,
  }
}

export const setPastIP = (state, payload) => {
  return {
    ...state,
    pastIP: payload.data,
  }
}

export const setSpinCount = (state, payload) => {
  return {
    ...state,
    spinCount: payload.data,
  }
}

export const setInputMode = (state, payload) => {
  return {
    ...state,
    inputMode: payload.mode,
  }
}

export const setStyleMode = (state, payload) => {
  return {
    ...state,
    styleMode: payload.mode,
  }
}

export const setCustomInput = (state, payload) => {
  return {
    ...state,
    customInput: payload.input,
  }
}

// export const setTitleActive = (state, payload) => {
//   return {
//     ...state,
//     title: {
//       ...state.title,
//       active: payload.active,
//     },
//   }
// }

// export const setTitleText = (state, payload) => {
//   return {
//     ...state,
//     title: {
//       ...state.title,
//       text: payload.text,
//     },
//   }
// }

export const setTitleObj = (state, payload) => {
  return {
    ...state,
    title: {
      ...state.title,
      ...payload.obj,
    },
  }
}

export const instantInit = (state, payload) => {
  if (!payload.previousState) {
    return {
      ...state,
    }
  } else {
    return {
      ...payload.previousState,
    }
  }
}

export const resetInitialState = (state, payload) => {
  const { turnTableSize, ...rest } = initialState
  return {
    ...state,
    ...rest,
  }
}

export const replaceState = (state, payload) => {
  return {
    ...state,
    ...payload.data,
  }
}

export default createReducer(initialState, {
  [SHOW_TURNTABLE]: showTurntable,
  [SET_RESULT_SELECTED]: setResultSelected,
  [SET_CHOICES]: setChoices,
  [SET_MODE]: setMode,
  [SET_HISTORY]: setHistory,
  [SET_TURNTABLE_SIZE]: setTurntableSize,
  [SET_PAST_IP]: setPastIP,
  [SET_SPIN_COUNT]: setSpinCount,
  [SET_INPUT_MODE]: setInputMode,
  [SET_STYLE_MODE]: setStyleMode,
  [SET_CUSTOM_INPUT]: setCustomInput,
  // [SET_TITLE_ACTIVE]: setTitleActive,
  // [SET_TITLE_TEXT]: setTitleText,
  [SET_TITLE_OBJ]: setTitleObj,
  [INSTANT_INIT]: instantInit,
  [RESET_INITIAL_STATE]: resetInitialState,
  [REPLACE_STATE]: replaceState,
})

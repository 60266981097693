import {
  SET_START_ROTATE,
  SET_WHEEL_IMAGES,
  INCREASE_SPIN_QUANTITY,
  RESET_SPIN_QUANTITY,
  SET_IS_EDITING,
  SET_HAS_SPIN_ONCE,
} from './constants'
import { createReducer } from '../reducerUtil'

const initialState = {
  angle: 0,
  wheelImages: {},
  spinQuantity: 0,
  isEditing: false,
  hasSpinOnce: false,
}

export const setStartRotate = (state, payload) => {
  return {
    ...state,
    angle: payload.angle,
  }
}

export const setWheelImages = (state, payload) => {
  return {
    ...state,
    wheelImages: payload.wheelImages,
  }
}

export const increaseSpinQuantity = (state, payload) => {
  return {
    ...state,
    spinQuantity: state.spinQuantity + 1,
  }
}

export const resetSpinQuantity = (state, payload) => {
  return {
    ...state,
    spinQuantity: 0,
  }
}

export const setIsEditing = (state, payload) => {
  return {
    ...state,
    isEditing: payload.value,
  }
}

export const setHasSpinOnce = (state, payload) => {
  return {
    ...state,
    hasSpinOnce: payload.value,
  }
}

export default createReducer(initialState, {
  [SET_START_ROTATE]: setStartRotate,
  [SET_WHEEL_IMAGES]: setWheelImages,
  [INCREASE_SPIN_QUANTITY]: increaseSpinQuantity,
  [RESET_SPIN_QUANTITY]: resetSpinQuantity,
  [SET_IS_EDITING]: setIsEditing,
  [SET_HAS_SPIN_ONCE]: setHasSpinOnce,
})

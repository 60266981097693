import React from 'react'
import { Provider } from 'react-redux'
import { configureStore } from './configureStore'
import { persistorInit } from '../utils/reduxPersistor'
// import { persistStore } from 'redux-persist'
// import { PersistGate } from 'redux-persist/integration/react'
import Layout from '../layout/RootLayout'

const store = configureStore()
persistorInit(store)
// const persistor = persistStore(store)

export default ({ element, props }) => (
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <Layout>{element}</Layout>
    {/* </PersistGate> */}
  </Provider>
)

import React, { createContext, useState, useContext } from 'react'
import loadable from '@loadable/component'
// const SuccessModal = loadable(() => import('../component/Modals/SuccessModal'))
const ConfirmModal = loadable(() => import('../component/Modals/ConfirmModal'))
const NoActionModal = loadable(() =>
  import('../component/Modals/NoActionModal'),
)

const GeneralModalContext = createContext()

export const useGeneralModal = () => useContext(GeneralModalContext)

const GeneralModalProvider = ({ children }) => {
  // const [successModal, setSuccessModal] = useState(null)
  const [confirmModal, setConfirmModal] = useState(null)
  const [noActionModal, setNoActionModal] = useState(null)

  return (
    <GeneralModalContext.Provider
      value={{
        // setSuccessModal,
        setConfirmModal,
        setNoActionModal,
      }}
    >
      {children}
      {/* {successModal && <SuccessModal {...successModal} />} */}
      {confirmModal && <ConfirmModal {...confirmModal} />}
      {noActionModal && <NoActionModal {...noActionModal} />}
    </GeneralModalContext.Provider>
  )
}

export default GeneralModalProvider

export const SHOW_TURNTABLE = 'SHOW_TURNTABLE_MAIN' //added MAIN page behind on 12/8/2022 because of share instant init
export const SET_TURNTABLE_SIZE = 'SET_TURNTABLE_SIZE'
export const SET_RESULT_SELECTED = 'SET_RESULT_SELECTED'
export const SET_CHOICES = 'SET_CHOICES'
// export const SET_FILTERED_CHOICES = "SET_FILTERED_CHOICES"
// export const SET_CHOICES_COUNT = "SET_CHOICES_COUNT"
export const CHANGE_KEY_TEXT = 'CHANGE_KEY_TEXT'
export const SET_MODE = 'SET_MODE'
export const LOAD_DONOR_BOX = 'LOAD_DONOR_BOX'
// export const TOGGLE_MUTE = "TOGGLE_MUTE"
export const SET_PAST_IP = 'SET_PAST_IP'
export const SET_SPIN_COUNT = 'SET_SPIN_COUNT'
export const SAVE_LOCAL_CHOICES_LIST = 'SAVE_LOCAL_CHOICES_LIST'
export const SET_SAVED_LISTS = 'SET_SAVED_LISTS'
// export const SET_LIST_NAME = 'SET_LIST_NAME'
export const SET_HISTORY = 'SET_HISTORY_MAIN'
// export const SET_TITLE_ACTIVE = 'SET_TITLE_ACTIVE'
// export const SET_TITLE_TEXT = 'SET_TITLE_TEXT'
export const SET_TITLE_OBJ = 'SET_TITLE_OBJ_MAIN'
export const SET_WEIGHT_ON = 'SET_WEIGHT_ON'
export const INSTANT_INIT = 'INSTANT_INIT_MAIN'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE_MAIN'
export const REPLACE_STATE = 'REPLACE_STATE_MAIN'

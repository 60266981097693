import {
  KEEP_SHARE_LINK,
  // SET_SHARE_CONFIG,
  UPDATE_CURRENT_CONFIG,
  SET_PREVIOUS_STATE,
  SET_INITIALIZED,
  // SET_FILE_SHARE_CONFIG,
} from './constants'
import { createReducer } from '../reducerUtil'

const initialState = {
  currentConfig: {
    //these are the variables for current visit only, no persist.
    fileSharePath: '',
    shareCanEdit: false,
    // inputChecked: true,
    settingsChecked: false,
    shareMethod: 'Guest', //Guest or File
    noAdsChecked: false,
  },
  initialized: false, //only for first load
  createdCount: 0, //these are the variables for history purpose. Has persist. start here
  lastCreate: 0,
  dataFunctions: null,
  lastSharePath: '',
  // lastSharePage: null,
  // settings: null,
  // main: null,
  // rtg: null,
  // rig: null,
  // ynw: null, //ynw, rng, rlg, created for title data on /5/7/2021
  // rng: null,
  // rlg: null,
  // rcg: null,
  // rdg: null,
  // path: {},
  // shareCanEdit: {}, //these are the variables for history purpose. end here
  // // shareConfig: null, //this the variable for share editing mode
  previousState: {}, //this is to store the previous state when open share link
  // fileShareConfig: null,
}

export const keepShareLink = (state, payload) => {
  return {
    ...state,
    // lastSharePage: payload.sharePage,
    lastCreate: payload.lastCreate,
    dataFunctions: payload.data,
    createdCount: payload.createdCount,
    lastSharePath: payload.path,
  }
}

// export const setShareConfig = (state, payload) => {
//   return {
//     ...state,
//     shareConfig: payload.shareConfig,
//   }
// }

export const updateCurrentConfig = (state, payload) => {
  if (payload.config) {
    return {
      ...state,
      currentConfig: {
        ...state.currentConfig,
        ...payload.config,
      },
    }
  } else {
    return {
      ...state,
      currentConfig: {
        ...initialState.currentConfig,
      },
    }
  }
}

export const setPreviousState = (state, payload) => {
  return {
    ...state,
    previousState: payload.previousState,
  }
}

export const setInitialized = (state, payload) => {
  return {
    ...state,
    initialized: payload.status,
  }
}

export default createReducer(initialState, {
  [KEEP_SHARE_LINK]: keepShareLink,
  // [SET_SHARE_CONFIG]: setShareConfig,
  [UPDATE_CURRENT_CONFIG]: updateCurrentConfig,
  [SET_PREVIOUS_STATE]: setPreviousState,
  [SET_INITIALIZED]: setInitialized,
  // [SET_FILE_SHARE_CONFIG]: setFileShareConfig,
})

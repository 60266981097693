import { isImageData } from './imageUtil'
import { isBoolean, isString } from '.'

export const mainMigration = {
  0: state => {
    let isNameLabelImage = false
    for (let input of state.choices) {
      if (isImageData(input.name)) {
        isNameLabelImage = true
        break
      }
    }
    return {
      ...state,
      choices: !isNameLabelImage ? state.choices : [],
      title: {
        ...state.title,
        description: state.title.description || '',
        popup: state.title.popup || '',
      },
    }
  },
}

export const rtgMigration = {
  0: state => {
    let evenSplit = state.evenSplit
    if (isBoolean(state.evenSplit)) {
      evenSplit = !state.evenSplit ? 'Default' : 'Gender'
    }
    return {
      ...state,
      evenSplit: evenSplit,
      title: {
        ...state.title,
        description: state.title.description || '',
        popup: state.title.popup || '',
      },
    }
  },
}

export const rngMigration = {
  0: state => {
    return {
      ...state,
      title: {
        ...state.title,
        description: state.title.description || '',
        popup: state.title.popup || '',
      },
    }
  },
  1: state => {
    let digitNumber = 4
    let typeOption = 0
    if (state.typeOption > 0) {
      digitNumber = state.typeOption + 1
      typeOption = 1
    }

    return {
      ...state,
      typeOption,
      digitNumber,
    }
  },
}

export const generalMigration = {
  0: state => {
    return {
      ...state,
      title: {
        ...state.title,
        description: state.title.description || '',
        popup: state.title.popup || '',
      },
    }
  },
}

export const settingsMigration = {
  0: state => {
    const {
      starting: startingG,
      spinning: spinningG,
      celebrating: celebratingG,
    } = state.music
    const starting = !isBoolean(startingG)
      ? startingG
      : startingG
      ? 'button-sound'
      : 'no-sound'
    const spinning = !isBoolean(spinningG)
      ? spinningG
      : spinningG
      ? 'tick-sound'
      : 'no-sound'
    const celebrating = !isBoolean(celebratingG)
      ? celebratingG
      : celebratingG
      ? 'celebration-sound'
      : 'no-sound'

    const bgColor = !isString(state.bgColor)
      ? state.bgColor
      : {
          color: state.bgColor,
          theme: 'Default',
        }
    return {
      ...state,
      spinBehavior: {
        ...state.spinBehavior,
        mysterySpin: state.spinBehavior.mysterySpin || false,
        spinQuantity: state.spinBehavior.spinQuantity || false,
      },
      music: {
        ...state.music,
        starting: starting,
        spinning: spinning,
        celebrating: celebrating,
        customOn: state.music.customOn || false,
      },
      bgColor: bgColor,
    }
  },
  1: state => {
    return {
      ...state,
      spinBehavior: {
        ...state.spinBehavior,
        duration:
          state.spinBehavior.duration === 9 ? 10 : state.spinBehavior.duration, //change default to 10
        initialSpinning: isBoolean(state.spinBehavior.initialSpinning)
          ? state.spinBehavior.initialSpinning
          : true,
      },
    }
  },
  2: state => {
    return {
      ...state,
      spinBehavior: {
        ...state.spinBehavior,
        buttonAnimation: true,
      },
    }
  },
}

import {
  ref,
  uploadString,
  deleteObject,
  uploadBytes,
  getDownloadURL,
  getMetadata,
  listAll,
} from 'firebase/storage'
import { httpsCallable } from 'firebase/functions'
// import { updateBannersFirestore } from '../firestore/actions'
// import { updateBanners } from '../toolSettings/actions'
// import { updateBackgroundImage } from '../toolSettings/actions'
import { storage, auth, functions } from '../../utils/firebase/firebase'
import { replaceCDN } from '../../utils'

export const uploadUserImageFirebase = async file => {
  const user = auth.currentUser
  const storageRef = ref(storage, `user-images/${user.uid}`)
  const metadata = {
    cacheControl: 'private,max-age=31536000',
  }
  const result = await uploadString(storageRef, file, 'data_url', metadata)
  return await getDownloadURL(result.ref)
  // storageRef
  //   .child(`/user-images/${user.uid}`)
  //   .putString(file, 'data_url')
}

export const deleteUserImageFirebase = async () => {
  const user = auth.currentUser
  const storageRef = ref(storage, `user-images/${user.uid}`)
  return await deleteObject(storageRef)
}

// export const uploadBanners =
//   (banners, actions) => async (dispatch, getState) => {
//     const user = auth.currentUser
//     const metadata = {
//       cacheControl: 'public,max-age=31536000',
//     }

//     const bannerPromises = []

//     for (let key of Object.keys(banners)) {
//       if (actions[key] === 'UPLOAD') {
//         const storageBannerRef = ref(
//           storage,
//           `banner-images/${user.uid}/${key}`,
//         )
//         bannerPromises.push(
//           uploadAssetAsPromise(
//             storageBannerRef,
//             banners[key].file,
//             key,
//             metadata,
//           ),
//         )
//       } else if (actions[key] === 'DELETE') {
//         const storageBannerRef = ref(
//           storage,
//           `banner-images/${user.uid}/${key}`,
//         )
//         bannerPromises.push(deleteAssetAsPromise(storageBannerRef, key))
//       } else if (actions[key] === 'UPDATE_ONLY') {
//         bannerPromises.push(updateOnlyAsPromise(banners[key], key))
//       }
//     }
//     try {
//       const urls = await Promise.all(bannerPromises)
//       let srcObj = {}
//       let cdnUrls = []
//       for (let i of urls) {
//         //restore to object style
//         const key = Object.keys(i)[0]
//         const srcVal = i[key]
//         srcObj[key] = {
//           alignment: banners[key].alignment,
//         }
//         if (srcVal) {
//           srcObj[key]['src'] = srcVal
//           cdnUrls.push(replaceCDN(srcVal))
//         }
//       }
//       const purgeCdnUrls = httpsCallable(functions, 'purgeCdnUrlsV2')
//       await purgeCdnUrls({ urls: cdnUrls })
//       updateBannersFirestore(srcObj)
//       // dispatch(updateBanners(srcObj))
//       return srcObj
//     } catch (error) {
//       console.log(`Some failed: `, error.message)
//     }
//   }

//return a promise which upload file & get download URL
// export const uploadAssetAsPromise = async (
//   storageRef,
//   asset,
//   name,
//   metadata,
// ) => {
//   // const storageRef = ref(storage, `banner-images/${user.uid}/${name}`)
//   return uploadBytes(storageRef, asset, metadata)
//     .then(async snapshot => {
//       const downloadURL = await getDownloadURL(snapshot.ref)
//       return { [name]: downloadURL }
//     })
//     .catch(error => {
//       console.log('Upload failed:', name, error.message)
//       return { [name]: null }
//     })
// }

//return a promise which delete file
// const deleteAssetAsPromise = async (storageRef, name) => {
//   // const storageRef = ref(storage, `banner-images/${user.uid}/${name}`)
//   return deleteObject(storageRef)
//     .then(() => {
//       return { [name]: null }
//     })
//     .catch(error => {
//       console.log('Upload failed:', name, error.message)
//       return { [name]: null }
//     })
// }

// const updateOnlyAsPromise = (banner, name) => {
//   return Promise.resolve(true).then(() => {
//     return { [name]: banner.src }
//   })
// }

export const uploadUserAsset = async (asset, uid, folder, name, oriName) => {
  const user = auth.currentUser
  const metadata = {
    cacheControl: 'public,max-age=31536000',
    customMetadata: {
      uid,
    },
  }
  if (oriName) {
    metadata['customMetadata']['oriName'] = oriName
  }

  try {
    const storageRef = ref(storage, `users/${user.uid}/${folder}/${name}`)
    const snapshot = await uploadBytes(storageRef, asset, metadata)
    const downloadURL = await getDownloadURL(snapshot.ref)
    // const purgeCdnUrls = httpsCallable(functions, 'purgeCdnUrls')
    // await purgeCdnUrls({ urls: [replaceCDN(downloadURL)] })
    return { [name]: downloadURL }
  } catch (error) {
    console.log('Upload asset failed:', error.message)
    return { [name]: null }
  }
}

export const deleteUserAsset = async (folder, name) => {
  const user = auth.currentUser
  try {
    const storageRef = ref(storage, `users/${user.uid}/${folder}/${name}`)
    await deleteObject(storageRef)
    return { [name]: null }
  } catch (error) {
    if (error.code == 'storage/object-not-found') {
      console.log('Object not found!')
    }
    return { [name]: null }
  }
}

// export const uploadCustomMusics = async musicFiles => {
//   const user = auth.currentUser

//   const musicPromises = []
//   for (const key in musicFiles) {
//     if (key === 'starting' || key === 'spinning' || key === 'ending') {
//       if (musicFiles[key].file) {
//         const metadata = {
//           cacheControl: 'public,max-age=31536000',
//           customMetadata: {
//             oriName: musicFiles[key].name,
//           },
//         }
//         const storageRef = ref(
//           storage,
//           `users/${user.uid}/custom-musics/${key}`,
//         )
//         musicPromises.push(
//           uploadAssetAsPromise(storageRef, musicFiles[key].file, key, metadata),
//         )
//       }
//     }
//   }

//   try {
//     const urls = await Promise.all(musicPromises)
//     let urlObj = {}
//     let cdnUrls = []
//     for (let i of urls) {
//       //restore to object style
//       const key = Object.keys(i)[0]
//       const srcVal = i[key]
//       if (srcVal) {
//         urlObj[key] = srcVal
//         cdnUrls.push(replaceCDN(srcVal))
//       }
//     }
//     const purgeCdnUrls = httpsCallable(functions, 'purgeCdnUrlsV2')
//     await purgeCdnUrls({ urls: cdnUrls })
//     return urlObj
//   } catch (error) {
//     console.log(`Some failed: `, error.message)
//   }
// }

export const getUserAssetMetaData = async path => {
  const assetRef = ref(storage, path)

  return getMetadata(assetRef)
}
// } catch (error) {
//   console.log(error)
// }
// }

export const copyStorageFiles = async files => {
  const copyFiles = httpsCallable(functions, 'copyFilesV2')
  return copyFiles(files)
}

export const getUnusedAssetsName = async (folder, assets) => {
  const user = auth.currentUser
  try {
    const array = []
    const listRef = ref(storage, `users/${user.uid}/${folder}`)
    const dir = await listAll(listRef)
    dir.items.forEach(fileRef => {
      if (assets.includes(fileRef.name)) {
        array.push(fileRef.name)
      }
    })
    return array
  } catch (error) {
    console.log(error)
  }
}

export const updateOptionsAssets = async (
  array,
  folder,
  includeName = false,
) => {
  try {
    const allPromises = []
    const unusedAssetsName = []
    for (let i = 0; i < array.length; i++) {
      if (array[i].file) {
        allPromises.push(
          uploadUserAsset(
            array[i].file,
            array[i].uid,
            folder,
            `${i}`,
            includeName ? array[i].name : '',
          ),
        )
      } else if (array[i].src === null) {
        unusedAssetsName.push(i.toString())
      }
    }

    const toBeDeletedAssets = await getUnusedAssetsName(
      folder,
      unusedAssetsName,
    )

    if (toBeDeletedAssets.length > 0) {
      for (let item of toBeDeletedAssets) {
        allPromises.push(deleteUserAsset(folder, item))
      }
    }

    if (allPromises.length === 0) return array.slice(0)
    const results = await Promise.all(allPromises)

    const newArray = array.slice(0)
    // const cdnUrls = []
    for (let result of results) {
      const key = Object.keys(result)[0]
      const index = parseInt(key)
      if (index >= 0) {
        const newSrc = result[key]
        newArray[index] = { src: newSrc }
        // if (newSrc) cdnUrls.push(replaceCDN(newSrc))
        if (includeName && newSrc) {
          newArray[index]['name'] = array[index].name
        }
        if (array[index].uid) {
          newArray[index]['uid'] = array[index].uid
        }
      }
    }

    //if want to use purge, enable below
    // if (cdnUrls.length > 0) {
    //   const purgeCdnUrls = httpsCallable(functions, 'purgeCdnUrlsV2')
    //   await purgeCdnUrls({ urls: cdnUrls })
    // }

    return newArray
  } catch (error) {
    console.log(error)
  }
}

export const SHOW_TURNTABLE = 'SHOW_TURNTABLE_RCG'
export const SET_TURNTABLE_SIZE = 'SET_TURNTABLE_SIZE_RCG'
export const SET_RESULT_SELECTED = 'SET_RESULT_SELECTED_RCG'
export const SET_MODE = 'SET_MODE_RCG'
export const SET_CHOICES = 'SET_CHOICES_RCG'
export const SET_HISTORY = 'SET_HISTORY_RCG'
export const SET_CONTINENTS_ACTIVE = 'SET_CONTINENTS_ACTIVE_RCG'
export const SET_SHOW_MODE = 'SET_SHOW_MODE_RCG'
// export const SET_TITLE_ACTIVE = 'SET_TITLE_ACTIVE_RCG'
// export const SET_TITLE_TEXT = 'SET_TITLE_TEXT_RCG'
export const SET_TITLE_OBJ = 'SET_TITLE_OBJ_RCG'
export const INSTANT_INIT = 'INSTANT_INIT_RCG'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE_RCG'
export const REPLACE_STATE = 'REPLACE_STATE_RCG'

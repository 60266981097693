import React, { createContext, useState, useContext, useRef } from 'react'
import Toast from '../component/Toast'

const ToastContext = createContext()

export const useToast = () => useContext(ToastContext)

const ToastProvider = ({ children }) => {
  const [toast, setToastData] = useState(null)
  const timer = useRef(null)

  // let timer = null
  const setToast = data => {
    setToastData(data)
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      setToastData(null)
    }, 3000)
  }

  const close = () => {
    setToastData(null)
  }

  const success = text => {
    setToast({
      text,
      type: 'Success',
    })
  }

  const warning = text => {
    setToast({
      text,
      type: 'Warning',
    })
  }

  return (
    <ToastContext.Provider value={{ success, warning, close }}>
      {children}
      <Toast data={toast} close={close} />
    </ToastContext.Provider>
  )
}

export default ToastProvider

import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  // TOGGLE_MUTE,
  SET_PAST_IP,
  SET_SPIN_COUNT,
  SET_MODE,
  SET_HISTORY,
  SET_RESULTS_ARRAY,
  SET_BOARD_INDEX,
  SET_TYPE_OPTION,
  SET_AUTO_SPIN_MERGE,
  SET_MIN_VALUE,
  SET_MAX_VALUE,
  SET_INTERVAL_VALUE,
  SET_TITLE_OBJ,
  SET_EXCLUDE_FIELD_VALUE,
  SET_FORMULA_FIELD_VALUE,
  SET_INPUT_METHOD,
  SET_DIGIT_RANGES,
  SET_DIGIT_NUMBER,
} from './constants'
import { setStartRotate } from '../turntable/actions'
import { setDataStatus } from '../common/actions'

export const showTurntable = turntable => ({
  type: SHOW_TURNTABLE,
  payload: {
    turntable,
  },
})

export const setTurntableSize = size => ({
  type: SET_TURNTABLE_SIZE,
  payload: {
    size,
  },
})

export const setResultSelected = result => ({
  type: SET_RESULT_SELECTED,
  payload: {
    result,
  },
})

export const setHistory = history => ({
  type: SET_HISTORY,
  payload: {
    history,
  },
})

export const setChoices =
  (choices, resetAngle = true) =>
  async (dispatch, getState) => {
    if (resetAngle) {
      const state = getState()
      const startAngle = state.settings.spinBehavior.randomStart
        ? 2 * Math.PI * Math.random()
        : 0
      dispatch(setStartRotate(startAngle))
    }
    dispatch({
      type: SET_CHOICES,
      payload: {
        choices,
      },
    })
    // dispatch(setDataStatus('Modified'))
  }

// export const setChoicesAction = (choices, nullTurntable = true) => ({
//   type: SET_CHOICES,
//   payload: {
//     choices,
//     nullTurntable,
//   },
// })

export const setMode = mode => ({
  type: SET_MODE,
  payload: {
    mode,
  },
})

// export const toggleMute = () => ({
//   type: TOGGLE_MUTE,
// })

export const setPastIP = data => {
  return {
    type: SET_PAST_IP,
    payload: {
      data,
    },
  }
}

export const setSpinCount = data => {
  return {
    type: SET_SPIN_COUNT,
    payload: {
      data,
    },
  }
}

export const setResultsArray = results => dispatch => {
  dispatch({
    type: SET_RESULTS_ARRAY,
    payload: {
      results,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setBoardIndex = index => dispatch => {
  dispatch({
    type: SET_BOARD_INDEX,
    payload: {
      index,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setTypeOption = option => dispatch => {
  dispatch({
    type: SET_TYPE_OPTION,
    payload: {
      option,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setAutoSpinMerge = status => dispatch => {
  dispatch({
    type: SET_AUTO_SPIN_MERGE,
    payload: {
      status,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setMinValueStore = min => dispatch => {
  dispatch({
    type: SET_MIN_VALUE,
    payload: {
      min,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setMaxValueStore = max => dispatch => {
  dispatch({
    type: SET_MAX_VALUE,
    payload: {
      max,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setIntervalValueStore = interval => dispatch => {
  dispatch({
    type: SET_INTERVAL_VALUE,
    payload: {
      interval,
    },
  })
  dispatch(setDataStatus('Modified'))
}

//03-07-2021 add title
export const setTitleActive = active => dispatch => {
  dispatch(setTitleObj({ active }))
}

export const setTitleText = text => dispatch => {
  dispatch(setTitleObj({ text }))
}

export const setTitleObj = obj => dispatch => {
  dispatch({
    type: SET_TITLE_OBJ,
    payload: {
      obj,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setExcludeFieldValue = value => dispatch => {
  dispatch({
    type: SET_EXCLUDE_FIELD_VALUE,
    payload: {
      value,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setFormulaFieldValue = value => dispatch => {
  dispatch({
    type: SET_FORMULA_FIELD_VALUE,
    payload: {
      value,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setInputMethod = method => dispatch => {
  dispatch({
    type: SET_INPUT_METHOD,
    payload: {
      method,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setDigitRanges = ranges => dispatch => {
  dispatch({
    type: SET_DIGIT_RANGES,
    payload: {
      ranges,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setDigitNumber = number => dispatch => {
  dispatch({
    type: SET_DIGIT_NUMBER,
    payload: {
      number,
    },
  })
  dispatch(setDataStatus('Modified'))
}

import {
  OPEN_AUTH_MODAL,
  SIGN_IN_USER,
  SIGN_OUT_USER,
  UPDATE_LAST_ACTIVE_DATE,
  UPDATE_AUTH_DISPLAY_NAME,
  UPDATE_AUTH_PHOTO_URL,
  UPDATE_AUTH_ROLE,
  UPDATE_USER_STORE,
  SET_PREMIUM_HISTORY,
  SET_USER_IS_PREMIUM,
  UPDATE_USER_CUSTOMIZATION,
  SET_SHARE_PREMIUM,
} from './constants'
// import {
//   asyncActionStart,
//   asyncActionFinish,
//   asyncActionError,
//   authLoaded,
// } from '../async/actions'
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  fetchSignInMethodsForEmail,
  signInWithPopup,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  FacebookAuthProvider,
  GoogleAuthProvider,
  updateProfile,
  getIdTokenResult,
  getIdToken,
  updateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  linkWithPopup,
  unlink,
  linkWithCredential,
  reauthenticateWithPopup,
  checkActionCode,
  applyActionCode,
} from 'firebase/auth'
import {
  getDoc,
  getDocs,
  doc,
  onSnapshot,
  updateDoc,
  collection,
  writeBatch,
} from 'firebase/firestore'
import getFirebase from '../../utils/firebase/firebase'
import { setUserProfileData, updateLastActive } from '../firestore/actions'
// import { updateProfilePhotoURL } from '../account/actions'
import { withTimeout, removeObjectField } from '../../utils'
import { deleteUserImageFirebase } from '../firebaseStorage/actions'
import { firestore, auth, functions } from '../../utils/firebase/firebase'
import { httpsCallable } from 'firebase/functions'
import { turnOffPremiumSettings } from '../toolSettings/actions'

// const auth = getAuth(firebaseApp)

const TIMEOUT_AUTH = 30000
let emailIdentity = false
// let isNew = false

export const registerInFirebase = creds => async (dispatch, getState) => {
  try {
    // isNew = true
    const result = await withTimeout(
      TIMEOUT_AUTH + 30000, //1 minute timeout
      createUserWithEmailAndPassword(auth, creds.email, creds.password),
    )
    // const result = await createUserWithEmailAndPassword(
    //   auth,
    //   creds.email,
    //   creds.password,
    // )
    await updateProfile(result.user, {
      displayName: creds.name,
    })
    await setUserProfileData(result.user)
    dispatch(updateLastActiveDate())
    // return 'abc'
    // return result
    //   return await setUserProfileData(result.user);
  } catch (error) {
    // console.log('error', error)
    throw error
  }
}

export const signInWithEmail = async creds => {
  try {
    const result = await withTimeout(
      TIMEOUT_AUTH,
      signInWithEmailAndPassword(auth, creds.email, creds.password),
    )
    // return result
    //   return await setUserProfileData(result.user);
  } catch (error) {
    if (error.code === 'auth/wrong-password') {
      const methods = await fetchSignInMethodsForEmail(auth, creds.email)
      if (methods.length === 0) throw { code: 'auth/user-not-found' }
      if (methods[0] === 'google.com') {
        throw { code: 'auth/different-provider-google' }
      } else if (methods[0] === 'facebook.com') {
        throw { code: 'auth/different-provider-facebook' }
      }
    }
    throw error
  }
}

export const signOutFirebase = () => {
  return signOut(auth)
}

export const socialLogin = selectedProvider => async (dispatch, getState) => {
  let provider
  if (selectedProvider === 'facebook') {
    provider = new FacebookAuthProvider()
  }
  if (selectedProvider === 'google') {
    provider = new GoogleAuthProvider()
  }
  try {
    const result = await signInWithPopup(auth, provider)
    // const additionalUserInfo = getAdditionalUserInfo(result)
    // console.log(additionalUserInfo.isNewUser, isNewUser(result.user))
    if (isNewUser(result.user)) {
      // isNew = true
      await setUserProfileData(result.user) // set user doc in cloud function
      dispatch(updateLastActiveDate())
      return true
    }
    return false
  } catch (error) {
    // console.log('social login', error)
    // console.log('social login', error.message)
    throw error
  }
}

export const resetPassword = async email => {
  try {
    const methods = await fetchSignInMethodsForEmail(auth, email)
    if (methods.length === 0) throw { code: 'auth/user-not-found' }
    if (methods[0] === 'google.com') {
      throw { code: 'auth/different-provider-google' }
    } else if (methods[0] === 'facebook.com') {
      throw { code: 'auth/different-provider-facebook' }
    }
    return await withTimeout(TIMEOUT_AUTH, sendPasswordResetEmail(auth, email))
    // return result
    // if (result.additionalUserInfo.isNewUser) {
    //   await setUserProfileData(result.user);
    // }
  } catch (error) {
    // console.log('resetpassword', error.message)
    throw error
  }
}

export const deleteAccount = async (
  deleteStorageImage,
  stripeCustomerId = null,
) => {
  try {
    const user = auth.currentUser
    if (deleteStorageImage) deleteUserImageFirebase()
    const batch = writeBatch(firestore)
    const accountDoc = doc(firestore, 'users', user.uid)
    const saveListsNamesRef = collection(accountDoc, 'save-lists-names')
    const docs = await getDocs(saveListsNamesRef)
    let pageArray = []
    let shareLinkObjArray = {}
    if (docs.size > 0) {
      docs.forEach(doc => {
        const docData = doc.data()
        const arrayPath = []
        for (const key in docData) {
          if (docData[key].sharePath || docData[key].shareId) {
            arrayPath.push(docData[key].sharePath || docData[key].shareId)
          }
        }
        shareLinkObjArray[doc.id] = [...arrayPath]
        pageArray.push(doc.id)
        batch.delete(doc.ref)
      })
    }
    const pagePromises = []
    for (const page of pageArray) {
      const saveListsPageRef = collection(accountDoc, `save-lists-${page}`)
      const promise = getDocs(saveListsPageRef).then(lists => {
        if (lists.size > 0) {
          lists.forEach(list => {
            batch.delete(list.ref)
          })
        }
      })
      pagePromises.push(promise)
    }
    await Promise.all(pagePromises)

    for (const page in shareLinkObjArray) {
      for (const sharePath of shareLinkObjArray[page]) {
        const linkDocRef = doc(
          firestore,
          'share-wheels',
          page,
          'share-links',
          sharePath,
        )
        batch.delete(linkDocRef)
      }
    }

    batch.delete(accountDoc)
    await batch.commit()

    if (user.uid) {
      const deleteFolder = httpsCallable(functions, 'deleteFolderV2') //delete user storage folder
      //careful use
      await deleteFolder({ folderPath: user.uid, userId: user.uid })
    }
    if (!!stripeCustomerId) {
      console.log('delete stripe customer')
      const deleteStripeCustomer = httpsCallable(
        functions,
        'deleteStripeCustomerV2',
      )
      await deleteStripeCustomer({ stripeCustomerId })
    }
    const deleteUser = httpsCallable(functions, 'deleteUserV2') //use cloudfunction admin to delete user because client side delete might need reauthenticate
    await deleteUser()
    signOutFirebase()
  } catch (error) {
    throw error
  }
}

export const openAuthModal = (show, customAction = null) => {
  return {
    type: OPEN_AUTH_MODAL,
    payload: {
      show,
      customAction,
    },
  }
}

export const updateLastActiveDate = () => {
  return {
    type: UPDATE_LAST_ACTIVE_DATE,
  }
}

export const signInUser = user => {
  return {
    type: SIGN_IN_USER,
    payload: {
      user,
    },
  }
}

const isNewUser = user => {
  return (
    Math.abs(
      Date.parse(user.reloadUserInfo.lastRefreshAt) -
        parseInt(user.metadata.createdAt),
    ) < 1000
  ) //within 1 second
}

export const verifyAuth = () => {
  return (dispatch, getState) => {
    getFirebase()
    return onAuthStateChanged(auth, async user => {
      const { lastActiveDate, premiumHistory } = getState().auth
      if (user) {
        let disableAdsDone = false
        if (premiumHistory) {
          disableAdsDone = true
          disableAdThriveAds()
          removeFooterAdDiv()
          // removeInterstitialDiv()
        }
        dispatch(signInUser(user))
        const timeNow = new Date().getTime()
        if (!isNewUser(user)) {
          // to prevent update during new user registration, it caused problem because user havent created
          if (timeNow - lastActiveDate > 86400000) {
            dispatch(updateLastActiveDate())
            updateLastActive(user)
          }
        }
        const stripeRole = await getCustomClaimRole(user)
        if (stripeRole === undefined || stripeRole === 'subscriber') {
          if (premiumHistory) {
            // only occur once
            dispatch(setPremiumHistory(false))
            dispatch(turnOffPremiumSettings())
          }
          if (!emailIdentity) {
            setEmailIdentity(user.email)
            emailIdentity = true
          }
          return
        } //if not premium member then skip the rest

        // addPremiumClass()
        dispatch(setUserIsPremium(true)) // have to set first because of ads removal
        if (!premiumHistory) dispatch(setPremiumHistory(true))
        if (!disableAdsDone) {
          disableAdThriveAds()
          removeFooterAdDiv()
          // removeInterstitialDiv()
        }
        removeStickyOutstream()
        hideCafemediaText()
        const doc = await getUserDoc()
        if (!doc.exists()) return
        dispatch(updateUserStore(doc.data()))
      } else {
        if (premiumHistory) {
          // only occur once
          dispatch(setPremiumHistory(false))
          dispatch(turnOffPremiumSettings())
        }
        dispatch(signOutUser())
        // removePremiumClass()
      }
    })
  }
}

export const signOutUser = () => {
  return {
    type: SIGN_OUT_USER,
  }
}

export const updateAuthPhotoURL = url => {
  return {
    type: UPDATE_AUTH_PHOTO_URL,
    payload: {
      url,
    },
  }
}

export const updateAuthDisplayName = name => {
  return {
    type: UPDATE_AUTH_DISPLAY_NAME,
    payload: {
      name,
    },
  }
}

const getCustomClaimRole = async user => {
  // await user.getIdToken(true)
  const decodedToken = await getIdTokenResult(user, true)
  const stripeRole = decodedToken.claims.role
  return stripeRole
}

// const addPremiumClass = () => {
//   if (!document.body.classList.contains('PrmuCl')) {
//     document.body.classList.add('PrmuCl')
//   }
// }

// const removePremiumClass = () => {
//   if (document.body.classList.contains('PrmuCl')) {
//     document.body.classList.remove('PrmuCl')
//   }
// }

// const reloadAdThriveScript = () => {
//   var scriptTag = document.getElementById('adthriveAdCode')
//   scriptTag.parentNode.removeChild(scriptTag)

//   var head = document.getElementsByTagName('head')[0]
//   var newScriptTag = document.createElement('script')
//   newScriptTag.id = 'adthriveAdCode'
//   newScriptTag.innerText = scriptTag.innerText
//   head.appendChild(newScriptTag)
// }

// const removeInterstitialDiv = () => {
//   if (window.adthrive === undefined) return
//   // const adInterstitialDesktop = document.getElementById(
//   //   'AdThrive_Interstitial_1_desktop',
//   // )
//   // adInterstitialDesktop &&
//   //   adInterstitialDesktop.parentNode.removeChild(adInterstitialDesktop)
//   // const adInterstitialMobile = document.getElementById(
//   //   'AdThrive_Interstitial_1_phone',
//   // )
//   // adInterstitialMobile &&
//   //   adInterstitialMobile.parentNode.removeChild(adInterstitialMobile)
//   const adInterstitial = document.querySelector('.adthrive-interstitial')
//   if (adInterstitial) adInterstitial.parentNode.removeChild(adInterstitial)
// }

const setEmailIdentity = email => {
  if (process.env.GATSBY_BUILD_CONTEXT !== 'development') {
    if (window.adthrive === undefined) return
    window.adthrive.cmd.push(function () {
      window.adthrive.identityApi({
        source: 'api',
        plainText: email,
      })
    })
  }
}

const removeFooterAdDiv = () => {
  if (window.adthrive === undefined) return
  // const adFooterDesktop = document.getElementById('AdThrive_Footer_1_desktop')
  // adFooterDesktop && adFooterDesktop.parentNode.removeChild(adFooterDesktop)
  // const adFooterMobile = document.getElementById('AdThrive_Footer_1_phone')
  // adFooterMobile && adFooterMobile.parentNode.removeChild(adFooterMobile)
  const adFooter = document.querySelector('.adthrive-footer')
  if (adFooter) adFooter.parentNode.removeChild(adFooter)
}

const removeStickyOutstream = () => {
  if (window.adthrive === undefined) return
  const stickyOutstream = document.querySelector('.adthrive-sticky-outstream')
  if (stickyOutstream) stickyOutstream.parentNode.removeChild(stickyOutstream)
}

const hideCafemediaText = () => {
  if (window.adthrive === undefined) return
  const cafemediaText = document.querySelector(
    '.adthrive-comscore.adthrive-footer-message',
  )
  if (cafemediaText) cafemediaText.style.display = 'none'
}

const disableAdThriveAds = () => {
  if (process.env.GATSBY_BUILD_CONTEXT !== 'development') {
    if (window.adthrive === undefined) return
    window.adthrive.cmd.push(function () {
      window.adthrive.disableAds()
    })
  }
}

export const disableAndRemoveAds = () => {
  disableAdThriveAds()
  removeFooterAdDiv()
  removeStickyOutstream()
  hideCafemediaText()
}

export const updateAuthRole = role => {
  return {
    type: UPDATE_AUTH_ROLE,
    payload: {
      role,
    },
  }
}

export const getUserDoc = async () => {
  const user = auth.currentUser
  const userDocRef = doc(firestore, 'users', user.uid)
  return await getDoc(userDocRef)
}

export const listenUserDoc = () => (dispatch, getState) => {
  const user = auth.currentUser
  return onSnapshot(
    doc(firestore, 'users', user.uid),
    userDoc => {
      if (userDoc.exists()) {
        const newData = {
          doneSync: true,
          country: '', //set a default country value
          ...userDoc.data(),
          providerId: user.providerData[0].providerId,
        } // to get latest providerID, remove providerID from firestore, because not up to date
        dispatch(updateUserStore(newData))
      }
    },
    error => {
      dispatch(updateUserStore({ doneSync: true })) // to make sure edit account can work
    },
  )
}

export const updateUserStore = user => {
  return {
    type: UPDATE_USER_STORE,
    payload: {
      user,
    },
  }
}

export const updateUserDocAndProfile = async (profile, infos = null) => {
  // const firebaseApp = getFirebase()
  const user = auth.currentUser
  const stripeUpdateData = {}
  try {
    // dispatch(asyncActionStart('loadingButton'))
    if (user.displayName !== profile.displayName) {
      await updateProfile(user, {
        displayName: profile.displayName,
      })
      stripeUpdateData['name'] = profile.displayName
    }
    if (user.email !== profile.email) {
      await updateEmail(user, profile.email)
      stripeUpdateData['email'] = profile.email
    }
    if (user.photoURL !== profile.photoURL) {
      await updateProfile(user, {
        photoURL: profile.photoURL,
      })
    }
    // const d = collection(firestore, 'users').doc(user.uid)
    const d = doc(firestore, 'users', user.uid)
    await updateDoc(d, profile)
    if (
      infos &&
      infos.stripeCustomerId &&
      Object.keys(stripeUpdateData).length > 0
    ) {
      const updateStripeCustomer = httpsCallable(
        functions,
        'updateStripeCustomerV2',
      )
      await updateStripeCustomer({
        stripeUpdateData,
        stripeCustomerId: infos.stripeCustomerId,
      })
    }
  } catch (error) {
    // dispatch(asyncActionError('loadingButton'))
    throw error
  }
}

export const setPremiumHistory = status => {
  return {
    type: SET_PREMIUM_HISTORY,
    payload: {
      status,
    },
  }
}

export const setUserIsPremium = isPremium => {
  return {
    type: SET_USER_IS_PREMIUM,
    payload: {
      isPremium,
    },
  }
}

export const updateUserCustomization = customization => {
  return {
    type: UPDATE_USER_CUSTOMIZATION,
    payload: {
      customization,
    },
  }
}

export const setSharePremium = premium => {
  return {
    type: SET_SHARE_PREMIUM,
    payload: {
      premium,
    },
  }
}

export const listenUserPremium = setStatus => {
  const user = auth.currentUser
  const timer = setTimeout(() => {
    setStatus('relogin')
    setTimeout(() => {
      signOutFirebase()
    }, 2000)
    console.log('timeout')
  }, 10000)
  return onSnapshot(doc(firestore, 'users', user.uid), userDoc => {
    if (userDoc.exists()) {
      const userData = userDoc.data()
      if (userData.isPremium) {
        getIdToken(user, true).then(() => {
          setStatus('upgraded')
          clearTimeout(timer)
          console.log('force refresh user done')
        })
        console.log('force refresh user')
      }
    }
  })
}

export const reauthenticateUser = async password => {
  try {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password,
    )
    await reauthenticateWithCredential(auth.currentUser, credential)
    return true
  } catch (error) {
    throw error
  }
}

export const socialLinkWithPopup = async selectedProvider => {
  let provider
  if (selectedProvider === 'facebook') {
    provider = new FacebookAuthProvider()
  }
  if (selectedProvider === 'google') {
    provider = new GoogleAuthProvider()
  }
  try {
    const result = await linkWithPopup(auth.currentUser, provider)
    return true
  } catch (error) {
    // console.log('social login', error)
    // console.log('social login', error.message)
    throw error
  }
}

export const unlinkAccount = async selectedProvider => {
  let providerId
  if (selectedProvider === 'facebook') {
    providerId = 'facebook.com'
  } else if (selectedProvider === 'google') {
    providerId = 'google.com'
  } else if (selectedProvider === 'password') {
    providerId = 'password'
  }
  try {
    await unlink(auth.currentUser, providerId)
    return true
  } catch (error) {
    // console.log('social login', error)
    // console.log('social login', error.message)
    throw error
  }
}

export const migrateToPassword =
  (
    password,
    social,
    unlinkPasswordFirst, // if true means users have 2 password and google, ori is password
  ) =>
  async (dispatch, getState) => {
    try {
      if (unlinkPasswordFirst) await unlinkAccount('password')
      const email = auth.currentUser.email
      const credential = EmailAuthProvider.credential(email, password)
      const googleProvider = new GoogleAuthProvider()
      if (!unlinkPasswordFirst)
        //run second time already
        await reauthenticateWithPopup(auth.currentUser, googleProvider)
      await linkWithCredential(auth.currentUser, credential)
      await unlinkAccount(social)
      await updateUserDocAndProfile({ email, providerId: 'password' })
      if (unlinkPasswordFirst)
        // firebase wont trigger because same firestore providerid password
        dispatch(updateUserStore({ providerId: 'password' }))
      if (!unlinkPasswordFirst) {
        //if run second time, ori is already password, no need change
        const exchangeUserType = httpsCallable(functions, 'exchangeUserTypeV2')
        exchangeUserType()
      }
      return true
    } catch (error) {
      throw error
    }
  }

export const verifyResetCode = async code => {
  try {
    return await verifyPasswordResetCode(auth, code)

    // return result
    // if (result.additionalUserInfo.isNewUser) {
    //   await setUserProfileData(result.user);
    // }
  } catch (error) {
    // console.log('resetpassword', error.message)
    throw error
  }
}

export const resetPasswordFinal = async (code, newPassword) => {
  try {
    await withTimeout(
      TIMEOUT_AUTH,
      confirmPasswordReset(auth, code, newPassword),
    )
    return true
    // return result
    // if (result.additionalUserInfo.isNewUser) {
    //   await setUserProfileData(result.user);
    // }
  } catch (error) {
    // console.log('resetpassword', error.message)
    throw error
  }
}

export const verifyRecoverCode = async code => {
  try {
    const info = await checkActionCode(auth, code)
    return info['data']['email']
  } catch (error) {
    throw error
  }
}

export const applyAuthAction = async (mode, code, email = '') => {
  try {
    await applyActionCode(auth, code)
    if (mode === 'recoverEmail') {
      const recoverFirestoreEmail = httpsCallable(
        functions,
        'recoverFirestoreEmailV2',
      )
      await recoverFirestoreEmail({
        email: email,
      })
    }
  } catch (error) {
    throw error
  }
}

//this is for wrapPageElement

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  /* margin: 1.5rem; */
  /* background-color: #fff; */
  /* max-width: 1000px; */
  /* padding: 1rem; */

  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 3rem);
  @media (max-width: 1023px) {
    min-height: calc(100vh - 1.6rem);
    /* margin: 0.8rem; */
  }
`

export const wrapPageElement = ({ element }) => <Container>{element}</Container>

export const UPDATE_WHEEL_COLORS = 'UPDATE_WHEEL_COLORS'
export const UPDATE_BACKGROUND_COLOR = 'UPDATE_BACKGROUND_COLOR'
export const UPDATE_SPIN_BEHAVIOR = 'UPDATE_SPIN_BEHAVIOR'
export const UPDATE_MUSIC = 'UPDATE_MUSIC'
export const UPDATE_CONFETTI = 'UPDATE_CONFETTI'
export const UPDATE_ALL_SETTINGS = 'UPDATE_ALL_SETTINGS'
export const UPDATE_MUSIC_VOLUME = 'UPDATE_MUSIC_VOLUME'
export const INSTANT_INIT_SETTINGS = 'INSTANT_INIT_SETTINGS'
export const UPDATE_SPIN_BUTTON = 'UPDATE_SPIN_BUTTON'
export const UPDATE_BACKGROUND_IMAGE = 'UPDATE_BACKGROUND_IMAGE'
export const UPDATE_BANNERS = 'UPDATE_BANNERS'
export const RESET_INITIAL_SETTINGS = 'RESET_INITIAL_SETTINGS'
export const TURN_OFF_PREMIUM_SETTINGS = 'TURN_OFF_PREMIUM_SETTINGS'
export const UPDATE_RTG_SPIN_BEHAVIOR = 'UPDATE_RTG_SPIN_BEHAVIOR'

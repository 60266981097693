export const SHOW_TURNTABLE = 'SHOW_TURNTABLE_YNW'
export const SET_TURNTABLE_SIZE = 'SET_TURNTABLE_SIZE_YNW'
export const SET_RESULT_SELECTED = 'SET_RESULT_SELECTED_YNW'
export const SET_INPUT_MODE = 'SET_INPUT_MODE_YNW'
export const SET_NUM_INPUT_SET = 'SET_NUM_INPUT_SET_YNW'
// export const TOGGLE_MUTE = "TOGGLE_MUTE_YNW"
export const SET_PAST_IP = 'SET_PAST_IP_YNW'
export const SET_SPIN_COUNT = 'SET_SPIN_COUNT_YNW'
export const CLEAR_RESULT_SELECTED = 'CLEAR_RESULT_SELECTED'
export const CLEAR_RESULTS = 'CLEAR_RESULTS'
export const SET_HISTORY = 'SET_HISTORY_YNW'
// export const SET_TITLE_ACTIVE = 'SET_TITLE_ACTIVE_YNW'
// export const SET_TITLE_TEXT = 'SET_TITLE_TEXT_YNW'
export const SET_TITLE_OBJ = 'SET_TITLE_OBJ_YNW'
export const INSTANT_INIT = 'INSTANT_INIT_YNW'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE_YNW'
export const REPLACE_STATE = 'REPLACE_STATE_YNW'

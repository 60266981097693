import {
  SHOW_TURNTABLE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  SET_MODE,
  // SET_FILTERED_CHOICES,
  CHANGE_KEY_TEXT,
  SET_TURNTABLE_SIZE,
  // TOGGLE_MUTE,
  // SET_PAST_IP,
  SET_SPIN_COUNT,
  // SYNC_FIRST_CHOICES_LIST,
  // SAVE_CHOICES_LIST_NAMES,
  // SET_LIST_NAME,
  SAVE_LOCAL_CHOICES_LIST,
  SET_HISTORY,
  // SET_TITLE_ACTIVE,
  // SET_TITLE_TEXT,
  SET_TITLE_OBJ,
  SET_WEIGHT_ON,
  SET_SAVED_LISTS,
  RESET_INITIAL_STATE,
  REPLACE_STATE,
  // SET_CHOICES_COUNT,
} from './constants'

import { setStartRotate } from '../turntable/actions'
import { setCurrentFileKey, setDataStatus } from '../common/actions'

export const showTurntable = turntable => ({
  type: SHOW_TURNTABLE,
  payload: {
    turntable,
  },
})

export const setTurntableSize = size => ({
  type: SET_TURNTABLE_SIZE,
  payload: {
    size,
  },
})

export const setResultSelected = (result, resultIndex = -1) => ({
  type: SET_RESULT_SELECTED,
  payload: {
    result,
    resultIndex,
  },
})

export const setChoices =
  (choices, resetAngle = true) =>
  (dispatch, getState) => {
    if (resetAngle) {
      const state = getState()
      const startAngle = state.settings.spinBehavior.randomStart
        ? 2 * Math.PI * Math.random()
        : 0
      dispatch(setStartRotate(startAngle))
    }
    dispatch({
      type: SET_CHOICES,
      payload: {
        choices,
      },
    })
    dispatch(setDataStatus('Modified'))
  }

// export const setChoicesAction = choices => ({
//   type: SET_CHOICES,
//   payload: {
//     choices,
//   },
// })

export const setMode = mode => ({
  type: SET_MODE,
  payload: {
    mode,
  },
})

// export const setFilteredChoices = filteredChoices => ({
//   type: SET_FILTERED_CHOICES,
//   payload: {
//     filteredChoices,
//   },
// })

// export const setChoicesCount = count => ({
//   type: SET_CHOICES_COUNT,
//   payload: {
//     count,
//   },
// })

// export const toggleMute = () => ({
//   type: TOGGLE_MUTE,
// })

export const changeKeyText = () => ({
  type: CHANGE_KEY_TEXT,
})

// export const setPastIP = data => {
//   return {
//     type: SET_PAST_IP,
//     payload: {
//       data,
//     },
//   }
// }

export const setSpinCount = data => {
  return {
    type: SET_SPIN_COUNT,
    payload: {
      data,
    },
  }
}

// export const setListName = name => {
//   return {
//     type: SET_LIST_NAME,
//     payload: {
//       name,
//     },
//   }
// }

// export const syncFirstChoicesList = () => {
//   return {
//     type: SYNC_FIRST_CHOICES_LIST,
//   }
// }

// export const saveChoicesListNames = (
//   aName,
//   bName,
//   cName,
//   dName,
//   eName,
//   fName,
//   gName,
// ) => {
//   return {
//     type: SAVE_CHOICES_LIST_NAMES,
//     payload: {
//       aName,
//       bName,
//       cName,
//       dName,
//       eName,
//       fName,
//       gName,
//     },
//   }
// }

export const saveLocalChoicesList = data => {
  return {
    type: SAVE_LOCAL_CHOICES_LIST,
    payload: {
      data,
    },
  }
}

export const setLoadSaveData =
  (id, choices = null, title = null, keyText = false) =>
  (dispatch, getState) => {
    dispatch(setCurrentFileKey(id))
    // dispatch(setListName(name))
    if (choices) dispatch(setChoices(choices))
    if (title) dispatch(setTitleObj(title))
    dispatch(setDataStatus('Unchanged'))
    if (keyText) dispatch(changeKeyText())
  }

export const setHistory = history => ({
  type: SET_HISTORY,
  payload: {
    history,
  },
})

//input item actions callback
export const itemWheelChanged =
  (newValue, index) => async (dispatch, getState) => {
    const choices = getState().main.choices
    let newChoices = choices.slice(0)
    newChoices[index].name = newValue
    dispatch(setChoices(newChoices, false))
  }

export const itemWeightChanged =
  (weight, index) => async (dispatch, getState) => {
    const choices = getState().main.choices
    let newChoices = choices.slice(0)
    if (weight === 1) {
      delete newChoices[index]['weight']
    } else {
      newChoices[index]['weight'] = weight
    }
    dispatch(setChoices(newChoices, false))
  }

export const itemToggleChanged =
  (status, index) => async (dispatch, getState) => {
    const choices = getState().main.choices
    let newChoices = choices.slice(0)
    newChoices[index].filtered = !status //inverse the value
    dispatch(setChoices(newChoices))
  }

export const itemCloseClicked = id => async (dispatch, getState) => {
  const choices = getState().main.choices
  let array = choices.slice(0)
  array.splice(id, 1)
  if (array.length === 0) {
    dispatch(setMode(0))
  }
  dispatch(setChoices(array))
  dispatch(changeKeyText())
}

export const itemDuplicateClicked = index => async (dispatch, getState) => {
  const choices = getState().main.choices
  let array = choices.slice(0)
  array.splice(index + 1, 0, { ...choices[index], count: 0, filtered: false })
  dispatch(setChoices(array))
  dispatch(changeKeyText())
}

export const itemImageChanged = (data, index) => (dispatch, getState) => {
  const choices = getState().main.choices
  let newChoices = choices.slice(0)
  if (data === null) {
    delete newChoices[index]['data']
  } else {
    newChoices[index]['data'] = data
  }
  dispatch(setChoices(newChoices, false))
}

//03-07-2021 add title
export const setTitleActive = active => dispatch => {
  dispatch(setTitleObj({ active }))
}

export const setTitleText = text => dispatch => {
  dispatch(setTitleObj({ text }))
}

export const setTitleObj = obj => dispatch => {
  dispatch({
    type: SET_TITLE_OBJ,
    payload: {
      obj,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setWeightOn = status => dispatch => {
  dispatch({
    type: SET_WEIGHT_ON,
    payload: {
      status,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setSavedLists = lists => {
  return {
    type: SET_SAVED_LISTS,
    payload: {
      lists,
    },
  }
}

export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  }
}

export const replaceState = data => {
  return {
    type: REPLACE_STATE,
    payload: {
      data,
    },
  }
}

import AllCountries from '../../images/rcg/All-Countries.png'
import Africa from '../../images/rcg/Africa.png'
import Asia from '../../images/rcg/Asia.png'
import Europe from '../../images/rcg/Europe.png'
import NorthAmerica from '../../images/rcg/North-America.png'
import Oceania from '../../images/rcg/Oceania.png'
import SouthAmerica from '../../images/rcg/South-America.png'
// import Antarctica from '../../images/rcg/Antarctica.png'

const asiaCountries = [
  //50
  //   { name: 'Kyrgyzstan', flag: '🇰🇬' },
  //   { name: 'Nepal', flag: '🇳🇵' },
  //   { name: 'Palestine', flag: '🇵🇸' },
  //   { name: 'Myanmar', flag: '🇲🇲' },
  //   { name: 'United Arab Emirates', flag: '🇦🇪' },
  //   { name: 'Maldives', flag: '🇲🇻' },
  //   { name: 'Azerbaijan', flag: '🇦🇿' },
  //   { name: 'Timor-Leste', flag: '🇹🇱' },
  //   { name: 'Bhutan', flag: '🇧🇹' },
  //   { name: 'Taiwan', flag: '🇹🇼' },
  //   { name: 'Pakistan', flag: '🇵🇰' },
  //   { name: 'Georgia', flag: '🇬🇪' },
  //   { name: 'Jordan', flag: '🇯🇴' },
  //   { name: 'South Korea', flag: '🇰🇷' },
  //   { name: 'China', flag: '🇨🇳' },
  //   { name: 'Vietnam', flag: '🇻🇳' },
  //   { name: 'Iraq', flag: '🇮🇶' },
  //   { name: 'Malaysia', flag: '🇲🇾' },
  //   { name: 'Uzbekistan', flag: '🇺🇿' },
  //   { name: 'Singapore', flag: '🇸🇬' },
  //   { name: 'Thailand', flag: '🇹🇭' },
  //   { name: 'Indonesia', flag: '🇮🇩' },
  //   { name: 'Cambodia', flag: '🇰🇭' },
  //   { name: 'Brunei', flag: '🇧🇳' },
  //   { name: 'Turkmenistan', flag: '🇹🇲' },
  //   { name: 'Sri Lanka', flag: '🇱🇰' },
  //   { name: 'Israel', flag: '🇮🇱' },
  //   { name: 'Qatar', flag: '🇶🇦' },
  //   { name: 'Turkey', flag: '🇹🇷' },
  //   { name: 'Iran', flag: '🇮🇷' },
  //   { name: 'Kazakhstan', flag: '🇰🇿' },
  //   { name: 'Bahrain', flag: '🇧🇭' },
  //   { name: 'Japan', flag: '🇯🇵' },
  //   { name: 'India', flag: '🇮🇳' },
  //   { name: 'Lebanon', flag: '🇱🇧' },
  //   { name: 'Hong Kong', flag: '🇭🇰' },
  //   { name: 'Philippines', flag: '🇵🇭' },
  //   { name: 'Saudi Arabia', flag: '🇸🇦' },
  //   { name: 'Tajikistan', flag: '🇹🇯' },
  //   { name: 'Kuwait', flag: '🇰🇼' },
  //   { name: 'Oman', flag: '🇴🇲' },
  //   { name: 'Macau', flag: '🇲🇴' },
  //   { name: 'North Korea', flag: '🇰🇵' },
  //   { name: 'Syria', flag: '🇸🇾' },
  //   { name: 'Mongolia', flag: '🇲🇳' },
  //   { name: 'Afghanistan', flag: '🇦🇫' },
  //   { name: 'Yemen', flag: '🇾🇪' },
  //   { name: 'Armenia', flag: '🇦🇲' },
  //   { name: 'Bangladesh', flag: '🇧🇩' },
  //   { name: 'Laos', flag: '🇱🇦' },
  //50
  { name: 'Kyrgyzstan', flag: '🇰🇬' },
  { name: 'Nepal', flag: '🇳🇵' },
  { name: 'Myanmar', flag: '🇲🇲' },
  { name: 'United Arab Emirates', flag: '🇦🇪' },
  { name: 'Maldives', flag: '🇲🇻' },
  { name: 'Azerbaijan', flag: '🇦🇿' },
  { name: 'Timor-Leste', flag: '🇹🇱' },
  { name: 'Bhutan', flag: '🇧🇹' },
  { name: 'Taiwan', flag: '🇹🇼' },
  { name: 'Pakistan', flag: '🇵🇰' },
  { name: 'Georgia', flag: '🇬🇪' },
  { name: 'Jordan', flag: '🇯🇴' },
  { name: 'South Korea', flag: '🇰🇷' },
  { name: 'China', flag: '🇨🇳' },
  { name: 'Vietnam', flag: '🇻🇳' },
  { name: 'Iraq', flag: '🇮🇶' },
  { name: 'Malaysia', flag: '🇲🇾' },
  { name: 'Uzbekistan', flag: '🇺🇿' },
  { name: 'Singapore', flag: '🇸🇬' },
  { name: 'Thailand', flag: '🇹🇭' },
  { name: 'Indonesia', flag: '🇮🇩' },
  { name: 'Cambodia', flag: '🇰🇭' },
  { name: 'Brunei', flag: '🇧🇳' },
  { name: 'Turkmenistan', flag: '🇹🇲' },
  { name: 'Sri Lanka', flag: '🇱🇰' },
  { name: 'Israel', flag: '🇮🇱' },
  { name: 'Qatar', flag: '🇶🇦' },
  { name: 'Turkey', flag: '🇹🇷' },
  { name: 'Iran', flag: '🇮🇷' },
  { name: 'Kazakhstan', flag: '🇰🇿' },
  { name: 'Bahrain', flag: '🇧🇭' },
  { name: 'Japan', flag: '🇯🇵' },
  { name: 'India', flag: '🇮🇳' },
  { name: 'Lebanon', flag: '🇱🇧' },
  { name: 'Hong Kong China', flag: '🇭🇰' },
  { name: 'Philippines', flag: '🇵🇭' },
  { name: 'Saudi Arabia', flag: '🇸🇦' },
  { name: 'Tajikistan', flag: '🇹🇯' },
  { name: 'Kuwait', flag: '🇰🇼' },
  { name: 'Oman', flag: '🇴🇲' },
  { name: 'Macau China', flag: '🇲🇴' },
  { name: 'North Korea', flag: '🇰🇵' },
  { name: 'Syria', flag: '🇸🇾' },
  { name: 'Mongolia', flag: '🇲🇳' },
  { name: 'Afghanistan', flag: '🇦🇫' },
  { name: 'Yemen', flag: '🇾🇪' },
  { name: 'Armenia', flag: '🇦🇲' },
  { name: 'Bangladesh', flag: '🇧🇩' },
  { name: 'Laos', flag: '🇱🇦' },
  { name: 'Palestine', flag: '🇵🇸' },
]

// https://restcountries.com/v3.1/region/europe?fields=name,flag,independent

const europeCountries = [
  //53
  //   { name: 'Hungary', flag: '🇭🇺' },
  //   { name: 'Croatia', flag: '🇭🇷' },
  //   { name: 'Faroe Islands', flag: '🇫🇴' },
  //   { name: 'Sweden', flag: '🇸🇪' },
  //   { name: 'Spain', flag: '🇪🇸' },
  //   { name: 'Netherlands', flag: '🇳🇱' },
  //   { name: 'Liechtenstein', flag: '🇱🇮' },
  //   { name: 'Åland Islands', flag: '🇦🇽' },
  //   { name: 'Bosnia and Herzegovina', flag: '🇧🇦' },
  //   { name: 'North Macedonia', flag: '🇲🇰' },
  //   { name: 'Montenegro', flag: '🇲🇪' },
  //   { name: 'Andorra', flag: '🇦🇩' },
  //   { name: 'Ukraine', flag: '🇺🇦' },
  //   { name: 'Luxembourg', flag: '🇱🇺' },
  //   { name: 'Belgium', flag: '🇧🇪' },
  //   { name: 'Norway', flag: '🇳🇴' },
  //   { name: 'Moldova', flag: '🇲🇩' },
  //   { name: 'San Marino', flag: '🇸🇲' },
  //   { name: 'Germany', flag: '🇩🇪' },
  //   { name: 'United Kingdom', flag: '🇬🇧' },
  //   { name: 'Slovenia', flag: '🇸🇮' },
  //   { name: 'Malta', flag: '🇲🇹' },
  //   { name: 'Finland', flag: '🇫🇮' },
  //   { name: 'Gibraltar', flag: '🇬🇮' },
  //   { name: 'Kosovo', flag: '🇽🇰' },
  //   { name: 'Ireland', flag: '🇮🇪' },
  //   { name: 'Vatican City', flag: '🇻🇦' },
  //   { name: 'Jersey', flag: '🇯🇪' },
  //   { name: 'Albania', flag: '🇦🇱' },
  //   { name: 'Denmark', flag: '🇩🇰' },
  //   { name: 'Bulgaria', flag: '🇧🇬' },
  //   { name: 'Poland', flag: '🇵🇱' },
  //   { name: 'Iceland', flag: '🇮🇸' },
  //   { name: 'Belarus', flag: '🇧🇾' },
  //   { name: 'Latvia', flag: '🇱🇻' },
  //   { name: 'Russia', flag: '🇷🇺' },
  //   { name: 'Austria', flag: '🇦🇹' },
  //   { name: 'Estonia', flag: '🇪🇪' },
  //   { name: 'Guernsey', flag: '🇬🇬' },
  //   { name: 'Switzerland', flag: '🇨🇭' },
  //   { name: 'Portugal', flag: '🇵🇹' },
  //   { name: 'Monaco', flag: '🇲🇨' },
  //   { name: 'Cyprus', flag: '🇨🇾' },
  //   { name: 'Czechia', flag: '🇨🇿' },
  //   { name: 'Italy', flag: '🇮🇹' },
  //   { name: 'Isle of Man', flag: '🇮🇲' },
  //   { name: 'Greece', flag: '🇬🇷' },
  //   { name: 'Lithuania', flag: '🇱🇹' },
  //   { name: 'Slovakia', flag: '🇸🇰' },
  //   { name: 'Romania', flag: '🇷🇴' },
  //   { name: 'Serbia', flag: '🇷🇸' },
  //   { name: 'Svalbard and Jan Mayen', flag: '🇸🇯' },
  //   { name: 'France', flag: '🇫🇷' },
  //46
  { name: 'Hungary', flag: '🇭🇺' },
  { name: 'Croatia', flag: '🇭🇷' },
  { name: 'Sweden', flag: '🇸🇪' },
  { name: 'Spain', flag: '🇪🇸' },
  { name: 'Netherlands', flag: '🇳🇱' },
  { name: 'Liechtenstein', flag: '🇱🇮' },
  { name: 'Bosnia and Herzegovina', flag: '🇧🇦' },
  { name: 'North Macedonia', flag: '🇲🇰' },
  { name: 'Montenegro', flag: '🇲🇪' },
  { name: 'Andorra', flag: '🇦🇩' },
  { name: 'Ukraine', flag: '🇺🇦' },
  { name: 'Luxembourg', flag: '🇱🇺' },
  { name: 'Belgium', flag: '🇧🇪' },
  { name: 'Norway', flag: '🇳🇴' },
  { name: 'Moldova', flag: '🇲🇩' },
  { name: 'San Marino', flag: '🇸🇲' },
  { name: 'Germany', flag: '🇩🇪' },
  { name: 'United Kingdom', flag: '🇬🇧' },
  { name: 'Slovenia', flag: '🇸🇮' },
  { name: 'Malta', flag: '🇲🇹' },
  { name: 'Finland', flag: '🇫🇮' },
  { name: 'Kosovo', flag: '🇽🇰' },
  { name: 'Ireland', flag: '🇮🇪' },
  { name: 'Vatican City', flag: '🇻🇦' },
  { name: 'Albania', flag: '🇦🇱' },
  { name: 'Denmark', flag: '🇩🇰' },
  { name: 'Bulgaria', flag: '🇧🇬' },
  { name: 'Poland', flag: '🇵🇱' },
  { name: 'Iceland', flag: '🇮🇸' },
  { name: 'Belarus', flag: '🇧🇾' },
  { name: 'Latvia', flag: '🇱🇻' },
  { name: 'Russia', flag: '🇷🇺' },
  { name: 'Austria', flag: '🇦🇹' },
  { name: 'Estonia', flag: '🇪🇪' },
  { name: 'Switzerland', flag: '🇨🇭' },
  { name: 'Portugal', flag: '🇵🇹' },
  { name: 'Monaco', flag: '🇲🇨' },
  { name: 'Cyprus', flag: '🇨🇾' },
  { name: 'Czechia', flag: '🇨🇿' },
  { name: 'Italy', flag: '🇮🇹' },
  { name: 'Greece', flag: '🇬🇷' },
  { name: 'Lithuania', flag: '🇱🇹' },
  { name: 'Slovakia', flag: '🇸🇰' },
  { name: 'Romania', flag: '🇷🇴' },
  { name: 'Serbia', flag: '🇷🇸' },
  { name: 'France', flag: '🇫🇷' },
]

const africaCountries = [
  //59
  //   { name: 'Mauritania', flag: '🇲🇷' },
  //   { name: 'Niger', flag: '🇳🇪' },
  //   { name: 'Togo', flag: '🇹🇬' },
  //   { name: 'Madagascar', flag: '🇲🇬' },
  //   { name: 'Algeria', flag: '🇩🇿' },
  //   { name: 'Equatorial Guinea', flag: '🇬🇶' },
  //   { name: 'Guinea', flag: '🇬🇳' },
  //   { name: 'Mozambique', flag: '🇲🇿' },
  //   { name: 'South Africa', flag: '🇿🇦' },
  //   { name: 'Malawi', flag: '🇲🇼' },
  //   { name: 'Cameroon', flag: '🇨🇲' },
  //   { name: 'Republic of the Congo', flag: '🇨🇬' },
  //   { name: 'Réunion', flag: '🇷🇪' },
  //   { name: 'Eswatini', flag: '🇸🇿' },
  //   { name: 'Gambia', flag: '🇬🇲' },
  //   { name: 'Liberia', flag: '🇱🇷' },
  //   { name: 'Zambia', flag: '🇿🇲' },
  //   { name: 'Burkina Faso', flag: '🇧🇫' },
  //   { name: 'Cape Verde', flag: '🇨🇻' },
  //   { name: 'Tanzania', flag: '🇹🇿' },
  //   { name: 'Seychelles', flag: '🇸🇨' },
  //   { name: 'DR Congo', flag: '🇨🇩' },
  //   { name: 'São Tomé and Príncipe', flag: '🇸🇹' },
  //   { name: 'Sierra Leone', flag: '🇸🇱' },
  //   { name: 'Chad', flag: '🇹🇩' },
  //   { name: 'Western Sahara', flag: '🇪🇭' },
  //   { name: 'Mayotte', flag: '🇾🇹' },
  //   { name: 'Benin', flag: '🇧🇯' },
  //   { name: 'Ivory Coast', flag: '🇨🇮' },
  //   { name: 'Morocco', flag: '🇲🇦' },
  //   { name: 'Ghana', flag: '🇬🇭' },
  //   { name: 'Djibouti', flag: '🇩🇯' },
  //   { name: 'Libya', flag: '🇱🇾' },
  //   { name: 'Sudan', flag: '🇸🇩' },
  //   { name: 'Mauritius', flag: '🇲🇺' },
  //   { name: 'Egypt', flag: '🇪🇬' },
  //   { name: 'British Indian Ocean Territory', flag: '🇮🇴' },
  //   { name: 'Kenya', flag: '🇰🇪' },
  //   { name: 'Burundi', flag: '🇧🇮' },
  //   { name: 'Zimbabwe', flag: '🇿🇼' },
  //   { name: 'Eritrea', flag: '🇪🇷' },
  //   { name: 'Mali', flag: '🇲🇱' },
  //   { name: 'Somalia', flag: '🇸🇴' },
  //   { name: 'Rwanda', flag: '🇷🇼' },
  //   { name: 'Angola', flag: '🇦🇴' },
  //   { name: 'Nigeria', flag: '🇳🇬' },
  //   { name: 'Comoros', flag: '🇰🇲' },
  //   { name: 'Senegal', flag: '🇸🇳' },
  //   {
  //     name: 'Saint Helena, Ascension and Tristan da Cunha',
  //     flag: '🇸🇭',
  //   },
  //   { name: 'Lesotho', flag: '🇱🇸' },
  //   { name: 'Uganda', flag: '🇺🇬' },
  //   { name: 'Gabon', flag: '🇬🇦' },
  //   { name: 'Ethiopia', flag: '🇪🇹' },
  //   { name: 'South Sudan', flag: '🇸🇸' },
  //   { name: 'Namibia', flag: '🇳🇦' },
  //   { name: 'Botswana', flag: '🇧🇼' },
  //   { name: 'Tunisia', flag: '🇹🇳' },
  //   { name: 'Guinea-Bissau', flag: '🇬🇼' },
  //   { name: 'Central African Republic', flag: '🇨🇫' },
  //54
  { name: 'Mauritania', flag: '🇲🇷' },
  { name: 'Niger', flag: '🇳🇪' },
  { name: 'Togo', flag: '🇹🇬' },
  { name: 'Madagascar', flag: '🇲🇬' },
  { name: 'Algeria', flag: '🇩🇿' },
  { name: 'Equatorial Guinea', flag: '🇬🇶' },
  { name: 'Guinea', flag: '🇬🇳' },
  { name: 'Mozambique', flag: '🇲🇿' },
  { name: 'South Africa', flag: '🇿🇦' },
  { name: 'Malawi', flag: '🇲🇼' },
  { name: 'Cameroon', flag: '🇨🇲' },
  { name: 'Republic of the Congo', flag: '🇨🇬' },
  { name: 'Eswatini', flag: '🇸🇿' },
  { name: 'Gambia', flag: '🇬🇲' },
  { name: 'Liberia', flag: '🇱🇷' },
  { name: 'Zambia', flag: '🇿🇲' },
  { name: 'Burkina Faso', flag: '🇧🇫' },
  { name: 'Cape Verde', flag: '🇨🇻' },
  { name: 'Tanzania', flag: '🇹🇿' },
  { name: 'Seychelles', flag: '🇸🇨' },
  { name: 'DR Congo', flag: '🇨🇩' },
  { name: 'São Tomé and Príncipe', flag: '🇸🇹' },
  { name: 'Sierra Leone', flag: '🇸🇱' },
  { name: 'Chad', flag: '🇹🇩' },
  { name: 'Benin', flag: '🇧🇯' },
  { name: 'Ivory Coast', flag: '🇨🇮' },
  { name: 'Morocco', flag: '🇲🇦' },
  { name: 'Ghana', flag: '🇬🇭' },
  { name: 'Djibouti', flag: '🇩🇯' },
  { name: 'Libya', flag: '🇱🇾' },
  { name: 'Sudan', flag: '🇸🇩' },
  { name: 'Mauritius', flag: '🇲🇺' },
  { name: 'Egypt', flag: '🇪🇬' },
  { name: 'Kenya', flag: '🇰🇪' },
  { name: 'Burundi', flag: '🇧🇮' },
  { name: 'Zimbabwe', flag: '🇿🇼' },
  { name: 'Eritrea', flag: '🇪🇷' },
  { name: 'Mali', flag: '🇲🇱' },
  { name: 'Somalia', flag: '🇸🇴' },
  { name: 'Rwanda', flag: '🇷🇼' },
  { name: 'Angola', flag: '🇦🇴' },
  { name: 'Nigeria', flag: '🇳🇬' },
  { name: 'Comoros', flag: '🇰🇲' },
  { name: 'Senegal', flag: '🇸🇳' },
  { name: 'Lesotho', flag: '🇱🇸' },
  { name: 'Uganda', flag: '🇺🇬' },
  { name: 'Gabon', flag: '🇬🇦' },
  { name: 'Ethiopia', flag: '🇪🇹' },
  { name: 'South Sudan', flag: '🇸🇸' },
  { name: 'Namibia', flag: '🇳🇦' },
  { name: 'Botswana', flag: '🇧🇼' },
  { name: 'Tunisia', flag: '🇹🇳' },
  { name: 'Guinea-Bissau', flag: '🇬🇼' },
  { name: 'Central African Republic', flag: '🇨🇫' },
]

//  API:  https://restcountries.com/v3.1/subregion/south%20America?fields=name,flag,independent

const southAmericaCountries = [
  //14
  //   { name: 'Colombia', flag: '🇨🇴' },
  //   { name: 'Peru', flag: '🇵🇪' },
  //   { name: 'Falkland Islands', flag: '🇫🇰' },
  //   { name: 'Argentina', flag: '🇦🇷' },
  //   { name: 'Venezuela', flag: '🇻🇪' },
  //   { name: 'Guyana', flag: '🇬🇾' },
  //   { name: 'Brazil', flag: '🇧🇷' },
  //   { name: 'Ecuador', flag: '🇪🇨' },
  //   { name: 'Uruguay', flag: '🇺🇾' },
  //   { name: 'Suriname', flag: '🇸🇷' },
  //   { name: 'Paraguay', flag: '🇵🇾' },
  //   { name: 'Bolivia', flag: '🇧🇴' },
  //   { name: 'French Guiana', flag: '🇬🇫' },
  //   { name: 'Chile', flag: '🇨🇱' },
  //12
  { name: 'Colombia', flag: '🇨🇴' },
  { name: 'Peru', flag: '🇵🇪' },
  { name: 'Argentina', flag: '🇦🇷' },
  { name: 'Venezuela', flag: '🇻🇪' },
  { name: 'Guyana', flag: '🇬🇾' },
  { name: 'Brazil', flag: '🇧🇷' },
  { name: 'Ecuador', flag: '🇪🇨' },
  { name: 'Uruguay', flag: '🇺🇾' },
  { name: 'Suriname', flag: '🇸🇷' },
  { name: 'Paraguay', flag: '🇵🇾' },
  { name: 'Bolivia', flag: '🇧🇴' },
  { name: 'Chile', flag: '🇨🇱' },
]

const northAmericaCountries = [
  //42
  //   { name: 'Mexico', flag: '🇲🇽' },
  //   { name: 'Greenland', flag: '🇬🇱' },
  //   { name: 'Bermuda', flag: '🇧🇲' },
  //   { name: 'United States', flag: '🇺🇸' },
  //   { name: 'Canada', flag: '🇨🇦' },
  //   { name: 'Saint Pierre and Miquelon', flag: '🇵🇲' },
  //   { name: 'United States Minor Outlying Islands', flag: '🇺🇲' },
  //   { name: 'Dominica', flag: '🇩🇲' },
  //   { name: 'Trinidad and Tobago', flag: '🇹🇹' },
  //   { name: 'Aruba', flag: '🇦🇼' },
  //   { name: 'Jamaica', flag: '🇯🇲' },
  //   { name: 'Haiti', flag: '🇭🇹' },
  //   { name: 'Montserrat', flag: '🇲🇸' },
  //   { name: 'United States Virgin Islands', flag: '🇻🇮' },
  //   { name: 'Turks and Caicos Islands', flag: '🇹🇨' },
  //   { name: 'British Virgin Islands', flag: '🇻🇬' },
  //   { name: 'Saint Lucia', flag: '🇱🇨' },
  //   { name: 'Saint Vincent and the Grenadines', flag: '🇻🇨' },
  //   { name: 'Barbados', flag: '🇧🇧' },
  //   { name: 'Martinique', flag: '🇲🇶' },
  //   { name: 'Cuba', flag: '🇨🇺' },
  //   { name: 'Guadeloupe', flag: '🇬🇵' },
  //   { name: 'Saint Barthélemy', flag: '🇧🇱' },
  //   { name: 'Bahamas', flag: '🇧🇸' },
  //   { name: 'Sint Maarten', flag: '🇸🇽' },
  //   { name: 'Puerto Rico', flag: '🇵🇷' },
  //   { name: 'Caribbean Netherlands', flag: '🇧🇶' },
  //   { name: 'Grenada', flag: '🇬🇩' },
  //   { name: 'Cayman Islands', flag: '🇰🇾' },
  //   { name: 'Anguilla', flag: '🇦🇮' },
  //   { name: 'Saint Kitts and Nevis', flag: '🇰🇳' },
  //   { name: 'Antigua and Barbuda', flag: '🇦🇬' },
  //   { name: 'Saint Martin', flag: '🇲🇫' },
  //   { name: 'Curaçao', flag: '🇨🇼' },
  //   { name: 'Dominican Republic', flag: '🇩🇴' },
  //   { name: 'Honduras', flag: '🇭🇳' },
  //   { name: 'Panama', flag: '🇵🇦' },
  //   { name: 'Belize', flag: '🇧🇿' },
  //   { name: 'El Salvador', flag: '🇸🇻' },
  //   { name: 'Guatemala', flag: '🇬🇹' },
  //   { name: 'Costa Rica', flag: '🇨🇷' },
  //   { name: 'Nicaragua', flag: '🇳🇮' },
  //3 north america
  { name: 'Mexico', flag: '🇲🇽' },
  { name: 'United States', flag: '🇺🇸' },
  { name: 'Canada', flag: '🇨🇦' },
  //7 central america
  { name: 'Honduras', flag: '🇭🇳' },
  { name: 'Panama', flag: '🇵🇦' },
  { name: 'Belize', flag: '🇧🇿' },
  { name: 'El Salvador', flag: '🇸🇻' },
  { name: 'Guatemala', flag: '🇬🇹' },
  { name: 'Costa Rica', flag: '🇨🇷' },
  { name: 'Nicaragua', flag: '🇳🇮' },
  //13 caribbean
  { name: 'Dominica', flag: '🇩🇲' },
  { name: 'Trinidad and Tobago', flag: '🇹🇹' },
  { name: 'Jamaica', flag: '🇯🇲' },
  { name: 'Haiti', flag: '🇭🇹' },
  { name: 'Saint Lucia', flag: '🇱🇨' },
  { name: 'Saint Vincent and the Grenadines', flag: '🇻🇨' },
  { name: 'Barbados', flag: '🇧🇧' },
  { name: 'Cuba', flag: '🇨🇺' },
  { name: 'Bahamas', flag: '🇧🇸' },
  { name: 'Grenada', flag: '🇬🇩' },
  { name: 'Saint Kitts and Nevis', flag: '🇰🇳' },
  { name: 'Antigua and Barbuda', flag: '🇦🇬' },
  { name: 'Dominican Republic', flag: '🇩🇴' },
]

const oceaniaCountries = [
  //27
  //   { name: 'Tuvalu', flag: '🇹🇻' },
  //   { name: 'Palau', flag: '🇵🇼' },
  //   { name: 'Papua New Guinea', flag: '🇵🇬' },
  //   { name: 'Cook Islands', flag: '🇨🇰' },
  //   { name: 'New Zealand', flag: '🇳🇿' },
  //   { name: 'Wallis and Futuna', flag: '🇼🇫' },
  //   { name: 'Norfolk Island', flag: '🇳🇫' },
  //   { name: 'Samoa', flag: '🇼🇸' },
  //   { name: 'New Caledonia', flag: '🇳🇨' },
  //   { name: 'Fiji', flag: '🇫🇯' },
  //   { name: 'Niue', flag: '🇳🇺' },
  //   { name: 'Pitcairn Islands', flag: '🇵🇳' },
  //   { name: 'Kiribati', flag: '🇰🇮' },
  //   { name: 'Nauru', flag: '🇳🇷' },
  //   { name: 'Tonga', flag: '🇹🇴' },
  //   { name: 'Tokelau', flag: '🇹🇰' },
  //   { name: 'Vanuatu', flag: '🇻🇺' },
  //   { name: 'Micronesia', flag: '🇫🇲' },
  //   { name: 'American Samoa', flag: '🇦🇸' },
  //   { name: 'Christmas Island', flag: '🇨🇽' },
  //   { name: 'Northern Mariana Islands', flag: '🇲🇵' },
  //   { name: 'Guam', flag: '🇬🇺' },
  //   { name: 'Cocos (Keeling) Islands', flag: '🇨🇨' },
  //   { name: 'Solomon Islands', flag: '🇸🇧' },
  //   { name: 'Marshall Islands', flag: '🇲🇭' },
  //   { name: 'Australia', flag: '🇦🇺' },
  //   { name: 'French Polynesia', flag: '🇵🇫' },
  //14
  { name: 'Tuvalu', flag: '🇹🇻' },
  { name: 'Palau', flag: '🇵🇼' },
  { name: 'Papua New Guinea', flag: '🇵🇬' },
  { name: 'New Zealand', flag: '🇳🇿' },
  { name: 'Samoa', flag: '🇼🇸' },
  { name: 'Fiji', flag: '🇫🇯' },
  { name: 'Kiribati', flag: '🇰🇮' },
  { name: 'Nauru', flag: '🇳🇷' },
  { name: 'Tonga', flag: '🇹🇴' },
  { name: 'Vanuatu', flag: '🇻🇺' },
  { name: 'Micronesia', flag: '🇫🇲' },
  { name: 'Solomon Islands', flag: '🇸🇧' },
  { name: 'Marshall Islands', flag: '🇲🇭' },
  { name: 'Australia', flag: '🇦🇺' },
]

// https://restcountries.com/v3.1/region/Antarctic?fields=name,flag

// const antarcticCountries = [
//5
//   { name: 'South Georgia', flag: '🇬🇸' },
//   { name: 'Heard Island and McDonald Islands', flag: '🇭🇲' },
//   { name: 'Bouvet Island', flag: '🇧🇻' },
//   { name: 'French Southern and Antarctic Lands', flag: '🇹🇫' },
//   { name: 'Antarctica', flag: '🇦🇶' },
// ]

export const allCountries = [
  ...asiaCountries,
  ...africaCountries,
  ...europeCountries,
  ...southAmericaCountries,
  ...northAmericaCountries,
  ...oceaniaCountries,
]

export const ContinentList = {
  AllCountries: {
    name: 'All Countries',
    imageSrc: AllCountries,
    dataList: allCountries,
  },
  Europe: {
    name: 'Europe',
    imageSrc: Europe,
    dataList: europeCountries,
  },
  Asia: {
    name: 'Asia',
    imageSrc: Asia,
    dataList: asiaCountries,
  },
  Africa: {
    name: 'Africa',
    imageSrc: Africa,
    dataList: africaCountries,
  },
  NorthAmerica: {
    name: 'North America',
    imageSrc: NorthAmerica,
    dataList: northAmericaCountries,
  },
  SouthAmerica: {
    name: 'South America',
    imageSrc: SouthAmerica,
    dataList: southAmericaCountries,
  },
  Oceania: {
    name: 'Oceania',
    imageSrc: Oceania,
    dataList: oceaniaCountries,
  },
  // {
  //   name: 'Antarctica',
  //   imageSrc: Antarctica,
  // },
}

export const ContinentActiveList = {
  AllCountries: true,
  Europe: false,
  Asia: false,
  Africa: false,
  NorthAmerica: false,
  SouthAmerica: false,
  Oceania: false,
}

//algo
//if include non-independent, total 250 contries

// const newData = data
//   .filter((unit) => {
//     return unit.independent === true;
//   })
//   .map((datum) => {
//     return {
//       name: datum.name.common,
//       flag: datum.flag,
//     };
//   });

// total 250 countries

import { createReducer } from '../reducerUtil'
import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  SET_HISTORY,
  SET_MODE,
  SET_INPUT_MODE,
  SET_TITLE_OBJ,
  INSTANT_INIT,
  SET_DAYS_MODE,
  SET_INPUTS,
  RESET_INITIAL_STATE,
  REPLACE_STATE,
} from './constants'

const initialState = {
  resultSelected: '',
  history: [],
  mode: 0,
  choices: [],
  title: {
    active: false,
    text: '',
    description: '',
    popup: '',
  },
  turnTable: null,
  turnTableSize: 300,
  inputMode: 2,
  daysMode: [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ],
  inputs: [],
}

export const setResultSelected = (state, payload) => {
  return {
    ...state,
    resultSelected: payload.result,
  }
}

export const setChoices = (state, payload) => {
  return {
    ...state,
    choices: payload.choices,
    turnTable: payload.nullTurntable ? null : state.turnTable,
  }
}

export const setHistory = (state, payload) => {
  return {
    ...state,
    history: payload.history,
  }
}

export const setMode = (state, payload) => {
  return {
    ...state,
    mode: payload.mode,
  }
}

export const showTurntable = (state, payload) => {
  return {
    ...state,
    turnTable: payload.turntable,
  }
}

export const setTurntableSize = (state, payload) => {
  return {
    ...state,
    turnTable: state.turnTableSize === payload.size ? state.turnTable : null,
    turnTableSize: payload.size,
  }
}

export const setInputMode = (state, payload) => {
  return {
    ...state,
    inputMode: payload.inputMode,
  }
}

export const setTitleObj = (state, payload) => {
  return {
    ...state,
    title: {
      ...state.title,
      ...payload.obj,
    },
  }
}

export const instantInit = (state, payload) => {
  if (!payload.previousState) {
    return {
      ...state,
    }
  } else {
    return {
      ...payload.previousState,
    }
  }
}

export const setDaysMode = (state, payload) => {
  return {
    ...state,
    daysMode: payload.daysMode,
  }
}

export const setInputs = (state, payload) => {
  return {
    ...state,
    inputs: payload.inputs,
  }
}

export const resetInitialState = (state, payload) => {
  const { turnTableSize, ...rest } = initialState
  return {
    ...state,
    ...rest,
  }
}

export const replaceState = (state, payload) => {
  return {
    ...state,
    ...payload.data,
  }
}

export default createReducer(initialState, {
  [SHOW_TURNTABLE]: showTurntable,
  [SET_RESULT_SELECTED]: setResultSelected,
  [SET_CHOICES]: setChoices,
  [SET_MODE]: setMode,
  [SET_HISTORY]: setHistory,
  [SET_TURNTABLE_SIZE]: setTurntableSize,
  [SET_INPUT_MODE]: setInputMode,
  [SET_TITLE_OBJ]: setTitleObj,
  [INSTANT_INIT]: instantInit,
  [SET_DAYS_MODE]: setDaysMode,
  [SET_INPUTS]: setInputs,
  [RESET_INITIAL_STATE]: resetInitialState,
  [REPLACE_STATE]: replaceState,
})

import { persistStore } from 'redux-persist'

let instance = null
export const persistorInit = store => {
  instance = persistStore(store)
}

export const getPersistor = () => {
  return instance
}

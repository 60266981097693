import {
  SET_START_ROTATE,
  SET_WHEEL_IMAGES,
  INCREASE_SPIN_QUANTITY,
  RESET_SPIN_QUANTITY,
  SET_IS_EDITING,
  SET_HAS_SPIN_ONCE,
} from './constants'
import { showTurntable } from '../homepage/actions'
import { showTurntable as showTurntableYNW } from '../ynw/actions'
import { showTurntable as showTurntableRNG } from '../rng/actions'
import { showTurntable as showTurntableRLG } from '../rlg/actions'
import { showTurntable as showTurntableRCG } from '../rcg/actions'
import { showTurntable as showTurntableRDG } from '../rdg/actions'
import { showTurntable as showTurntableRIG } from '../rig/actions'
import { showTurntable as showTurntableICP } from '../icp/actions'

export const setStartRotate = angle => ({
  type: SET_START_ROTATE,
  payload: {
    angle,
  },
})

export const setWheelImages = wheelImages => ({
  type: SET_WHEEL_IMAGES,
  payload: {
    wheelImages,
  },
})

export const increaseSpinQuantity = () => ({
  type: INCREASE_SPIN_QUANTITY,
})

export const resetSpinQuantity = () => ({
  type: RESET_SPIN_QUANTITY,
})

export const setIsEditing = value => ({
  type: SET_IS_EDITING,
  payload: {
    value,
  },
})

export const setHasSpinOnce = value => ({
  type: SET_HAS_SPIN_ONCE,
  payload: {
    value,
  },
})

export const refreshTurntable =
  (page = '') =>
  dispatch => {
    switch (page) {
      case 'main':
        dispatch(showTurntable(null))
        break
      case 'rng':
        dispatch(showTurntableRNG(null))
        break
      case 'ynw':
        dispatch(showTurntableYNW(null))
        break
      case 'rlg':
        dispatch(showTurntableRLG(null))
        break
      case 'rcg':
        dispatch(showTurntableRCG(null))
        break
      case 'rdg':
        dispatch(showTurntableRDG(null))
        break
      case 'rig':
        dispatch(showTurntableRIG(null))
        break
      case 'icp':
        dispatch(showTurntableICP(null))
        break
      default:
        dispatch(showTurntable(null))
        dispatch(showTurntableYNW(null))
        dispatch(showTurntableRNG(null))
        dispatch(showTurntableRLG(null))
        dispatch(showTurntableRIG(null))
        dispatch(showTurntableRCG(null))
        dispatch(showTurntableRDG(null))
        dispatch(showTurntableICP(null))
        break
    }
  }
